import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { selectHedgeAccountList } from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { CONTRACT_TYPE_VALUES } from 'utils/constants';
import { mapToLabelValue } from 'utils/helpers';

export const useRegionHedgeAccountSchema = (
	cropYears: Array<number> | undefined | null,
) => {
	const { t: translate } = useTranslation();
	const domain = translations.app.containers.Settings.hedgeAccount;
	const hedgeAccounts = useSelector(selectHedgeAccountList);
	cropYears = cropYears || [];

	const generateSchema = () =>
		Yup.object().shape(
			cropYears.reduce(
				(acc, cropYear) => ({
					...acc,
					[`${CONTRACT_TYPE_VALUES.basis}_${cropYear}`]: Yup.object().required(
						translate(domain.requiredValidation),
					),
					[`${CONTRACT_TYPE_VALUES.flatPrice}_${cropYear}`]:
						Yup.object().required(translate(domain.requiredValidation)),
					[`${CONTRACT_TYPE_VALUES.hta}_${cropYear}`]: Yup.object().required(
						translate(domain.requiredValidation),
					),
				}),
				{},
			),
		);

	const validationSchema = generateSchema();
	const initialValues = {};
	const options = mapToLabelValue(hedgeAccounts);

	const formInstance = useForm({
		defaultValues: {
			...initialValues,
		},
		mode: 'all',
		resolver: yupResolver(validationSchema),
	});

	return { validationSchema, initialValues, options, formInstance };
};
