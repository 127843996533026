import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectExternalFill } from 'app/containers/ReviewAndRelease/selectors';
import { actions } from 'app/containers/ReviewAndRelease/slice';
import { translations } from 'locales/i18n';

export const CancelExternalFill = () => {
	const { t: translate } = useTranslation();
	const msg =
		translations.app.containers.ReviewAndRelease.components.Views.List.Modals;
	const dispatch = useDispatch();
	const [submitting, setSubmitting] = useState(false);

	const id = useSelector(selectExternalFill).data?.id;

	const handleConfirmation = () => {
		if (!submitting) {
			setSubmitting(true);
			// this is hack for our really bad performance, to give the button time to get disabled
			setTimeout(() => {
				dispatch(actions.cancelExternalFill({ id }));
			}, 100);
		}
	};

	const handleCancel = () => {
		dispatch(actions.setCurrentModal(null));
	};

	return (
		<Modal
			maskClosable={false}
			className="cancel-externalFill-modal"
			title={translate(msg.cancelExternalFillTitle)}
			visible={true}
			onOk={handleConfirmation}
			onCancel={handleCancel}
			footer={[
				<Button
					key="back"
					onClick={handleCancel}
					className="return-button"
					data-testid="return-button"
					disabled={submitting}
				>
					{translate(msg.returnBtn)}
				</Button>,
				<Button
					data-testid="confirm-button"
					key="submit"
					type="primary"
					onClick={handleConfirmation}
					className="cancel-button"
					disabled={submitting}
				>
					{translate(msg.cancelBtn)}
				</Button>,
			]}
		>
			{<p>{translate(msg.cancelExternalFillConfirmation)}</p>}
		</Modal>
	);
};
