import { translations } from 'locales/i18n';
import { FIELD_TYPES_VALUES, OrderEntry } from 'types/OrderEntry';
import { getNumberArray } from 'utils/helpers';

import { ORDER_ENTRY_FORM_ITEMS } from '../../../types';
import { MULTI_SELECT_MAX_OPTIONS } from '../../../utils/helpers';

const getMultiSelectOption = (index: number) => {
	const option = `${index + 1}`;

	return { label: option, value: option };
};

const isUndefinedType = (fieldType: FIELD_TYPES_VALUES | undefined | null) =>
	fieldType === FIELD_TYPES_VALUES.UND;

export const DOMAIN =
	translations.app.containers.Settings.sections.OrderEntry.components
		.OrderEntryRow;

export const getFieldTypes = (translate: Function) => [
	{
		label: translate(DOMAIN.text),
		value: FIELD_TYPES_VALUES.TXT,
	},
	{
		label: translate(DOMAIN.alphanumeric),
		value: FIELD_TYPES_VALUES.TXT2,
	},
	{
		label: translate(DOMAIN.numeric),
		value: FIELD_TYPES_VALUES.INT,
	},
	{
		label: translate(DOMAIN.date),
		value: FIELD_TYPES_VALUES.DAT,
	},
	{
		label: translate(DOMAIN.boolean),
		value: FIELD_TYPES_VALUES.BLN,
	},
	{
		label: translate(DOMAIN.multiSelect),
		value: FIELD_TYPES_VALUES.MUL,
	},
];

export const getMultiSelectInputsIdList = (orderEntryId: string) =>
	getNumberArray(MULTI_SELECT_MAX_OPTIONS).map(
		(value: number, index: number) =>
			`${ORDER_ENTRY_FORM_ITEMS.MULTI_SELECT_INPUT}_${index}-${orderEntryId}`,
	);

export const getMultiSelectOptions = () =>
	Array.from({ length: MULTI_SELECT_MAX_OPTIONS }, (_: any, index: number) =>
		getMultiSelectOption(index),
	);

export const isFieldTypeDisabled = (
	disabledItems: boolean,
	orderEntry: OrderEntry | undefined,
) => disabledItems || (!!orderEntry?.id && !isUndefinedType(orderEntry?.type));
