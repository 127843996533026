import './style.scss';

import { Checkbox, Select } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { memo } from 'react';

import { GenericOption } from 'types/GenericOption';

import { TagWrapped } from '../TagWrapped';

interface Props {
	options: GenericOption[];
	currentOptions: GenericOption[];
	defaultOption?: GenericOption[];
	handleChange: (selectedValues: GenericOption[]) => void;
	placeholder?: string;
}
/**
 * FilterMultiple component supports Selecting 1 or more options, following next rules:
 * 1. The "All" Option is selected as initial value
 * 2. The "All" option is selected when trying to clear filter
 * 3. If other options are selected and then the user select "All", other options are cleared
 * 4. When at least one different option from "All" is selected, the "All" option is cleared
 * 5. When there are only options different from 'all', they are checked
 */
export const FilterMultiple = memo((props: Props) => {
	const { Option } = Select;
	const {
		options,
		placeholder,
		handleChange: propHandleChange,
		currentOptions = [],
		defaultOption,
	} = props;

	const handleChange = (selectedValues: GenericOption[]) => {
		const indexAll = selectedValues.findIndex(
			(element) => element.value === 'all',
		);

		//when the all option is the last selected and doesn't exist in filters' currentOptions
		if (
			indexAll === selectedValues.length - 1 &&
			!currentOptions.some((element) => element.value === 'all')
		) {
			propHandleChange([]);
		}
		//when selected at least one option additional to 'all' option.
		else if (indexAll >= 0 && indexAll < selectedValues.length - 1) {
			const filteredOptions = selectedValues.filter(
				(element) => element.value !== 'all',
			);
			propHandleChange(filteredOptions);
		}
		//when there are only options different from 'all'
		else if (indexAll === -1) {
			propHandleChange(selectedValues);
		}
	};

	return (
		<Select
			className="filter-select-multiple"
			data-testid="filter-multiple"
			mode="multiple"
			tagRender={TagWrapped}
			labelInValue
			showArrow={true}
			showSearch={false}
			size="small"
			onChange={handleChange}
			placeholder={placeholder}
			allowClear={true}
			optionLabelProp="label"
			value={currentOptions}
			defaultValue={defaultOption}
			maxTagCount={2}
			menuItemSelectedIcon={null}
			maxTagTextLength={
				currentOptions.length > 2 ? 4 : currentOptions.length === 2 ? 10 : 30
			}
		>
			{options.map((option, index) => (
				<Option
					label={option.label}
					value={option.value}
					key={option.value}
					className="select-options"
				>
					<Checkbox
						data-testid={`filter-multiple-${option.label}`}
						className="checkbox--no-border-radius"
						checked={
							currentOptions.some(
								(element) => element.value === option.value,
							) ||
							(!currentOptions.length && index === 0)
						}
					/>
					<Text
						mark={option?.highlight ?? false}
						className="select-options_label--bold"
					>
						{option.label}
					</Text>
				</Option>
			))}
		</Select>
	);
});
