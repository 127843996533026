import { z } from 'zod';

const _deliveryDateSchema = z.object({
	start: z.string(),
	end: z.string(),
	name: z.string().nullable(),
	futureMonth: z.string().nullable(),
	basis: z.number(),
	cropYear: z.number().int().nullable(),
});
export interface DeliveryDate extends z.infer<typeof _deliveryDateSchema> {}
export const deliveryDateSchema: z.Schema<DeliveryDate> = _deliveryDateSchema;
