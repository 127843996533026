import { call, put } from 'typed-redux-saga';
import { z } from 'zod';

import { GenericItem } from 'types/GenericItem';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';
import { genericItemSchema } from 'utils/validators';

import { actions } from '../slice';

const { eventRoot } = apiEndpoints;

export function* getEventList() {
	const requestURL = new URL(`${apiRoutes.base}/${eventRoot}`);

	const request = requestWithSchema<GenericItem[]>;
	const { data, error } = yield* call(request, requestURL, httpMethod.Get, {
		schema: z.array(genericItemSchema),
	});

	if (!!data) {
		yield* put(actions.eventLoaded(data));
	} else if (error) {
		yield* put(actions.eventError(error));
	}
}
