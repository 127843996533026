import { Permission } from 'types/Authorization';
import { FeatureFlag } from 'types/FeatureFlags';
import { GenericError } from 'types/GenericError';
import { Location } from 'types/Location';

export enum Sections {
	Bidsheet = 'bidsheet',
	BrokerMapping = 'brokerMapping',
	BypassReview = 'bypassReview',
	Commodities = 'commodities',
	Customers = 'customers',
	DefaultPage = 'defaultPage',
	Employees = 'employees',
	ERPIntegration = 'erpIntegration',
	HedgeMapping = 'hedgeMapping',
	Locations = 'locations',
	OrderEntry = 'orderEntry',
	NtcAdmin = 'ntcAdmin',
	RoundingRules = 'roundingRules',
	ServiceFees = 'serviceFees',
	Users = 'users',
	NotificationSettings = 'notificationSettings',
	Regions = 'regions',
}

export interface MenuItem {
	name: Sections;
	icon: React.ReactNode;
	component: React.ReactNode;
	legacyComponent?: React.ReactNode;
	permission?: Permission;
	featureFlag?: FeatureFlag;
}

/* --- STATE --- */
export interface SettingsState {
	activeSection: Sections;
	locationsForSettings: {
		data: Location[];
		loading: boolean;
		error: GenericError | null;
	};
	isLocationPermissionStateUpdated: boolean;
}

export type ContainerState = SettingsState;

export const FORM_HEADER = 'formHeader';

export enum RoleSections {
	CONTRACT_TYPES = 'contractTypes',
	OFFERS_TYPES = 'offersTypes',
	SCREENS = 'screens',
	FUNCTIONS = 'functions',
}

export enum RoleSettings {
	LOCATIONS = 'locations',
	LIMITS = 'limits',
}

export const roleSectionsList = [
	RoleSections.CONTRACT_TYPES,
	RoleSections.OFFERS_TYPES,
	RoleSections.SCREENS,
	RoleSections.FUNCTIONS,
];

export const roleCreationList = [RoleSettings.LIMITS];
export const roleLocationList = [RoleSettings.LOCATIONS];

export enum ContractType {
	FLAT_PRICE = 'CONTRACTSERVICE_CONTRACTFLATPRICE_CREATE',
	HTA = 'CONTRACTSERVICE_CONTRACTHTA_CREATE',
	BASIS = 'CONTRACTSERVICE_CONTRACTBASIS_CREATE',
	NTC = 'CONTRACTSERVICE_CONTRACTNTC_CREATE',
}

export const contractTypeList = [
	ContractType.FLAT_PRICE,
	ContractType.HTA,
	ContractType.BASIS,
	ContractType.NTC,
];

export enum OfferType {
	FLAT_PRICE = 'CONTRACTSERVICE_OFFERFLATPRICE_CREATE',
	HTA = 'CONTRACTSERVICE_OFFERHTA_CREATE',
	BASIS = 'CONTRACTSERVICE_OFFERBASIS_CREATE',
}

export const offerTypeList = [
	OfferType.FLAT_PRICE,
	OfferType.HTA,
	OfferType.BASIS,
];

export enum ScreenPermission {
	REVIEW_RELEASE = 'CONTRACTSERVICE_REVIEWANDRELEASE_VIEW',
	LIVELEDGER = 'CONTRACTSERVICE_LIVELEDGER_VIEW',
	SETTINGS = 'CONTRACTSERVICE_SETTINGS_ADMIN',
	BIDSHEET_UPLOAD = 'CONTRACTSERVICE_BIDSHEET_UPLOAD',
	ORIGINATOR_SCREEN = 'CONTRACTSERVICE_ORIGINATOR_BIDS_QUOTES_VIEW',
	FUTURESERP_SCREEN = 'CONTRACTSERVICE_FUTURESERP_VIEW',
	TRADEBOOK_SCREEN = 'CONTRACTSERVICE_TRADEBOOK_VIEW',
	FUTURE_FIRST = 'CONTRACTSERVICE_FUTUREFIRST_VIEW',
}

export const screenPermissionList = [
	ScreenPermission.REVIEW_RELEASE,
	ScreenPermission.LIVELEDGER,
	ScreenPermission.SETTINGS,
	ScreenPermission.BIDSHEET_UPLOAD,
	ScreenPermission.ORIGINATOR_SCREEN,
	ScreenPermission.FUTURESERP_SCREEN,
	ScreenPermission.TRADEBOOK_SCREEN,
	ScreenPermission.FUTURE_FIRST,
];

export enum functionsPermission {
	CANCEL = 'CONTRACTSERVICE_CONTRACT_CANCEL',
	DO_NOT_HEDGE = 'CONTRACTSERVICE_CONTRACT_ACTIVATEDNH',
	EDIT_NO_QUANTITY = 'LOCALPERMISSION_ALL_EDIT_NO_QUANTITY',
	EDIT_QUANITY = 'LOCALPERMISSION_ALL_EDIT_QUANTITY',
	SALES_TRADING = 'CONTRACTSERVICE_USE_SALES_TRADING',
	RESEND_TO_ERP = 'CONTRACTSERVICE_CONTRACT_RESENDTOERP',
	MOBILEAPP = 'CONTRACTSERVICE_CUSTOMER_MOBILEAPPENABLE',
}

export const functionsPermissionList = [
	functionsPermission.CANCEL,
	functionsPermission.DO_NOT_HEDGE,
	functionsPermission.EDIT_NO_QUANTITY,
	functionsPermission.EDIT_QUANITY,
	functionsPermission.SALES_TRADING,
	functionsPermission.RESEND_TO_ERP,
	functionsPermission.MOBILEAPP,
];

export const allEditNoQuantityPermission = [
	Permission.CONTRACTSERVICE_CONTRACTFLATPRICE_EDITNONQUANTITY,
	Permission.CONTRACTSERVICE_CONTRACTHTA_EDITNONQUANTITY,
	Permission.CONTRACTSERVICE_CONTRACTBASIS_EDITNONQUANTITY,
	Permission.CONTRACTSERVICE_CONTRACTNTC_EDITNONQUANTITY,
];

export const allEditQuantityPermission = [
	Permission.CONTRACTSERVICE_CONTRACTFLATPRICE_EDITQUANTITY,
	Permission.CONTRACTSERVICE_CONTRACTHTA_EDITQUANTITY,
	Permission.CONTRACTSERVICE_CONTRACTBASIS_EDITQUANTITY,
	Permission.CONTRACTSERVICE_CONTRACTNTC_EDITQUANTITY,
];

export const allEditNoQuantityLabels = [
	'CONTRACTSERVICE_CONTRACTFLATPRICE_EDITNONQUANTITY',
	'CONTRACTSERVICE_CONTRACTHTA_EDITNONQUANTITY',
	'CONTRACTSERVICE_CONTRACTNTC_EDITNONQUANTITY',
	'CONTRACTSERVICE_CONTRACTBASIS_EDITNONQUANTITY',
];

export const allEditQuantityLabels = [
	'CONTRACTSERVICE_CONTRACTFLATPRICE_EDITQUANTITY',
	'CONTRACTSERVICE_CONTRACTHTA_EDITQUANTITY',
	'CONTRACTSERVICE_CONTRACTNTC_EDITQUANTITY',
	'CONTRACTSERVICE_CONTRACTBASIS_EDITQUANTITY',
];

export const allPermissionsLabels = [
	...contractTypeList,
	...offerTypeList,
	...screenPermissionList,
	...functionsPermissionList,
];

export interface ErrorInLine {
	Field: string;
	Message: string;
}
