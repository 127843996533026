/**
 *
 * FiltersGenerator
 *
 */
import './styles.scss';

import { Col, Row } from 'antd';
import { withFormik } from 'formik';
import moment from 'moment';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { FilterSwitch } from 'app/components/FilterSwitch';
import { FormCalendar } from 'app/components/FormCalendar';
import { FormDropdown } from 'app/components/FormDropdown';
import { FormSearch } from 'app/components/FormSearch';
import { translations } from 'locales/i18n';
import { CONTRACT_TYPE_VALUES, DATE_FORMAT } from 'utils/constants';

import {
	selectDynamicFilters,
	selectFiltersData,
	selectFiltersSchema,
} from '../../selectors';
import { genericReportActions } from '../../slice';
import { FilterItem, SelectionMode } from '../../types';
import { FormButtons } from './components/FormButtons';

interface Props {
	mode?: 'simple' | 'form';
}

const FILTERS_TYPES = {
	dropdown: 'dropdown',
	search: 'search',
	datepicker: 'datepicker',
	switch: 'switch',
};

export const FiltersGenerator = memo((props: Props) => {
	const { t: translate } = useTranslation();
	const { mode } = props;

	const dispatch = useDispatch();

	const filtersSchema = useSelector(selectFiltersSchema);
	const filtersData = useSelector(selectFiltersData);
	const dynamicFilters = useSelector(selectDynamicFilters);

	const useEffectFilterSchema = (effect: React.EffectCallback) => {
		useEffect(effect, [filtersSchema]);
	};

	useEffectFilterSchema(() => {
		filtersSchema.forEach((filter) => {
			if (filter.dataType === 'dinamic') {
				dispatch(
					genericReportActions.loadDynamicFilter({
						url: filter?.data.Url,
						source: filter.key,
					}),
				);
			}
		});
	});

	const handleSearchFilter = (filterSchema: FilterItem, value: string) => {
		const endpoint = filterSchema.data.Url.substring(5);

		dispatch(
			genericReportActions.loadFilterData({
				endpoint,
				filterKey: filterSchema.key,
				filterValue: value,
			}),
		);
	};

	const generateFilter = (
		singleFilterSchema: FilterItem,
		setFieldValue: (
			field: string,
			value: any,
			shouldValidate?: boolean,
		) => void,
		values: { [key: string]: any },
	) => {
		const type = singleFilterSchema.componentType;
		const defaultValue = singleFilterSchema.defaultValue;
		const maxValue = singleFilterSchema.restrictions.maxValue;

		const getDefaultValue = () => {
			return defaultValue !== null
				? moment().add(defaultValue, 'days')
				: undefined;
		};

		const disabledDate = (current) => {
			if (maxValue !== null) {
				return current && current > moment().add(maxValue, 'days').endOf('day');
			}
			return false;
		};

		const mapFutureMData = (data) => {
			return data.map((item) => ({
				label: item.label,
				value: item.label,
			}));
		};

		switch (type) {
			case FILTERS_TYPES.dropdown:
				let dropDownOptions;

				switch (singleFilterSchema.dataType) {
					case 'static':
						dropDownOptions = singleFilterSchema.data;
						break;
					case 'dinamic':
						if (singleFilterSchema.key === 'futureM') {
							dropDownOptions = dynamicFilters[singleFilterSchema.key]
								? mapFutureMData(dynamicFilters[singleFilterSchema.key].data)
								: [];
						} else {
							dropDownOptions = dynamicFilters[singleFilterSchema.key]
								? dynamicFilters[singleFilterSchema.key].data
								: [];
						}
						break;

					default:
						dropDownOptions = singleFilterSchema.data;
						break;
				}

				/*
				 * Bugfix
				 * Task No 2982
				 * DETAILS: (NTC should not be available as an option at contract type dropdown)
				 *
				 * Apporach Explain: Check if the option type matches NTC type, and if so, remove that option from the dropdown.
				 */
				const filteredDropDownOption = () => {
					if (singleFilterSchema.key === 'contractType') {
						return dropDownOptions.filter(
							(e) => e.type !== CONTRACT_TYPE_VALUES.ntc,
						);
					} else {
						return dropDownOptions;
					}
				};

				return (
					<FormDropdown
						name={singleFilterSchema.key}
						options={filteredDropDownOption()}
						placeholder={singleFilterSchema.label}
						multiple={
							singleFilterSchema.selectionMode === SelectionMode.MULTIPLE
								? true
								: false
						}
					/>
				);

			case FILTERS_TYPES.search:
				return (
					<FormSearch
						name={singleFilterSchema.key}
						options={filtersData[singleFilterSchema.key]}
						placeholder={singleFilterSchema.label}
						onSearch={(value) => {
							handleSearchFilter(singleFilterSchema, value);
						}}
					/>
				);

			case FILTERS_TYPES.datepicker:
				// Disable the Expiration field when the GTC switch is toggled on.
				const isDisabled =
					singleFilterSchema.key === 'expiration' && values['gtc'];

				if (singleFilterSchema.selectionMode === SelectionMode.SINGLE) {
					return (
						<FormCalendar
							defaultValue={getDefaultValue()}
							disabledDate={disabledDate}
							name={singleFilterSchema.key}
							placeholder={
								singleFilterSchema.key === 'startdate'
									? translate(
											translations.app.containers.Transactions.startDate,
										)
									: singleFilterSchema.key === 'enddate'
										? translate(
												translations.app.containers.Transactions.endDate,
											)
										: singleFilterSchema.label
							}
							disabled={isDisabled}
						/>
					);
				} else {
					throw new Error(
						`Selection mode "${singleFilterSchema.selectionMode}" is not supported for date filters`,
					);
				}

			case FILTERS_TYPES.switch:
				return (
					<FilterSwitch
						handleChange={(checked) =>
							setFieldValue(singleFilterSchema.key, checked)
						}
						checkedElement={singleFilterSchema.label}
						uncheckedElement={singleFilterSchema.label}
						checked={values[singleFilterSchema.key]}
					/>
				);

			default:
				return null;
		}
	};

	const FiltersForm = (props) => {
		const { values, handleSubmit, submitForm, resetForm, setFieldValue } =
			props;

		useEffect(() => {
			if (mode === 'simple') {
				submitForm();
			}
		}, [handleSubmit, submitForm, values]);

		const filters = filtersSchema?.map((singleFilterSchema: FilterItem) => {
			const element = generateFilter(singleFilterSchema, setFieldValue, values);

			if (element) {
				return (
					<Col
						span={singleFilterSchema.size ? singleFilterSchema.size : 2}
						key={singleFilterSchema.key}
						data-testid="field-wrapper"
					>
						{element}
					</Col>
				);
			} else {
				return null;
			}
		});

		const handleClearFilters = () => {
			resetForm();
			dispatch(genericReportActions.setStartPagination({ start: 1 }));
			dispatch(genericReportActions.setSelectedFilters({}));
			dispatch(genericReportActions.resetTableData());
		};

		return (
			<form onSubmit={handleSubmit}>
				<div className="filters-generator__container">
					<Row gutter={16}>{filters}</Row>
				</div>
				{mode === 'form' && <FormButtons onClear={handleClearFilters} />}
			</form>
		);
	};

	const getInitialValues = () => {
		const initialValues = {};

		filtersSchema?.forEach((singleFilterSchema) => {
			const type = singleFilterSchema.componentType;
			const key = singleFilterSchema.key;
			const defaultValue = singleFilterSchema.defaultValue;
			const selectionMode = singleFilterSchema.selectionMode;

			switch (type) {
				case FILTERS_TYPES.dropdown:
					initialValues[key] = selectionMode === 'multiple' ? [] : '';
					break;
				case FILTERS_TYPES.search:
					initialValues[key] = '';
					break;

				case FILTERS_TYPES.datepicker:
					initialValues[key] = moment(defaultValue).isValid()
						? moment().add(defaultValue, 'days')
						: null;
					break;

				case FILTERS_TYPES.switch:
					// Backend sends the default values as '0' or '1'
					initialValues[key] = defaultValue === '1';
					break;

				default:
					break;
			}
		});

		return initialValues;
	};

	const getValidationSchema = () => {
		const validationObject = {};

		if (mode === 'form') {
			filtersSchema?.forEach((singleFilterSchema) => {
				let validationProperty = Yup.string().nullable();

				if (singleFilterSchema.restrictions.isRequired) {
					validationProperty = validationProperty.required('Field Required');
				}

				validationObject[singleFilterSchema.key] = validationProperty;
			});
		}

		return Yup.object().shape(validationObject);
	};

	const mapValues = (itemValue) => {
		if (Array.isArray(itemValue)) {
			const arrayValues = itemValue.map((item) => item.value);
			return arrayValues;
		} else if (typeof itemValue === 'object') {
			return itemValue.value;
		}
	};

	const mapDates = (dateRange, selectionMode) => {
		if (dateRange && selectionMode === SelectionMode.SINGLE) {
			return moment(dateRange).format(DATE_FORMAT);
		} else if (dateRange && selectionMode === SelectionMode.RANGE) {
			const startDate = dateRange[0].format(DATE_FORMAT);
			const endDate = dateRange[1].format(DATE_FORMAT);

			return `${startDate}_${endDate}`;
		}
	};

	const handleSubmit = (values) => {
		const selectedFilters = {};

		filtersSchema?.forEach((singleFilterSchema) => {
			const type = singleFilterSchema.componentType;
			const key = singleFilterSchema.key;
			let itemValue = values[key];
			const selectionMode = singleFilterSchema.selectionMode;

			switch (type) {
				case FILTERS_TYPES.dropdown:
				case FILTERS_TYPES.search:
					selectedFilters[key] = mapValues(itemValue);
					break;

				case FILTERS_TYPES.datepicker:
					selectedFilters[key] = mapDates(itemValue, selectionMode);
					break;

				case FILTERS_TYPES.switch:
					// TODO: I wish they've written it in a better way, but that's what it is. The stored procedure expects 'Yes' or 'No' for the 'gtc' parameter.
					if (key === 'gtc') {
						itemValue = itemValue ? 'Yes' : 'No';
					}
					selectedFilters[key] = itemValue;
					break;

				default:
					break;
			}
		});

		dispatch(genericReportActions.setSelectedFilters(selectedFilters));
		dispatch(genericReportActions.loadTableData());
		dispatch(genericReportActions.loadTableDataReport());
	};

	const FormWrapper = withFormik({
		mapPropsToValues: () => getInitialValues(),
		validationSchema: getValidationSchema(),
		validateOnBlur: true,
		validateOnChange: true,
		handleSubmit: handleSubmit,
	})(FiltersForm);

	return !!filtersSchema?.length ? (
		<div className="filters-generator">
			{mode === 'form' && (
				<label className="filters-generator__label">Filters</label>
			)}
			<FormWrapper />
		</div>
	) : null;
});
