import './style.scss';

import { DevTool } from '@hookform/devtools';
import { Divider, Form as AntDForm } from 'antd';
import { FormProps } from 'antd/lib/form/Form';
import classNames from 'classnames';
import React, { memo, useState } from 'react';
import {
	FieldValues,
	FormProvider,
	SubmitHandler,
	UseFormMethods,
} from 'react-hook-form';
import * as yup from 'yup';

import { FormButton } from '../FormButton';
import { FormButtonsProps } from '../types';

const DEFAULT_LABEL_SPAN = 3;
const DEFAULT_WRAPPER_COL_SPAN = 9;

interface Props
	extends Omit<
		FormProps,
		| 'component'
		| 'fields'
		| 'form'
		| 'initialValues'
		| 'name'
		| 'preserve'
		| 'requiredMark'
		| 'scrollToFirstError'
		| 'validateMessages'
		| 'validateTrigger'
		| 'onFieldsChange'
		| 'onFinish'
		| 'onFinishFailed'
		| 'onValuesChange'
	> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- type safety for form values will be separate effort (and likely require substantial rewriting of forms)
	formInstance: UseFormMethods<any>;
	children: JSX.Element | JSX.Element[];
	onSubmit?: SubmitHandler<FieldValues>;
	validationSchema: yup.Schema<unknown>;
	buttonsDefinition?: FormButtonsProps[];
}

export const ValidationSchemaContext = React.createContext<yup.Schema<unknown>>(
	yup.mixed().nullable(),
);

/**
 * Creates a new form based on formDefinition schema
 * @param formInstance pass the return value of a "react-hook-form" useForm()
 * @param children array of FormItems
 * @param onSubmit function to be called when the form is valid and is submited *
 * @param validationSchema Yup object
 * @param buttonsDefinition array of FormButtonsProps, each entry creates a FormButton
 * @returns an Ant Form component
 */
export const Form = memo((props: Props) => {
	const {
		formInstance,
		onSubmit = () => {},
		validationSchema,
		buttonsDefinition = [],
		children,
		...formProps
	} = props;

	const { handleSubmit, control, reset } = formInstance;
	const [submitting, setSubmitting] = useState(false);

	const buttons = buttonsDefinition.map((buttonProps) => (
		<FormButton
			{...buttonProps}
			disabled={buttonProps.disabled || submitting}
		></FormButton>
	));

	// this is a hack to compensate for the poor performance of our forms
	// it gives the button a chance to get properly disabled before the main thread is blocked
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO strict types for forms
	const onFinish = (values: any) => {
		setSubmitting(true);
		setTimeout(() => {
			handleSubmit(onSubmit)(values);
			setSubmitting(false);
		}, 100);
	};

	const defaultLabelCol = { span: DEFAULT_LABEL_SPAN };
	const defaultWrapperCol = { span: DEFAULT_WRAPPER_COL_SPAN };

	return (
		<FormProvider {...formInstance}>
			<AntDForm
				onReset={() => reset()}
				onFinish={onFinish}
				labelCol={defaultLabelCol}
				wrapperCol={defaultWrapperCol}
				colon={false}
				{...formProps}
				className={classNames('generic-form-container', props.className)}
			>
				{process.env.NODE_ENV === 'development' && (
					<DevTool control={control} />
				)}

				<ValidationSchemaContext.Provider value={validationSchema}>
					<div className="form-items-container">{children}</div>
				</ValidationSchemaContext.Provider>

				{buttons.length > 0 && <Divider></Divider>}
				<div className="buttons-container">{buttons}</div>
			</AntDForm>
		</FormProvider>
	);
});
