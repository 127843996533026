import './style.scss';

import { Button, notification } from 'antd';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomNotification } from 'app/containers/GlobalSaga/types';

import { UseDefaultMessagesTranslations } from './translations';

const ConfirmButton = ({
	confirmText,
	id,
	onClick,
}: {
	confirmText: string;
	id: string;
	onClick: (id: string) => void;
}) => (
	<Button
		data-testid="confirm-button"
		key="submit"
		onClick={() => {
			onClick && onClick(id);
			notification.close(id);
		}}
		type="primary"
	>
		{confirmText}
	</Button>
);
interface Props extends CustomNotification {
	onClose: (id: string) => void;
	showDescription?: boolean;
}

export const ToastMessage = ({
	confirmText,
	duration = 3.5,
	id = '',
	message,
	onClose,
	showConfirmButton = false,
	showDescription = true,
	title,
	type,
}: Props) => {
	const defaultMessages = UseDefaultMessagesTranslations();
	const { t: translate } = useTranslation();

	//more config options can be found here: https://ant.design/components/notification/#API
	notification.config({
		placement: 'bottomRight', //where it will be placed
		duration, //time in seconds to close notification. 0 means no close
		rtl: false, //text right to left (chinese, japanese, korean, etc)
	});

	useEffect(() => {
		let validatedConfirmText: string = defaultMessages[type].title;
		let validatedTitle: string = defaultMessages[type].title;
		let validatedMessage: string = defaultMessages[type].message;

		if (!title || typeof title === 'string') {
			validatedTitle = title || validatedTitle;
		} else {
			try {
				validatedTitle = translate(title.key, title.data);
			} catch (error) {}
		}

		if (!message || typeof message === 'string') {
			validatedMessage = message || validatedMessage;
		} else {
			try {
				validatedMessage = translate(message.key, message.data);
			} catch (error) {}
		}

		if (!confirmText || typeof confirmText === 'string') {
			validatedConfirmText = confirmText || validatedConfirmText;
		} else {
			try {
				validatedConfirmText = translate(confirmText.key, confirmText.data);
			} catch (error) {}
		}

		notification[type]({
			key: id,
			message: validatedTitle,
			description: showDescription ? validatedMessage : '',
			className: classNames('toast-message', type, {
				'no-description': !showDescription,
				'no-confirm-button': !showConfirmButton,
			}),
			onClose: () => {
				onClose && onClose(id);
			},
			btn: showConfirmButton ? (
				<ConfirmButton
					confirmText={validatedConfirmText}
					id={id}
					onClick={onClose}
				/>
			) : null,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <></>;
};
