import { call, put } from 'typed-redux-saga';
import { z } from 'zod';

import { GenericItem } from 'types/GenericItem';
import { ServiceFeeData, serviceFeeDataSchema } from 'types/ServiceFee';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';
import { genericItemSchema } from 'utils/validators';

import { actions } from '../slice';
const { serviceFeeRoot, serviceFeeMonths, serviceFeeTypes } = apiEndpoints;

export function* getServiceFeesList() {
	let requestURL = new URL(
		`${apiRoutes.base}/${serviceFeeRoot}/${serviceFeeMonths}`,
	);

	const request = requestWithSchema<ServiceFeeData[]>;
	const { data, error } = yield* call(request, requestURL, httpMethod.Get, {
		schema: z.array(serviceFeeDataSchema),
	});

	if (!!data) {
		yield* put(actions.ServiceFeeListLoaded(data));
	} else if (!!error?.detail) {
		yield* put(actions.ServiceFeeListError(error));
	}
}

export function* getServiceFeeTypes() {
	let requestURL = new URL(
		`${apiRoutes.base}/${serviceFeeRoot}/${serviceFeeTypes}`,
	);

	const request = requestWithSchema<GenericItem[]>;
	const { data, error } = yield* call(request, requestURL, httpMethod.Get, {
		schema: z.array(genericItemSchema),
	});

	if (!!data) {
		yield* put(actions.ServiceFeeTypesListLoaded(data));
	} else if (!!error?.detail) {
		yield* put(actions.ServiceFeeListError(error));
	}
}
