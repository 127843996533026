import { call, put } from 'typed-redux-saga';
import { z } from 'zod';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from './../slice';

const { reviewAndReleaseStatus: reviewAndReleaseStates } = apiEndpoints;

export function* getReviewAndReleaseStates() {
	const requestURL = new URL(`${apiRoutes.base}/${reviewAndReleaseStates}`);

	const request = requestWithSchema<string[]>;
	const { data, error } = yield* call(request, requestURL, httpMethod.Get, {
		schema: z.array(z.string()),
	});

	// Success actions
	if (!!data) {
		yield* put(actions.reviewAndReleaseStatesLoaded(data));
	} else if (!!error) {
		yield* put(actions.reviewAndReleaseStatesError(error));
	}
}
