import { MessageOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { SelectProps } from 'formik-antd';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NIL as guidEmpty } from 'uuid';
import * as Yup from 'yup';

import {
	selectCurrentModal,
	selectCustomerNotificationGroups,
	selectCustomerNotificationGroupsLoading,
	selectEmployeeNotificationGroups,
	selectEmployeeNotificationGroupsLoading,
} from 'app/containers/GlobalSaga/selectors';
import { actions } from 'app/containers/GlobalSaga/slice';
import {
	GlobalModals,
	NotificationGroupDto,
	NotificationMessagePayload,
} from 'app/containers/GlobalSaga/types';
import { translations } from 'locales/i18n';
import { FieldDefinition } from 'types/FieldDefinition';
import { GenericOption } from 'types/GenericOption';
import {
	generateFormSearch,
	generateInputField,
	generateTextArea,
} from 'utils/GenericFormInputs/generic-form-inputs';

import { FormFooterButtons } from '../FormFooterButtons';
import { GenericModalForm } from '../GenericModalForm';

const domain = translations.app.components.SendCustomNotification;

export const SendCustomNotification = memo(() => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const confirmDomain = translations.app.components.ConfirmationModal;

	const customerGroups = useSelector(selectCustomerNotificationGroups);
	const customerGroupsLoading = useSelector(
		selectCustomerNotificationGroupsLoading,
	);
	const employeeGroups = useSelector(selectEmployeeNotificationGroups);
	const employeeGroupsLoading = useSelector(
		selectEmployeeNotificationGroupsLoading,
	);
	const currentModal = useSelector(selectCurrentModal);

	const allGroupsOption = useMemo(
		() => ({
			label: translate(domain.allGroupsOption),
			value: guidEmpty,
		}),
		[translate],
	);
	const mapGroupsToOption = useCallback(
		(groups: NotificationGroupDto[]) => [
			allGroupsOption,
			...groups.map((group) => ({
				label: group.name,
				value: group.id,
				type: group.type,
			})),
		],
		[allGroupsOption],
	);

	const [employeeGroupOptions, setEmployeeGroupOptions] = useState<
		GenericOption[]
	>(mapGroupsToOption(employeeGroups));
	const [customerGroupOptions, setCustomerGroupOptions] = useState<
		GenericOption[]
	>(mapGroupsToOption(customerGroups));
	const [selectedEmployeeGroups, setSelectedEmployeeGroups] = useState<
		GenericOption[]
	>([]);
	const [selectedCustomerGroups, setSelectedCustomerGroups] = useState<
		GenericOption[]
	>([]);
	const [loading, setLoading] = useState(false);

	const handleEmployeeOptionChange = (
		options: GenericOption[],
		selectedOption?: GenericOption,
	) => {
		if (Array.isArray(options)) {
			setSelectedEmployeeGroups(
				options.filter((option) => option.value !== allGroupsOption.value),
			);
		}

		if (selectedOption && selectedOption.value === allGroupsOption.value) {
			setSelectedEmployeeGroups([allGroupsOption]);
		}
	};
	const handleCustomerOptionChange = (
		options: GenericOption[],
		selectedOption?: GenericOption,
	) => {
		if (Array.isArray(options)) {
			setSelectedCustomerGroups(
				options.filter((option) => option.value !== allGroupsOption.value),
			);
		}

		if (selectedOption && selectedOption.value === allGroupsOption.value) {
			setSelectedCustomerGroups([allGroupsOption]);
		}
	};

	const formDefinition: FieldDefinition<SelectProps | TextAreaProps>[] = [
		{
			name: 'messageSubject',
			key: 'messageSubject',
			initialValue: '',
			validation: Yup.string().required(
				translate(domain.messageSubjectRequired),
			),
			label: translate(domain.labelSubject),
			placeholder: translate(domain.labelSubject),
			maxLength: 50,
			render: generateInputField,
		},
		{
			name: 'messageContent',
			key: 'messageContent',
			initialValue: '',
			validation: Yup.string().required(translate(domain.messageRequired)),
			label: translate(domain.labelMessage),
			placeholder: translate(domain.labelMessage),
			maxLength: 300,
			style: { height: 120, resize: 'none' },
			render: generateTextArea,
		},
		{
			name: 'employeeNotificationGroupIds',
			key: 'employeeNotificationGroupIds',
			value: selectedEmployeeGroups,
			initialValue: [],
			label: translate(domain.labelEmployeeGroups),
			placeholder: translate(domain.placeholderEmployeeGroups),
			options: employeeGroupOptions,
			loading: employeeGroupsLoading,
			optionFilterProp: 'label',
			mode: 'multiple',
			validation: Yup.array()
				.of(Yup.string())
				.test({
					name: 'custom-validation',
					message: translate(domain.groupsValidationMsg),
					test: function (value) {
						const { customerNotificationGroupIds } = this.parent;

						if (!customerNotificationGroupIds?.length && !value?.length) {
							return false;
						}
						return true;
					},
				}),
			onSelect: handleEmployeeOptionChange as SelectProps['onSelect'],
			render: generateFormSearch,
		},
		{
			name: 'customerNotificationGroupIds',
			key: 'customerNotificationGroupIds',
			value: selectedCustomerGroups,
			initialValue: [],
			label: translate(domain.labelCustomerGroups),
			placeholder: translate(domain.placeholderCustomerGroups),
			options: customerGroupOptions,
			loading: customerGroupsLoading,
			optionFilterProp: 'label',
			mode: 'multiple',
			validation: Yup.array()
				.of(Yup.string())
				.test({
					name: 'custom-validation',
					message: translate(domain.groupsValidationMsg),
					test: function (value) {
						const { employeeNotificationGroupIds } = this.parent;

						if (!employeeNotificationGroupIds?.length && !value?.length) {
							return false;
						}
						return true;
					},
				}),
			onSelect: handleCustomerOptionChange as SelectProps['onSelect'],
			render: generateFormSearch,
		},
	];

	const handleClick = () => {
		dispatch(actions.setCurrentModal(GlobalModals.CustomNotification));
		dispatch(actions.loadEmployeeNotificationGroupList());
		dispatch(actions.loadCustomerNotificationGroupList());
	};

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};

	const handleSubmit = (values: {
		messageSubject: string;
		messageContent: string;
		employeeNotificationGroupIds: GenericOption[];
		customerNotificationGroupIds: GenericOption[];
	}) => {
		setLoading(true);
		const payload: NotificationMessagePayload = {
			data: {
				...values,
				employeeNotificationGroupIds: values.employeeNotificationGroupIds.map(
					(group) => group.value,
				),
				customerNotificationGroupIds: values.customerNotificationGroupIds.map(
					(group) => group.value,
				),
			},
			successMessage: translate(domain.successMessage),
		};
		dispatch(actions.sendCustomNotification(payload));
	};

	useEffect(() => {
		setLoading(false);

		setSelectedEmployeeGroups([]);
		setSelectedCustomerGroups([]);
	}, [currentModal]);

	useEffect(() => {
		setEmployeeGroupOptions(mapGroupsToOption(employeeGroups));
	}, [employeeGroups, mapGroupsToOption]);

	useEffect(() => {
		setCustomerGroupOptions(mapGroupsToOption(customerGroups));
	}, [customerGroups, mapGroupsToOption]);

	return (
		<>
			<Tooltip title={translate(domain.title)}>
				<MessageOutlined onClick={handleClick} />
			</Tooltip>
			{currentModal === GlobalModals.CustomNotification && (
				<GenericModalForm
					title={translate(domain.title)}
					formDefinition={formDefinition}
					handleSubmit={handleSubmit}
					handleClose={handleClose}
					footerButtons={
						<FormFooterButtons
							cancelLabel={translate(domain.cancel)}
							submitLabel={translate(domain.submit)}
							closeModal={handleClose}
							submitOnConfirm={true}
							hasConfirmDialog={true}
							isLoading={loading}
							confirmDialogMessage={translate(domain.confirmText)}
							placement="topLeft"
							confirmButtonText={translate(confirmDomain.confirm)}
							cancelButtonText={translate(confirmDomain.cancel)}
						/>
					}
				></GenericModalForm>
			)}
		</>
	);
});
