import './style.scss';

import { DatePicker } from 'antd';
import { Moment } from 'moment';
import React, { memo } from 'react';

import { DATE_FORMAT } from 'utils/constants';

interface Props {
	name: string;
	placeholder?: string;
	defaultValue?: Moment | null;
	disabled?: boolean;
	handleChange: (date: string) => void;
	datatestid?: string;
	currentDate?: Moment | null;
}

export const FilterDate = memo((props: Props) => {
	const {
		name,
		defaultValue,
		placeholder,
		disabled,
		datatestid = 'filter-date',
		currentDate,
	} = props;

	const handleChange = (momentDate: Moment | null, dateString: string) => {
		props.handleChange(dateString);
	};
	return (
		<div data-testid={datatestid} className="date-picker">
			<DatePicker
				name={name}
				placeholder={placeholder}
				defaultValue={defaultValue ?? undefined}
				format={DATE_FORMAT}
				className="calendar__input"
				disabled={disabled}
				size="small"
				onChange={handleChange}
				value={currentDate ?? undefined}
			/>
		</div>
	);
});
