import './style.scss';

import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	DeleteOutlined,
	UploadOutlined,
} from '@ant-design/icons';
import { Progress, Spin, Upload } from 'antd';
import {
	RcCustomRequestOptions,
	RcFile,
	UploadChangeParam,
} from 'antd/lib/upload/interface';
import { useField } from 'formik';
import { Form } from 'formik-antd';
import React, { memo, useState } from 'react';

const { Dragger } = Upload;

interface Props {
	name: string;
	label?: string;
	dragText?: string;
	template?: { error?: boolean; loading?: boolean };
	onChangeDragAndDrop?: (info: UploadChangeParam) => void;
	beforeUpload?: (
		file: RcFile,
		setProgress: (value: number) => void,
	) => boolean | Promise<void>;
	onUpload: (
		options: RcCustomRequestOptions,
		setProgress: (value: number) => void,
	) => void;
	errorList?: Array<string>;
	fileName: string;
	showFileName?: boolean;
	uploadCompleteText?: string;
	onDeleteIcon?: () => void;
}

export const FormDragAndDrop = memo((props: Props) => {
	const {
		name,
		label,
		dragText,
		template,
		onChangeDragAndDrop,
		beforeUpload,
		onUpload,
		errorList,
		fileName,
		showFileName,
		onDeleteIcon,
		uploadCompleteText,
	} = props;

	const [, , helpers] = useField(name);

	const [progress, setProgress] = useState<number>(0);

	const handleUpload = (options: RcCustomRequestOptions) => {
		onUpload && onUpload(options, setProgress);
	};

	const handleBeforeUpload = beforeUpload
		? (file: RcFile) => {
				helpers.setTouched(true);
				helpers.setValue(file);

				return beforeUpload(file, setProgress);
			}
		: undefined;

	const handleDeleteIconClick = () => {
		helpers.setValue('');
		helpers.setTouched(false);
		onDeleteIcon && onDeleteIcon();
	};

	const uploadProps = {
		name: name,
		customRequest: handleUpload,
		showUploadList: false,
		multiple: false,
		beforeUpload: handleBeforeUpload,
		onChange: onChangeDragAndDrop,
		disabled: template?.loading,
	};

	return (
		<Form.Item
			className="dragger-form__content"
			label={label}
			name={name}
			key={name}
		>
			{/* DragAndDrop */}
			<Dragger data-testid="drag-and-drop-container" {...uploadProps}>
				<span className="ant-upload-drag-icon">
					{template?.loading ? (
						<Spin data-testid="drag-and-drop-spin" size="large" />
					) : (
						<UploadOutlined />
					)}
				</span>
				<p className="ant-upload-text">{dragText}</p>
			</Dragger>

			{/* Progress Bar */}
			{template?.loading && !template?.error ? (
				<Progress
					data-testid="drag-and-drop-progress-bar"
					showInfo={false}
					size="small"
					status="active"
					percent={progress}
				/>
			) : null}

			{/* Validation Error List */}
			{errorList && !!errorList.length && (
				<>
					<div className="error-list">
						<span className="filename">{fileName}</span>
					</div>
					<ul className="error-list">
						{errorList.map((error, index) => (
							<li key={`error-${index}`}>
								<CloseCircleOutlined />
								<span>{error}</span>
							</li>
						))}
					</ul>
				</>
			)}
			{showFileName && fileName && !template?.loading && !errorList?.length && (
				<div className="filename-container">
					<div>
						{fileName}
						<DeleteOutlined
							data-testid="delete-icon"
							onClick={handleDeleteIconClick}
						/>
					</div>
					<span>
						<CheckCircleOutlined /> {uploadCompleteText}
					</span>
				</div>
			)}
		</Form.Item>
	);
});
