import './styles.scss';

import { Button, Select } from 'antd';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectCommoditiesList } from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { mapToLabelValue } from 'utils/helpers';
import { useEnabledRegions } from 'utils/hooks/useEnabledRegions';

import { hedgeMappingActions } from '../../../slice';
import { HedgeMappingModals } from '../../../types';

export const Filters = memo(() => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const translationsRoot =
		translations.app.containers.Settings.sections.HedgeMapping.Filters;
	const globalTranslationDomain = translations.app.commons;
	const commoditiesList = useSelector(selectCommoditiesList);

	const regionsOptions = useEnabledRegions(mapToLabelValue);
	const hedgeMapTemplate = 'HrvystHedgeMapTemplate';

	const [commodityId, setCommodityId] = useState('');
	const [regionInfo, setRegionInfo] = useState({
		id: '',
		name: '',
	});

	const commoditiesOptions = mapToLabelValue([...commoditiesList]);

	const handleLoadHedgeMap = () => {
		dispatch(
			hedgeMappingActions.loadHedgeMapList({
				commodityId,
				regionId: regionInfo.id,
				errorTitle: translate(translationsRoot.errorTitle),
				errorDescription: translate(translationsRoot.errorDescription),
			}),
		);
	};

	const handleUploadHedgeMap = () => {
		dispatch(hedgeMappingActions.setCurrentModal(HedgeMappingModals.Upload));
	};

	const handleCommodityChange = (commodityId) => {
		setCommodityId(commodityId);
		dispatch(
			hedgeMappingActions.loadRegionInfo({
				commodityId,
				regionId: regionInfo.id,
			}),
		);
		dispatch(
			hedgeMappingActions.downloadFilledHedgemapRequest({
				commodityId,
				name: hedgeMapTemplate,
				regionName: regionInfo.name,
			}),
		);
	};

	const handleChangeRegions = (_, regionData) => {
		setRegionInfo((prevRegionInfo) => ({
			...prevRegionInfo,
			id: regionData.value,
			name: regionData.label,
		}));
		if (commodityId) {
			dispatch(
				hedgeMappingActions.loadRegionInfo({
					commodityId,
					regionId: regionData.value,
				}),
			);
			dispatch(
				hedgeMappingActions.downloadFilledHedgemapRequest({
					commodityId,
					name: hedgeMapTemplate,
					regionName: regionData.label,
				}),
			);
		}
	};

	return (
		<div className="filters-container upload-hedge-map__filter">
			{regionsOptions && (
				<div>
					<span className="title">
						{translate(globalTranslationDomain.regions)}
					</span>

					<Select
						placeholder={translate(globalTranslationDomain.regionPlaceholder)}
						data-testid="hedge-mapping-filter-region"
						className="select"
						options={regionsOptions}
						onChange={handleChangeRegions}
					/>
				</div>
			)}
			<div>
				<span className="title">
					{translate(translationsRoot.commodityFilter)}
				</span>

				<Select
					placeholder={translate(translationsRoot.filterPlaceholder)}
					data-testid="hedge-mapping-filter-commodity"
					className="select"
					options={commoditiesOptions}
					onChange={handleCommodityChange}
					disabled={Boolean(regionsOptions && !regionInfo.id)}
				/>
			</div>
			<div>
				<Button
					data-testid="hedgemap-load-button"
					type="primary"
					size="middle"
					onClick={handleLoadHedgeMap}
					className="button"
					disabled={Boolean(!commodityId || (regionsOptions && !regionInfo.id))}
				>
					{translate(translationsRoot.loadButton)}
				</Button>
			</div>
			<div>
				<Button
					data-testid="hedgemap-upload-button"
					type="primary"
					size="middle"
					onClick={handleUploadHedgeMap}
					className="button"
					disabled={Boolean(!commodityId || (regionsOptions && !regionInfo.id))}
				>
					{translate(translationsRoot.upLoadButton)}
				</Button>
			</div>
		</div>
	);
});
