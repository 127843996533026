import './styles.scss';

import { Button, Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectCommoditiesList,
	selectContractTypeCatalog,
	selectCropsList,
} from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { mapToLabelValue } from 'utils/helpers';

import { selectServiceFeeTypesList } from '../../../../../GlobalSaga/selectors';
import { ServiceFeeWrapper } from '../../components/ServiceFeeWrapper';
import { selectServiceFeeMonths } from '../../selectors';
import { serviceFeesActions } from '../../slice';

export const CreateEditServiceFee = () => {
	const serviceFeeMonths = useSelector(selectServiceFeeMonths);
	return (
		<>
			<div className="list-container">
				<Filters serviceFeeMonths={serviceFeeMonths} />
				{!!serviceFeeMonths &&
				!!serviceFeeMonths.data &&
				serviceFeeMonths.data.length ? (
					<ServiceFeeWrapper></ServiceFeeWrapper>
				) : (
					''
				)}
			</div>
		</>
	);
};
const Filters = ({ serviceFeeMonths }) => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const translationsScope =
		translations.app.containers.Settings.sections.ServiceFees;

	let commoditiesList: any = useSelector(selectCommoditiesList);

	let transactionList: any = useSelector(selectServiceFeeTypesList);
	let contractTypeList: any = useSelector(selectContractTypeCatalog);

	let cropsList: any = useSelector(selectCropsList);
	cropsList = cropsList.map((year) => {
		return { name: year, id: year };
	});

	const [transaction, setTransactionValue] = useState(null);
	const [commodity, setCommodityValue] = useState(null);
	const [crop, setCropValue] = useState(null);
	const [contract, setContractValue] = useState(null);

	const cropsOptions = mapToLabelValue(cropsList);
	const commoditiesOptions = mapToLabelValue(commoditiesList);
	const transactionOptions = mapToLabelValue(transactionList);
	const contractOptions = mapToLabelValue(contractTypeList);
	const handleChangeCommodities = (commodity) => {
		setCommodityValue(commodity);
	};
	const handleChangeContracts = (contract) => {
		setContractValue(contract);
	};
	const handleChangeCropYears = (crop) => {
		setCropValue(crop);
	};
	const handleChangeTransactions = (transaction) => {
		setTransactionValue(transaction);
	};
	const handleCreateServiceFee = () => {
		dispatch(
			serviceFeesActions.createServiceFee({
				contractTypeId: contract,
				transactionTypeId: transaction,
				commodityId: commodity,
				cropYear: crop,
			}),
		);
	};
	const currentTransaction = transactionOptions.find(
		(option) => option.value === serviceFeeMonths?.transactionTypeId,
	);
	const currentCommodity = commoditiesOptions.find(
		(option) => option.value === serviceFeeMonths?.commodityId,
	);
	const currentCropYear = cropsOptions.find(
		(option) => option.value === serviceFeeMonths?.cropYear,
	);
	const currentContractType = contractOptions.find(
		(option) => option.value === serviceFeeMonths?.contractTypeId,
	);

	const shouldDisableFilters =
		!!currentTransaction ||
		!!currentCommodity ||
		!!currentCropYear ||
		!!currentContractType;

	return (
		<div className="service-fee-bucket">
			<div className="service-fee-filters">
				<Select
					disabled={shouldDisableFilters}
					value={currentTransaction?.value}
					options={transactionOptions}
					onChange={handleChangeTransactions}
					placeholder={translate(
						translationsScope.View.List.Table.Headers.Type,
					)}
					className="select"
				/>

				<Select
					disabled={shouldDisableFilters}
					value={currentContractType?.value}
					options={contractOptions}
					onChange={handleChangeContracts}
					placeholder={translate(
						translationsScope.View.List.Table.Headers.Contract,
					)}
					className="select"
				/>

				<Select
					disabled={shouldDisableFilters}
					value={currentCommodity?.value}
					options={commoditiesOptions}
					onChange={handleChangeCommodities}
					placeholder={translate(
						translationsScope.View.List.Table.Headers.Commodity,
					)}
					className="select"
				/>

				<Select
					disabled={shouldDisableFilters}
					value={currentCropYear?.value}
					options={cropsOptions}
					onChange={handleChangeCropYears}
					placeholder={translate(
						translationsScope.View.List.Table.Headers.CropYear,
					)}
					className="select"
				/>

				<Button
					disabled={shouldDisableFilters}
					type="primary"
					size="middle"
					onClick={handleCreateServiceFee}
					data-testid="create-button"
					className="serviceFee-button"
				>
					{translate(
						translationsScope.View.List.Table.Headers.CreateServiceFees,
					)}
				</Button>
			</div>
		</div>
	);
};
