import { Contract } from 'types/Contract';
import { ContractDetails } from 'types/ContractDetails';
import { ContractsChildren } from 'types/ContractsChildren';
import { EventType } from 'types/EventType';
import { FixedColumn } from 'types/FixedColumn';
import { GenericError } from 'types/GenericError';
import { GenericOption } from 'types/GenericOption';

/**
 * Modals to be displayed
 */
export enum ContractsModals {
	Create = 'create',
	Edit = 'edit',
	Cancel = 'cancel',
	Undo = 'undo',
	PriceRoll = 'price-roll',
	ApplyNameId = 'apply-name-id',
	Convert = 'convert',
	BulkConvert = 'bulk-convert',
	BulkEdit = 'bulk-Edit',
}

/**
 * Container to be rendered. if null will render table with contracts
 */
export enum ContractsViews {
	Table = 'table',
	Details = 'details',
}

export interface ContractsFilters {
	commodities: { value: any; label: string }[];
	customerId: { value: any; label: string }[];
	contractTypeId: { value: any; label: string }[];
	eventId: { value: any; label: string }[];
	contractOrCustomer: string;
	number: string;
	statusView: { value: any; label: string }[];
	futuresMonths: { value: any; label: string }[];
	employeeId: { value: any; label: string }[];
	orderDirection: { value: any; label: string }[];
	transactionTypeId: { value: any; label: string }[];
	destinations: { value: any; label: string }[];
}

export type ContractsFixedColumns = ContractsColumns<FixedColumn>;

type ContractsVisibleColumns = ContractsColumns<boolean>;

type ContractsColumns<T> = {
	created: T;
	contractNumber: T;
	hrvystId: T;
	event: T;
	customer: T;
	employee: T;
	commodity: T;
	crop: T;
	deliveryStartDate: T;
	deliveryEndDate: T;
	futuresMonth: T;
	destination: T;
	pushBasis: T;
	balance: T;
	quantity: T;
	futures: T;
	basis: T;
	cashPrice: T;
	dots: T;
};

/* --- STATE --- */
export interface ContractsState {
	selectedRows: any[];
	success: boolean | null;

	currentModal: ContractsModals | null;
	currentView: ContractsViews | null;

	initialValues: {
		contractType: GenericOption;
		transactionType: GenericOption;
		contractNumber: string;
	};

	selectedFilters: ContractsFilters;

	selectedColumns: ContractsVisibleColumns;

	fixedColumns: ContractsFixedColumns;

	pagination: {
		limit: number;
		start: number;
	};

	contractList: {
		data: Contract[];
		loading: boolean;
		allLoaded: boolean;
		total: number;
		error: GenericError | null;
	};

	activeContract: {
		data: ContractDetails | null;
		loading: boolean;
		error: GenericError | null;
	};

	activeContractDetails: {
		data: ContractDetails | null;
		loading: boolean;
		error: GenericError | null;
	};

	contractsChildren: {
		data: ContractsChildren[] | null;
		loading: boolean;
		error: GenericError | null;
	};

	contractPriceRoll: {
		data: any;
		loading: boolean;
		error: GenericError | null;
		response: any;
	};

	contractApplyNameId: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	currentEventType: EventType;

	spreadValue: {
		data: any;
		response: any;
		loading: boolean;
		error: GenericError | null;
	};

	resendToERP: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};
	cashbidsState: any;

	contractsFuturesMonths: {
		[key: string]: {
			data: GenericOption[];
			loading: boolean;
			error: GenericError | null;
		};
	};
}

export type ContainerState = ContractsState;

export type contract = Contract;

export enum OrderStatus {
	Priced = 'Priced',
	Open = 'Open',
	Canceled = 'Canceled',
	Convert = 'Convert',
}

export enum OrderDirectionOptions {
	Buy = 'Buy',
	Sell = 'Sell',
}
export enum TransactionTypesContracts {
	CashContract = 'Cash Contract',
	SalesTrading = 'Sales Trading',
	Adjustment = 'Adjustment',
	BushelsOnly = 'Bushels Only',
}

export const ERP_STATUS_CODE = {
	0: 'Not Sent',
	1: 'Processing',
	2: 'Failed',
	3: 'Success',
	4: 'No Erp',
	5: 'Timeout',
};
export const ERP_STATUS = {
	NotSent: 0,
	Processing: 1,
	Failed: 2,
	Success: 3,
	NoErp: 4,
	Timeout: 5,
};

export enum ViewOnlyContractTypeOptions {
	Overfill = 'Overfill',
	Underfill = 'Underfill',
	SpotContract = 'Spot Contract',
}
export enum ViewOnlyContractTypeIds {
	Overfill = '2DD0CF19-4103-41D9-B6E8-014D0133A082',
	Underfill = '75A140DD-520E-46BF-ACEE-84020DA00A10',
	SpotContract = '63FAF871-5B64-47D8-8FF3-7E58F4B24C51',
}
