import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslations as useQtyToPriceTranslations } from 'app/containers/Contracts/components/Modals/shared/useTranslations';
import { selectSelectedRows } from 'app/containers/Contracts/selectors';
import { contract } from 'app/containers/Contracts/types';
import { selectDefaultPageData } from 'app/containers/GlobalSaga/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Basis } from 'app/containers/Transactions/components/Basis';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { FlatPrice } from 'app/containers/Transactions/components/FlatPrice';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { PassFill } from 'app/containers/Transactions/components/PassFill';
import { useOrderEntriesFormSchema } from 'app/containers/Transactions/hooks/useOrderEntriesFormSchema';
import { ActionType } from 'types/ActionType';
import { DeliveryDateMode } from 'types/DeliveryDateMode';
import { CONSTANTS, MIN_VALUE } from 'utils/constants';
import {
	customFormat,
	getTotalGrossRemainingBalance,
	isDeliveryDateCustom,
	isDeliveryDateWindow,
} from 'utils/helpers';
import Yup from 'yupCustom';

import { EFPBushels } from '../../../Business/EFPBushels';
import { QuantityToPrice } from '../../../Business/QuantityToPrice';
import { validateMinMaxValue, validatePrice } from '../../shared/helpers';
import { useTranslations } from '../../shared/useTranslations';

export const useFlatPriceSchema = (isAdjustment = false) => {
	const translations = useTranslations();
	const defaultData = useSelector(selectDefaultPageData);

	const qtyToPriceTranslations = useQtyToPriceTranslations();

	const orderDataList = useSelector(selectSelectedRows) as contract[];

	const { elements, initialValues, validationSchema } =
		useOrderEntriesFormSchema();

	const numberMsg = translations.validations.number;

	const requiredMsg = translations.validations.required;
	const basisFormatError = translations.validations.basisFormatError;

	return {
		initialValues: {
			action: ActionType.BUY,
			commodity: { value: null },
			location: { value: null },
			deliveryLocation: { value: null },
			assignedRegion: { value: null },
			deliveryDate: null,
			deliveryDatesMode: { value: DeliveryDateMode.Window },
			deliveryDateWindow: { value: null },
			cropYear: null,
			futuresMonth: { value: null },
			futuresPrice: null,
			qtyPriceBalance: `${customFormat(getTotalGrossRemainingBalance(orderDataList), true, CONSTANTS.FIXED_QUANTITY_DECIMALS)}`,
			qtyPriceAmount: customFormat(
				getTotalGrossRemainingBalance(orderDataList),
				true,
				CONSTANTS.FIXED_QUANTITY_DECIMALS,
			),
			efpBushels: MIN_VALUE,
			postedBasis: null,
			pushBasis: null,
			netBasis: null,
			freight: null,
			fees1: null,
			fees2: null,
			flatPrice: null,
			netFutures: null,
			netBasisPrice: null,
			quantity: null,
			gtcMode: false,
			expirationDate: '',
			employee: {
				label: defaultData?.employeeName,
				value: defaultData?.employeeId,
			},
			comments: '',
			basis: null,
			futures: null,
			passFill: false,
			doNotHedge: false,
			useRoundingRules: false,
			useServiceFees: false,
			...initialValues,
		},

		validationSchema: Yup.object().shape({
			contract: Yup.object().requiredOption(requiredMsg),
			commodity: Yup.object().requiredOption(requiredMsg),
			location: Yup.object().requiredOption(requiredMsg),
			deliveryLocation: Yup.object().requiredOption(requiredMsg),
			assignedRegion: Yup.object().requiredOption(requiredMsg),
			deliveryDate: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateCustom(mode),
				then: Yup.mixed().required(requiredMsg),
			}),
			deliveryDateWindow: Yup.mixed().when('deliveryDatesMode', {
				is: (mode) => isDeliveryDateWindow(mode),
				then: Yup.object().requiredOption(requiredMsg),
			}),
			qtyPriceAmount: Yup.string()
				.required(translations.validations.required)
				.test(
					'minMaxValidation',
					qtyToPriceTranslations.validations.rangeValue(
						customFormat(
							getTotalGrossRemainingBalance(orderDataList),
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						),
					),
					(value: string) =>
						validateMinMaxValue(
							getTotalGrossRemainingBalance(orderDataList),
							value,
						),
				)
				.nullable(),
			freight: Yup.number().typeError(numberMsg).nullable(),
			fees: Yup.number().typeError(numberMsg),
			flatPrice: Yup.number().typeError(numberMsg).required(requiredMsg),
			employee: Yup.object().requiredOption(requiredMsg),
			pushBasis: Yup.number().typeError(numberMsg).nullable(),
			postedBasis: Yup.number()
				.typeError(requiredMsg)
				.required(requiredMsg)
				.test('priceValidation', basisFormatError, (value: string) =>
					validatePrice(value),
				),
			futuresMonth: Yup.object().requiredOption(requiredMsg),
			futuresPrice: Yup.number().typeError(requiredMsg).required(requiredMsg),
			...validationSchema,
		}),
		elements: [
			<ContractType
				resetDependenciesOnChange={false}
				disableBuySell={true}
				ShowNTC
			/>,
			<Commodity checkDefaultValues disabled />,
			<Location />,
			<DeliveryLocation />,
			<AssignedRegion />,
			<CropYears disabled />,
			<DeliveryDates checkDefaultValues />,
			<PassFill priceViaEFP hedgeable />,
			<Futures
				checkDefaultValues
				futuresMonth={{ market: true, disableWithinDeliveryMode: true }}
				futuresPrice={{ market: true, disabled: false }}
				useRoundingRules
			/>,
			<QuantityToPrice
				priceViaEFP
				disabled={{ qtyPriceBalance: true, qtyPriceAmount: true }}
			/>,
			<EFPBushels priceViaEFP />,
			<Basis disabled={{ netBasis: true }} datesModeDependency />,
			<Freight />,
			<Fees />,
			<FlatPrice />,
			<Employee />,
		]
			.concat(elements)
			.concat([<Comments />]),
	};
};
