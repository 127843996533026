import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { GenericModalForm } from 'app/components/GenericModalForm';
import { UseFieldTranslations } from 'app/containers/Settings/definitions/fields/translations';
import { translations } from 'locales/i18n';
import { FieldDefinition } from 'types/FieldDefinition';

import { useFieldFactory } from '../../../../definitions/fields';
import { Tag50Controller } from '../../components/Tag50Controller';
import {
	selectActiveEditEmployee,
	selectActiveEmployeeLoading,
} from '../../selectors';
import { actions } from '../../slice';

export const EditEmployee = () => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(actions.resetActiveEmployee());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fieldList = useFieldFactory();
	const genericTranslations = UseFieldTranslations();
	const activeEmployee = useSelector(selectActiveEditEmployee);
	const activeEmployeeLoading = useSelector(selectActiveEmployeeLoading);

	const domain =
		translations.app.containers.Settings.sections.Employees.Modals.Edit;

	const {
		number,
		firstName,
		lastName,
		email,
		phoneNumber,
		role,
		id,
		tag50,
		tag50Account,
		isGlobalTag50,
	} = activeEmployee;

	const erpNumber = {
		...fieldList.erpNumber,
		disabled: true,
		initialValue: number,
	};

	const nameInput = {
		...fieldList.name.fields[0],
		initialValue: firstName,
	};

	const lastNameInput = {
		...fieldList.name.fields[1],
		initialValue: lastName,
	};

	const roleInput = {
		...fieldList.role,
		initialValue: {
			value: role,
		},
	};

	const fullName = {
		...fieldList.name,
		fields: [nameInput, lastNameInput],
	};

	const phoneNumberInput = {
		...fieldList.cellphone,
		initialValue: phoneNumber,
	};

	const emailInput = {
		...fieldList.email,
		initialValue: email,
		validation: fieldList.email.validation.required(
			genericTranslations.validationSchema.required,
		),
	};

	const tag50Controler = [
		{
			name: 'Tag50Account',
			initialValue: tag50Account,
			render: () => <Tag50Controller isGlobalTag50={isGlobalTag50} />,
			validation: Yup.string().when('tag50toggle', {
				is: true,
				then: Yup.string()
					.nullable()
					.required(genericTranslations.validationSchema.required),
				otherwise: Yup.string().nullable(),
			}),
		},
		{
			name: 'tag50toggle',
			initialValue: tag50,
			render: () => <></>,
		},
	];

	const editEmployeeSchema: FieldDefinition[] = [
		erpNumber,
		fullName,
		roleInput,
		phoneNumberInput,
		emailInput,
		...tag50Controler,
	];

	const handleSubmit = (values) => {
		const fullNameEdited = `${values.firstName} ${values.lastName}`;

		dispatch(
			actions.editEmployee({
				data: {
					firstName: values.firstName,
					lastName: values.lastName,
					number: values.erpNumber,
					role: values.role.value,
					phoneNumber: values.cellphone,
					email: values.email,
					tag50: values.tag50toggle,
					tag50Account: values.tag50toggle ? values.Tag50Account : null,
				},
				id: id,
				successMessage: translate(domain.toastSuccessEditMessage, {
					fullNameEdited,
				}),
			}),
		);
	};

	const handleClose = () => {
		dispatch(actions.setCurrentModal(null));
	};

	return (
		<>
			<GenericModalForm
				title={translate(domain.editEmployeeModalTitle)}
				formDefinition={editEmployeeSchema}
				handleSubmit={handleSubmit}
				handleClose={handleClose}
				footerButtons={
					<FormFooterButtons
						isLoading={activeEmployeeLoading}
						cancelLabel={translate(domain.cancelEmployeeModalButton)}
						submitLabel={translate(domain.saveEmployeeModalButton)}
						closeModal={handleClose}
					/>
				}
			/>
		</>
	);
};
