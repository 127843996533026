import moment from 'moment-timezone';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Calendar } from 'app/components/Calendar';
import { GenericForm } from 'app/components/GenericForm';
import { translations } from 'locales/i18n';

interface Props {
	disabled?: boolean;
	disabledDate?: () => boolean;
	longLabel?: boolean;
}

export const ExpirationDate = memo((props: Props) => {
	const { disabled, disabledDate = defautlDisableDate, longLabel } = props;
	const { t: translate } = useTranslation();
	const translationScope =
		translations.app.containers.Transactions.components.expirationDate;

	function defautlDisableDate(current) {
		return current && current < moment().subtract(1, 'days').endOf('day');
	}
	const label = longLabel ? translationScope.longLabel : translationScope.label;
	return (
		<GenericForm.FormItem name="expirationDate" label={translate(label)}>
			<Calendar
				disabled={disabled}
				placeholder={translate(translationScope.placeholder)}
				customDisabledDate={disabledDate}
			/>
		</GenericForm.FormItem>
	);
});
