import './styles.scss';

import { Button, Select } from 'antd';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectCommoditiesList } from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { mapToLabelValue } from 'utils/helpers';
import { useEnabledRegions } from 'utils/hooks/useEnabledRegions';

import { brokerMappingActions } from '../../slice';

export const Filters = memo(() => {
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const translationsRoot =
		translations.app.containers.Settings.sections.BrokerMapping.Filters;
	const globalTranslationDomain = translations.app.commons;
	const commoditiesList = useSelector(selectCommoditiesList);

	const [commodityId, setCommodityId] = useState('');
	const [regionId, setRegionId] = useState('');

	const commoditiesOptions = mapToLabelValue([...commoditiesList]);

	const regionsOptions = useEnabledRegions(mapToLabelValue);

	const handleCommodityChange = (commodityId: string) => {
		setCommodityId(commodityId);
		dispatch(brokerMappingActions.setCommodityId(commodityId));
		dispatch(brokerMappingActions.setShowDisplay(false));
	};
	const handleChangeRegions = (regionId: string) => {
		setRegionId(regionId);
		dispatch(brokerMappingActions.setRegionId(regionId));
		dispatch(brokerMappingActions.setShowDisplay(false));
	};

	const loadBrokerMapping = () => {
		dispatch(brokerMappingActions.loadMappings({ commodityId, regionId }));
		dispatch(brokerMappingActions.setShowDisplay(true));
	};

	return (
		<div className="filters-container upload-hedge-map__filter">
			{regionsOptions && (
				<div>
					<span className="title">
						{translate(globalTranslationDomain.regions)}
					</span>

					<Select
						placeholder={translate(globalTranslationDomain.regionPlaceholder)}
						data-testid="broker-mapping-filter-region"
						className="select"
						options={regionsOptions}
						onChange={handleChangeRegions}
					/>
				</div>
			)}

			<div>
				<span className="title">
					{translate(translationsRoot.commodityFilter)}
				</span>

				<Select
					placeholder={translate(translationsRoot.filterPlaceholder)}
					data-testid="broker-mapping-filter-commodity"
					className="select"
					options={commoditiesOptions}
					onChange={handleCommodityChange}
				/>
			</div>
			<div>
				<Button
					data-testid="broker-mapping-load-button"
					type="primary"
					size="middle"
					onClick={loadBrokerMapping}
					className="button"
					disabled={Boolean(!commodityId || (regionsOptions && !regionId))}
				>
					{translate(translationsRoot.loadButton)}
				</Button>
			</div>
		</div>
	);
});
