import './styles.scss';

import { Input, Switch } from 'antd';
import React, { memo } from 'react';

import { GenericForm } from 'app/components/GenericForm';
import { ContractType } from 'app/containers/GlobalSaga/types';

import { NTC_ADMIN_FORM_ITEMS } from '../../types';
import { EditButton } from '../EditButton';
import { useNtcAdminRowData } from './hooks/useNtcAdminRowData';
import { DOMAIN } from './utils/helpers';

interface Props {
	ntcAdmin: ContractType | undefined;
	index: number;
}
export const NtcAdminRow = memo(({ index, ntcAdmin }: Props) => {
	const { disabledItems, isActive, ntcAdminId, setDisabledItems, translate } =
		useNtcAdminRowData(index, ntcAdmin);

	const getFormItemId = (key: string) =>
		`${key}-${ntcAdminId !== 'undefined' ? ntcAdminId : index}`;

	const getTranslation = (key: string, values?: any) =>
		translate(DOMAIN[key], values);

	return (
		<>
			<GenericForm.FormItem name={ntcAdminId}>
				<GenericForm.FormItem name={getFormItemId(NTC_ADMIN_FORM_ITEMS.STATUS)}>
					<Switch data-testid="form-item-status" checked={isActive} />
				</GenericForm.FormItem>
				<GenericForm.FormItem
					name={getFormItemId(NTC_ADMIN_FORM_ITEMS.NTC_NAME_LABEL)}
				>
					<Input
						disabled={disabledItems}
						key={getFormItemId(NTC_ADMIN_FORM_ITEMS.NTC_NAME_LABEL)}
						name={getFormItemId(NTC_ADMIN_FORM_ITEMS.NTC_NAME_LABEL)}
						placeholder={getTranslation('ntcNamePlaceholder')}
						type="string"
						maxLength={10}
					/>
				</GenericForm.FormItem>
				<GenericForm.FormItem
					name={getFormItemId(NTC_ADMIN_FORM_ITEMS.ERP_ID_LABEL)}
				>
					<Input
						disabled={disabledItems}
						key={getFormItemId(NTC_ADMIN_FORM_ITEMS.ERP_ID_LABEL)}
						name={getFormItemId(NTC_ADMIN_FORM_ITEMS.ERP_ID_LABEL)}
						placeholder={getTranslation('erpIdPlaceholder')}
						type="string"
					/>
				</GenericForm.FormItem>
				<GenericForm.FormItem name={getFormItemId('edit')}>
					<div onClick={() => setDisabledItems(!disabledItems)}>
						<EditButton />
					</div>
				</GenericForm.FormItem>
			</GenericForm.FormItem>
		</>
	);
});
