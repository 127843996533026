import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { selectHedgeAccountList } from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { mapToLabelValue } from 'utils/helpers';
import { useEnabledRegions } from 'utils/hooks/useEnabledRegions';

import { HedgeAccount } from '../../components/HedgeAccount';

const generateHedgeAccounts = (formFields) => {
	return <HedgeAccount {...formFields} />;
};

export const useHedgeAccountSchema = () => {
	const { t: translate } = useTranslation();
	const domain = translations.app.containers.Settings.hedgeAccount;
	const hedgeAccounts = useSelector(selectHedgeAccountList);
	const regions = useEnabledRegions();

	const generateSchema = (regions) => {
		const regionsShape = regions.reduce((acc, region) => {
			acc[region.id] = Yup.array().of(
				Yup.object().shape({
					HTA: Yup.string().required(translate(domain.requiredValidation)),
					Basis: Yup.string().required(translate(domain.requiredValidation)),
					FlatPrice: Yup.string().required(
						translate(domain.requiredValidation),
					),
				}),
			);
			return acc;
		}, {});

		return Yup.object().shape(regionsShape);
	};

	const validationSchema = generateSchema(regions);
	const initialValues = regions.reduce((acc, region) => {
		acc[region.id] = [];
		return acc;
	}, {});

	const cropsSection = {
		name: 'cropsSection',
		initialValue: initialValues,
		options: mapToLabelValue(hedgeAccounts),
		placeholder: translate(domain.accountTypesPlaceholder),
		validation: validationSchema,
		render: generateHedgeAccounts,
	};

	return [cropsSection];
};
