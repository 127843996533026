import { put } from 'typed-redux-saga';

import { SessionActions } from 'types/SessionActions';
import { SessionErrors } from 'types/SessionErrors';
import { mapUser } from 'utils/auth-map-user';
import {
	FILTERS_FOR_CONTRACTS,
	FILTERS_FOR_OFFERS,
	FILTERS_FOR_ORIGINATOR_SCREEN,
	FILTERS_FOR_REVIEW_AND_RELEASE,
	SESSION_NAME,
} from 'utils/constants';
import { initialFilters } from 'utils/initial-filters';

import { actions } from '../slice';

export function* manageSession(
	action: ReturnType<typeof actions.startManageSession>,
) {
	const sessionAction: SessionActions = action.payload.sessionAction;
	const accessToken = action.payload.accessToken;

	try {
		if (sessionAction === SessionActions.getSession) {
			const hrvst = window.localStorage.getItem(SESSION_NAME);
			const session = hrvst && JSON.parse(hrvst);
			const { userAccount, userInfo } = mapUser(session.accessToken);
			if (!userAccount || !userInfo) {
				yield* put(
					actions.manageSessionError({ detail: SessionErrors.invalidToken }),
				);
				return;
			}
			yield* put(actions.setUserAccount(userAccount));
			yield* put(actions.setUserInfo(userInfo));
			yield* put(actions.sessionLoadedFromLocalStorage());
			yield* put(actions.loadInitData());
		} else if (sessionAction === SessionActions.removeSession) {
			yield* put(actions.setUserAccount(null));
			yield* put(actions.setUserInfo(null));
			window.localStorage.removeItem(SESSION_NAME);
			window.localStorage.removeItem(FILTERS_FOR_CONTRACTS);
			window.localStorage.removeItem(FILTERS_FOR_OFFERS);
			window.localStorage.removeItem(FILTERS_FOR_REVIEW_AND_RELEASE);
		} else if (sessionAction === SessionActions.setSession && accessToken) {
			const { userAccount, userInfo } = mapUser(accessToken);
			if (!userAccount || !userInfo) {
				yield* put(
					actions.manageSessionError({ detail: SessionErrors.invalidToken }),
				);
				return;
			}
			window.localStorage.setItem(
				SESSION_NAME,
				JSON.stringify({ accessToken: accessToken }),
			);

			// save the initial filter DATA at localStorage
			Object.entries(initialFilters).forEach(([key, value]) => {
				// TODO: This condition was added specifically for implementing feature 6660 on the originator screen only.
				// To avoid exceeding the scope of this story, the condition is necessary for now.
				// Once this feature is extended to other filters, the condition will no longer be required,
				// and the following line will suffice: localStorage.setItem(`${key}-${userInfo?.id}`, JSON.stringify(value));
				if (
					key === FILTERS_FOR_ORIGINATOR_SCREEN &&
					!localStorage.getItem(`${key}-${userInfo?.id}`)
				) {
					localStorage.setItem(`${key}-${userInfo?.id}`, JSON.stringify(value));
				} else {
					localStorage.setItem(key, JSON.stringify(value));
				}
			});

			yield* put(actions.setUserAccount(userAccount));
			yield* put(actions.setUserInfo(userInfo));
			yield* put(actions.sessionFromService());
			yield* put(actions.loadInitData());
			yield* put(actions.getCommoditiesCropYearsExpired());
		}
	} catch (error) {
		yield* put(
			actions.manageSessionError({
				detail: error instanceof Error ? error.message : null,
			}),
		);
	}
}
