/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import 'antd/dist/antd.less';
import './styles.scss';

import { LDUser } from 'launchdarkly-js-client-sdk';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { AbacProvider } from 'react-abac';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { Permission } from 'types/Authorization';
import { rules } from 'utils/rbac';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { AppLayout } from './components/AppLayout';
import { NotFoundPage } from './components/NotFoundPage';
import { NotificationsCenter } from './components/NotificationsCenter';
import { PrivateProtectedRoute } from './components/PrivateProtectedRoute';
import { AuthenticationCallback } from './containers/AuthenticationCallback';
import { globalSaga } from './containers/GlobalSaga/saga';
import {
	selectAppNotifications,
	selectAuthUser,
	selectUserInfo,
} from './containers/GlobalSaga/selectors';
import { reducer, sliceKey } from './containers/GlobalSaga/slice';
import { HedgeSummary } from './containers/HedgeSummary';
import { hubSaga } from './containers/HubSaga/saga';
import {
	reducer as hubReducer,
	sliceKey as hubSliceKey,
} from './containers/HubSaga/slice';
import { LiveLedger } from './containers/LiveLedger';
import { Login } from './containers/Login';
import { NotificationsHistory } from './containers/NotificationsHistory';
import { OriginatorScreen } from './containers/OriginatorScreen';
import { Reports } from './containers/Reports';
import { Settings } from './containers/Settings';
import { Transactions } from './containers/Transactions';
import { TransactionsRejectedModal } from './containers/Transactions/components/TransactionsRejectedModal';
import { ROUTES } from './routes';

export const App = () => {
	useInjectReducer({ key: sliceKey, reducer: reducer });
	useInjectSaga({ key: sliceKey, saga: globalSaga });

	useInjectSaga({ key: hubSliceKey, saga: hubSaga });
	useInjectReducer({ key: hubSliceKey, reducer: hubReducer });

	const dispatch = useDispatch();

	const notifications = useSelector(selectAppNotifications);
	const user = useSelector(selectAuthUser);
	const userInfo = useSelector(selectUserInfo);
	const permissions = user?.permissions ? user.permissions : undefined;

	const handleCloseNotification = (id: string) => {
		id && dispatch(globalActions.removeNotification(id));
	};

	// TODO: Make this a store middleware
	const ldClient = useLDClient();
	const featureFlags = useFlags();

	if (ldClient) {
		const context: LDUser = {
			anonymous: true,
			key: 'anonymous',
		};
		if (userInfo) {
			context.anonymous = false;
			context.key = userInfo.email;
			context.name = userInfo.name;
			context.custom = {
				tenant: userInfo.tenant,
			};
		}
		ldClient.identify(context);

		ldClient.waitForInitialization().then(() => {
			dispatch(globalActions.featureFlagsLoaded({ ...featureFlags }));
		});
	}

	return (
		<>
			<AbacProvider
				rules={rules} //TODO: currently it doesn't matter the rules, everything is based on permissions. But this is a required atrribute
				user={user}
				permissions={permissions}
			>
				<BrowserRouter>
					<Helmet titleTemplate="%s - HRVYSTHEDGE" defaultTitle="HRVYSTHEDGE">
						<meta name="description" content="HRVYSTHEDGE Web App" />
					</Helmet>

					<Switch>
						{/* TODO: Remove this redirect after Demo.This Redirect is just for Demo purposes */}
						<Redirect exact from={ROUTES.HOME} to={ROUTES.TRANSACTIONS} />

						<PrivateProtectedRoute exact path={ROUTES.HOME} />

						<PrivateProtectedRoute
							exact
							path={`${ROUTES.TRANSACTIONS}/:tab?/:id?`}
						>
							<AppLayout>
								<Transactions />
								<TransactionsRejectedModal />
							</AppLayout>
						</PrivateProtectedRoute>

						<PrivateProtectedRoute exact path={ROUTES.REPORTS}>
							<AppLayout>
								<Reports />
							</AppLayout>
						</PrivateProtectedRoute>

						<PrivateProtectedRoute
							grant={Permission.CONTRACTSERVICE_ORIGINATOR_BIDS_QUOTES_VIEW}
							exact
							path={ROUTES.CASHBIDS_QUOTES}
						>
							<AppLayout>
								<OriginatorScreen />
							</AppLayout>
						</PrivateProtectedRoute>

						<PrivateProtectedRoute
							grant={Permission.CONTRACTSERVICE_FUTURESERP_VIEW}
							exact
							path={ROUTES.HEDGE_SUMMARY}
						>
							<AppLayout>
								<HedgeSummary />
							</AppLayout>
						</PrivateProtectedRoute>

						<PrivateProtectedRoute
							grant={Permission.CONTRACTSERVICE_LIVELEDGER_VIEW}
							path={ROUTES.LIVELEDGER}
							exact
						>
							<AppLayout>
								<LiveLedger></LiveLedger>
							</AppLayout>
						</PrivateProtectedRoute>

						<PrivateProtectedRoute path={`${ROUTES.SETTINGS}/:section?`} exact>
							<AppLayout>
								<Settings />
							</AppLayout>
						</PrivateProtectedRoute>

						<PrivateProtectedRoute
							path={`${ROUTES.NOTIFICATIONS_HISTORY}`}
							exact
						>
							<AppLayout>
								<NotificationsHistory />
							</AppLayout>
						</PrivateProtectedRoute>

						{/* Auth routes */}
						<Route exact path={ROUTES.LOGIN}>
							<Login></Login>
						</Route>

						<Route exact path={ROUTES.AUTH_CALLBACK}>
							<AuthenticationCallback />
						</Route>

						{/* NotFound */}
						<Route>
							<NotFoundPage />
						</Route>
					</Switch>
				</BrowserRouter>

				<NotificationsCenter
					notificationsList={notifications}
					onCloseNotification={handleCloseNotification}
				/>
			</AbacProvider>
		</>
	);
};
