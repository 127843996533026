import { put, select } from 'typed-redux-saga';

import { actions as hubActions } from 'app/containers/HubSaga/slice';

import { selectAuthUser } from '../selectors';
import { actions } from '../slice';

export function* loadInitData() {
	const authUser = yield* select(selectAuthUser);

	if (!authUser) return;

	yield* put(hubActions.startSignalRConnection());
	yield* put(actions.loadCommoditiesList());
	yield* put(actions.loadCropsList());
	yield* put(actions.loadTransactionsList());
	yield* put(actions.loadContractTypeCatalog());
	yield* put(actions.loadEventList());
	yield* put(actions.loadReviewAndReleaseStates());
	yield* put(actions.loadPreHedgeStates());
	yield* put(actions.loadLocationsList());
	yield* put(actions.loadDestinationsList());
	yield* put(actions.loadHedgeAccounts());
	yield* put(actions.loadRoundingRulesList());
	yield* put(actions.loadDefaultPage());
	yield* put(actions.loadServiceFeeList());
	yield* put(actions.loadServiceFeeTypes());
	yield* put(actions.getOrderEntryRequest({ isActive: true }));
	yield* put(hubActions.getActiveAlertTone());
}
