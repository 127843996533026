import './styles.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Spin } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { FormButtonsProps } from 'app/components/GenericForm/types';
import {
	selectCommoditiesList,
	selectHedgeAccountList,
} from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { GenericOption } from 'types/GenericOption';
import {
	CONTRACT_TYPE_VALUES,
	CONTRACT_TYPES,
	CONTRACT_TYPES_GUIDS,
} from 'utils/constants';
import { mapToLabelValue } from 'utils/helpers';
import Yup from 'yupCustom';

import { useRegionHedgeAccountSchema } from '../../schemas/useRegionHedgeAccountSchema';
import {
	displayMapping,
	selectBrokerMapping,
	selectCommodityId,
	selectCreateMappingLoading,
	selectRegionId,
	selectUpdateMappingLoading,
} from '../../selectors';
import { brokerMappingActions } from '../../slice';

export const Mapping = memo(() => {
	const { t: translate } = useTranslation();
	const translationsRoot =
		translations.app.containers.Settings.sections.BrokerMapping.Map;

	const [loadedMapping, setLoadedMapping] = useState(false);

	const dispatch = useDispatch();
	const brokerMappingState = useSelector(selectBrokerMapping);
	const commodities = useSelector(selectCommoditiesList);
	const regionId = useSelector(selectRegionId);
	const commodityId = useSelector(selectCommodityId);
	const shouldDisplayMapping = useSelector(displayMapping);
	const updateMappingLoading = useSelector(selectUpdateMappingLoading);
	const createMappingLoading = useSelector(selectCreateMappingLoading);

	const selectedCommodity = commodities?.filter(
		(commodity) => commodity.id === commodityId,
	)[0];
	const hedgeAccountList = mapToLabelValue(useSelector(selectHedgeAccountList));

	const tableLoading = brokerMappingState.loading;
	const brokerMapping = brokerMappingState.data;

	const validationSchema = Yup.object().shape({});

	const newRegionSchema = useRegionHedgeAccountSchema(
		selectedCommodity?.cropYears
			?.filter((c) => c.isActive)
			.map((c) => c.cropYear),
	);
	const resolver = yupResolver(validationSchema);

	const getBrokerMappingInitialValues = () => {
		let values = {};
		brokerMapping?.forEach((crop) => {
			crop.mappings?.forEach((contract) => {
				values[contract.id] = { value: contract.hedgeAccountId };
			});
		});
		return values;
	};
	const formInstance = useForm({
		resolver,
		mode: 'all',
		defaultValues: {},
	});

	const handleSubmit = (values) => {
		const data = Object.entries(values).map(([index, value]) => {
			const selectedHedgeAccountId = value as GenericOption;
			return {
				id: index,
				hedgeAccountId: selectedHedgeAccountId?.value,
			};
		});
		dispatch(brokerMappingActions.updateMappings(data));
	};

	const handleSubmitNewMappings = (values) => {
		const data = Object.entries(values).map(([name, value]) => {
			const selectedHedgeAccountId = value as GenericOption;
			const [contractTypeId, cropYear] = name.split('_');
			return {
				hedgeAccountId: selectedHedgeAccountId?.value,
				regionId: regionId ? regionId : null,
				commodityId,
				contractTypeId,
				cropYear: parseInt(cropYear),
			};
		});
		dispatch(brokerMappingActions.createMappings(data));
	};
	const formButtonProps: FormButtonsProps = {
		htmlType: 'submit',
		children: translate(translationsRoot.saveMap),
		key: 'submit',
		dataTestId: 'submit-btn',
	};

	const contractTypes = [
		{ name: CONTRACT_TYPES.flatPrice, id: CONTRACT_TYPE_VALUES.flatPrice },
		{ name: CONTRACT_TYPES.basis, id: CONTRACT_TYPE_VALUES.basis },
		{ name: CONTRACT_TYPES.hta, id: CONTRACT_TYPE_VALUES.hta },
	];

	useEffect(() => {
		formInstance.reset(getBrokerMappingInitialValues());
	}, [brokerMapping]);

	useEffect(() => {
		dispatch(brokerMappingActions.clearMappings());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (tableLoading) setLoadedMapping(true);
	}, [tableLoading]);

	return shouldDisplayMapping ? (
		tableLoading ? (
			<Spin data-testid="broker-mapping-spin" size="large" />
		) : (
			<div className="broker-mapping__container">
				{brokerMapping?.length ? (
					<GenericForm.Form
						formInstance={formInstance}
						validationSchema={validationSchema}
						onSubmit={handleSubmit}
						buttonsDefinition={[
							{
								...formButtonProps,
								disabled:
									!formInstance.formState.isDirty || updateMappingLoading,
							},
						]}
						className="broker-mapping__form"
					>
						{brokerMapping.map((crop) => {
							return (
								<div key={`cropYearSection-${crop.cropYear}`}>
									<Divider
										className="broker-mapping__divider"
										type="horizontal"
									/>
									<div className="broker-mapping__container">
										<label className="broker-mapping__year-label">{`${translate(translationsRoot.cropYear)}: ${crop.cropYear}`}</label>
										<div className="broker-mapping__elements">
											{crop.mappings.map((contract) => {
												const { contractTypeId } = contract;
												const label =
													CONTRACT_TYPES_GUIDS[contractTypeId.toUpperCase()];
												return (
													<GenericForm.FormItem
														name={contract.id}
														label={label}
													>
														<Dropdown
															key={contract.hedgeAccountId}
															options={hedgeAccountList}
														/>
													</GenericForm.FormItem>
												);
											})}
										</div>
									</div>
								</div>
							);
						})}
					</GenericForm.Form>
				) : !brokerMapping?.length && loadedMapping && selectedCommodity ? (
					<GenericForm.Form
						formInstance={newRegionSchema.formInstance}
						validationSchema={newRegionSchema.validationSchema}
						onSubmit={handleSubmitNewMappings}
						buttonsDefinition={[
							{
								...formButtonProps,
								disabled:
									!newRegionSchema.formInstance.formState.isValid ||
									createMappingLoading,
							},
						]}
						className="broker-mapping__form"
					>
						{selectedCommodity.cropYears
							?.filter((crop) => crop.isActive)
							.map((crop) => {
								return (
									<div key={`cropYearSection-${crop.cropYear}`}>
										<Divider
											className="broker-mapping__divider"
											type="horizontal"
										/>
										<div className="broker-mapping__container">
											<label className="broker-mapping__year-label">{`${translate(translationsRoot.cropYear)}: ${crop.cropYear}`}</label>
											<div className="broker-mapping__elements">
												{contractTypes.map((contractType) => {
													return (
														<GenericForm.FormItem
															name={`${contractType.id}_${crop.cropYear}`}
															label={contractType.name}
														>
															<Dropdown
																key={`${contractType.id}_${crop.cropYear}`}
																options={newRegionSchema.options}
															/>
														</GenericForm.FormItem>
													);
												})}
											</div>
										</div>
									</div>
								);
							}) ?? <></>}
					</GenericForm.Form>
				) : (
					<></>
				)}
			</div>
		)
	) : (
		<></>
	);
});
