import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'typed-redux-saga';
import { z } from 'zod';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';
import { genericItemSchema } from 'utils/validators';

import { actions } from '../slice';
import { GenericLoadListPayload } from '../types';

const { employeeRoot } = apiEndpoints;

const employeesResponseSchema = z.object({
	total: z.number().int(),
	employees: z.array(genericItemSchema),
});

export function* getEmployeesList(
	action: PayloadAction<GenericLoadListPayload>,
) {
	const source = action.payload.source;

	let requestURL = new URL(`${apiRoutes.base}/${employeeRoot}`);
	if (action.payload.search) {
		requestURL.searchParams.append('name', action.payload.search);
	}
	requestURL.searchParams.append(
		'isActiveOnly',
		(action.payload.isActiveOnly || false).toString(),
	);

	const request = requestWithSchema<z.infer<typeof employeesResponseSchema>>;
	const { data, error } = yield* call(request, requestURL, httpMethod.Get, {
		schema: employeesResponseSchema,
	});

	if (!!data) {
		const payload = {
			source,
			data: data.employees,
		};

		yield* put(actions.employeesListLoaded(payload));
	} else if (!!error?.detail) {
		const payload = {
			source,
			error,
		};

		yield* put(actions.employeesListError(payload));
	}
}
