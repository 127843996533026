import React from 'react';
import { useSelector } from 'react-redux';

import { FuturesPrice } from 'app/containers/Contracts/components/Business/FuturesPrice';
import { OriginDeliveryWindow } from 'app/containers/Contracts/components/Business/OriginDeliveryWindow';
import { QuantityToPrice } from 'app/containers/Contracts/components/Business/QuantityToPrice';
import { useTranslations } from 'app/containers/Contracts/components/Modals/shared/useTranslations';
import { selectActiveContract } from 'app/containers/Contracts/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDate } from 'app/containers/Transactions/components/ExpirationDate';
import { ExpirationDateGTC } from 'app/containers/Transactions/components/ExpirationDateGTC';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { CONSTANTS } from 'utils/constants';
import {
	customFormat,
	dateFormatYearMonthDay,
	deliveryWindowFormat,
	getExpirationDate,
	getFormattedExpirationDate,
	validatePriceFormat,
	validateQuarterCents,
} from 'utils/helpers';
import Yup from 'yupCustom';

import { validateMinMaxValue } from '../../../shared/helpers';

export const useBasisSchema = () => {
	const translations = useTranslations();

	let orderData = useSelector(selectActiveContract) as any;
	if (!!!orderData) return null;

	const requiredMsg = translations.validations.required;

	return {
		initialValues: {
			theirContract: orderData.theirContract,
			location: {
				label: orderData.locationName,
				value: orderData.locationId,
			},
			deliveryLocation: {
				label: orderData.deliveryLocationName,
				value: orderData.deliveryLocationId,
			},
			assignedRegion: {
				label: orderData?.regionName,
				value: orderData?.regionId,
			},
			cropYear: orderData.cropYear,
			deliveryWindow: deliveryWindowFormat(
				orderData.futuresMonth,
				orderData.deliveryStartDate,
				orderData.deliveryEndDate,
				false,
			),
			futuresMonth: {
				label: orderData.futuresMonth,
				value: orderData.futuresMonth,
			},
			futuresPriceInput: validatePriceFormat(orderData.futuresPrice),
			qtyPriceBalance: `${customFormat(orderData.remainingBalance, true, CONSTANTS.FIXED_QUANTITY_DECIMALS)}`,
			qtyPriceAmount: null,
			netBasis: validatePriceFormat(orderData.netBasis),
			freight: validatePriceFormat(orderData.freightPrice),
			fees1: validatePriceFormat(orderData.fees1),
			fees2: validatePriceFormat(orderData.fees2),
			employee: {
				label: orderData.employeeName,
				value: orderData.employeeId,
			},
			comments: null,
			gtcMode: false,
			expirationDateGTC: null,
			expirationDate: getExpirationDate(orderData),
		},
		validationSchema: Yup.object().shape({
			location: Yup.object().requiredOption(requiredMsg),
			deliveryLocation: Yup.object().requiredOption(requiredMsg),
			assignedRegion: Yup.object().requiredOption(requiredMsg),
			qtyPriceAmount: Yup.string()
				.required(translations.validations.required)
				.test(
					'minMaxValidation',
					translations.validations.rangeValue(
						customFormat(
							orderData.remainingBalance,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						),
					),
					(value: string) =>
						validateMinMaxValue(orderData.remainingBalance, value),
				)
				.nullable(),
			futuresPriceInput: Yup.number()
				.typeError(requiredMsg)
				.required(requiredMsg)
				.test(
					'quarterCentsValidation',
					translations.validations.futuresPriceQuarterMsg,
					(val) => validateQuarterCents(val),
				),
			employee: Yup.object().requiredOption(requiredMsg),
			freight: Yup.number().typeError(translations.validations.number),
			fees1: Yup.number().typeError(translations.validations.number),
			fees2: Yup.number().typeError(translations.validations.number),
			expirationDateGTC: Yup.string().when('gtcMode', {
				is: false,
				then: Yup.string().typeError(requiredMsg).required(requiredMsg),
				otherwise: Yup.string().nullable(),
			}),
			expirationDate: Yup.string().nullable(),
			deliveryWindow: Yup.string().required(requiredMsg),
			futuresMonth: Yup.object().requiredOption(requiredMsg),
		}),
		elements: [
			<Location />,
			<DeliveryLocation />,
			<AssignedRegion />,
			<CropYears disabled />,
			<OriginDeliveryWindow disabled deliveryDateLabel />,
			<Futures futuresMonth={{ market: true, disabled: true }} />,
			<QuantityToPrice disabled={{ qtyPriceBalance: true }} />,
			<FuturesPrice />,
			<ExpirationDateGTC messageGTC disableWeekends />,
			<ExpirationDate disabled longLabel />,
			<Freight />,
			<Fees />,
			<Employee />,
			<Comments />,
		],
		getSubmitValues: (values) => {
			const submitValues = {
				quantity: parseFloat(
					customFormat(
						values.qtyPriceAmount,
						false,
						CONSTANTS.FIXED_QUANTITY_DECIMALS,
					),
				),
				futuresPrice: parseFloat(values.futuresPriceInput),
				gtc: values.gtcMode,
				expiration: !values.expirationDateGTC
					? null
					: dateFormatYearMonthDay(values.expirationDateGTC),
				freightPrice: parseFloat(values.freight),
				fees1: parseFloat(values.fees1),
				fees2: parseFloat(values.fees2),
				basis: parseFloat(values.netBasis) || null,
				employeeId: values.employee?.value,
				comments: values.comments ?? '',
				locationId: values.location?.value,
				deliveryLocationId: values.deliveryLocation?.value,
				expirationDate: getFormattedExpirationDate(values),
				theirContract: orderData.theirContract,
				regionId: values?.assignedRegion?.value,
			};

			return submitValues;
		},
	};
};
