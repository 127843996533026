/**
 *
 * RoundPill
 *
 */
import './style.scss';

import { Popover } from 'antd';
import classNames from 'classnames';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { translations } from 'locales/i18n';

interface Props {
	isSell: boolean;
}

export const IsSellRoundPill = memo((props: Props) => {
	const messages = translations.app.components.IsSellRoundPill;
	const { t } = useTranslation();
	const { isSell } = props;
	return (
		<Popover
			content={
				<span style={{ padding: '0 1em' }}>
					{isSell ? t(messages.sellFull) : t(messages.purchaseFull)}
				</span>
			}
		>
			<span
				className={classNames(
					'is-sell-round-pill',
					{
						'is-sell-round-pill--buy': !isSell,
					},
					{
						'is-sell-round-pill--sell': isSell,
					},
				)}
				data-testid={`contract-type-is-sell-${isSell}`}
			>
				{isSell ? t(messages.sellAbbreviated) : t(messages.purchaseAbbreviated)}
			</span>
		</Popover>
	);
});
