import './style.scss';

import { MoreOutlined } from '@ant-design/icons';
import { Button, Popover, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';
import { SwitchStateFull } from 'app/components/SwitchStateFull';
import { selectUserRolesListData } from 'app/containers/Settings/sections/UserRoles/selectors';
import { translations } from 'locales/i18n';
import { GenericItem } from 'types/GenericItem';

import {
	selectActiveEmployeeLoading,
	selectEmployeeStatusData,
	selectEmployeeStatusError,
	selectEmployeeStatusLoading,
	selectFilters,
	selectListData,
	selectListLoading,
	selectListTotal,
	selectPaginationLimit,
	selectPaginationStart,
} from '../../../selectors';
import { actions } from '../../../slice';
import { FiltersEnum } from '../../../types';
import { Actions } from './Actions';

const { Paragraph } = Typography;

export const Table = () => {
	const { t: translate } = useTranslation();
	const translationsScope =
		translations.app.containers.Settings.sections.Employees;
	const translationsShared = translations.app.containers.Settings.Shared;
	const dispatch = useDispatch();
	const listData = useSelector(selectListData);
	const listTotal = useSelector(selectListTotal);
	const listLoading = useSelector(selectListLoading);
	const start = useSelector(selectPaginationStart);
	const limit = useSelector(selectPaginationLimit);
	const filters = useSelector(selectFilters);
	const loadingEmployee = useSelector(selectActiveEmployeeLoading);
	const rolesList: GenericItem[] = useSelector(selectUserRolesListData);

	const rolesMap = rolesList.reduce((accumulator, currentValue) => {
		return {
			...accumulator,
			[currentValue.id]: currentValue.name,
		};
	}, {});

	const columns = [
		{
			title: translate(translationsScope.View.List.Table.Headers.Status),
			dataIndex: 'isActive',
			key: 'isActive',
			fixed: true,
			width: 70,
			render: (data, record) => (
				<GenericTable.Column>
					<SwitchStateFull
						initialStatus={data}
						recordId={record.id}
						toggleAction={actions.setEmployeeStatus}
						errorSelector={selectEmployeeStatusError}
						loadingSelector={selectEmployeeStatusLoading}
						activeRecordSelector={selectEmployeeStatusData}
						toogleOnMessage={translate(
							translationsScope.View.List.Table.activateSwitch.toogleOnMessage,
						)}
						toggleOffMessage={translate(
							translationsScope.View.List.Table.activateSwitch.toggleOffMessage,
						)}
						confirmButtonText={translate(
							translationsScope.View.List.Table.activateSwitch
								.confirmButtonText,
						)}
						cancelButtonText={translate(
							translationsScope.View.List.Table.activateSwitch.cancelButtonText,
						)}
						stateControlled={false}
					/>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.Erp),
			dataIndex: 'number',
			key: 'number',
			fixed: true,
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.FirstName),
			dataIndex: 'firstName',
			key: 'firstName',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.LastName),
			dataIndex: 'lastName',
			key: 'lastName',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.Role),
			dataIndex: 'role',
			key: 'role',
			render: (roleId) => {
				const roleLabel = rolesMap[roleId] || '';

				return (
					<GenericTable.Column>
						<Paragraph
							className="text text--large ellipsis"
							title={roleLabel}
							ellipsis={{ rows: 2 }}
						>
							<p className="text text--large">{roleLabel}</p>
						</Paragraph>
					</GenericTable.Column>
				);
			},
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.CellPhone),
			dataIndex: 'phoneNumber',
			key: 'phoneNumber',
			width: 150,
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.Email),
			dataIndex: 'email',
			key: 'email',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">{data}</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.View.List.Table.Headers.tag50Header),
			dataIndex: 'tag50',
			key: 'tag50',
			className: 'sm',
			render: (data) => (
				<GenericTable.Column>
					<Paragraph
						className="text text--large ellipsis"
						title={data}
						ellipsis={{ rows: 2 }}
					>
						<p className="text text--large">
							{data
								? translate(translationsScope.View.List.Table.Columns.tag50.Yes)
								: translate(translationsScope.View.List.Table.Columns.tag50.No)}
						</p>
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			fixed: 'right' as 'right',
			render: (data) => (
				<GenericTable.Column>
					<Popover
						content={<Actions rowData={data} />}
						trigger="click"
						data-testid="more-actions-trigger"
					>
						<Button
							data-testid="table-button-actions"
							loading={loadingEmployee}
							type="text"
							icon={<MoreOutlined className="more-actions-button" />}
						/>
					</Popover>
				</GenericTable.Column>
			),
		},
	];

	const handleOnChange = (page, pageSize) => {
		dispatch(
			actions.setPagination({
				limit: pageSize,
				start: page,
			}),
		);
		dispatch(actions.loadEmployeesList());
	};

	return (
		<>
			<GenericTable.Table
				otherClassName="table-container employees"
				columns={columns}
				data={listData}
				loading={listLoading || loadingEmployee}
				selectedFilters={filters}
				pagination={{
					total: listLoading ? 0 : listTotal,
					pageSize: limit,
					current: start,
					onChange: handleOnChange,
				}}
				emptyText={translate(translationsShared.Table.emptyText, {
					query: filters[FiltersEnum.Query],
				})}
			/>
		</>
	);
};
