import { z } from 'zod';

const _cropYearSchema = z.object({
	cropYear: z.number().int(),
	cropYearEndDate: z.string(),
	cropYearStartDate: z.string().nullable(),
	expiration: z.number().int(),
	isActive: z.boolean(),
});
interface CropYear extends z.infer<typeof _cropYearSchema> {}
export const cropYearSchema: z.Schema<CropYear> = _cropYearSchema;
