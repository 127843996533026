import { useDispatch, useSelector } from 'react-redux';

import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import { FeatureFlag } from 'types/FeatureFlags';
import { GenericOption } from 'types/GenericOption';
import {
	filterSelectedOption,
	mapPropertyToLabelValue,
	optionToLabelValue,
} from 'utils/helpers';

import {
	selectSearchListDataForContractLocation,
	selectSearchListDataForContractLocationLoadingState,
	selectSearchListDataForDestinationLocation,
	selectSearchListDataForDestinationLocationLoadingState,
} from '../selectors';
import { actions } from '../slice';
import {
	CurrentGroupedLocation,
	LocationField,
	LocationListResponse,
} from './types';
import { useLocationFieldFactory } from './useLocationFieldFactory';

export const useAutocompleteEditSchema = (
	currentLocation: CurrentGroupedLocation | null = null,
	isEditMode: boolean,
): LocationField[] => {
	const dispatch = useDispatch();
	const locationGeneralFields = useLocationFieldFactory(currentLocation);
	const contractLocationSearchResult = useSelector(
		selectSearchListDataForContractLocation,
	);
	const contractLocationSearchResultIsLoading = useSelector(
		selectSearchListDataForContractLocationLoadingState,
	);
	const destinationLocationSearchResult = useSelector(
		selectSearchListDataForDestinationLocation,
	);
	const destinationLocationSearchResultIsLoading = useSelector(
		selectSearchListDataForDestinationLocationLoadingState,
	);
	const featureFlags = useSelector(selectFeatureFlags);

	const initialValueDestinationLocationName = currentLocation
		? optionToLabelValue(currentLocation, 'destinationLocationName')
		: '';
	const initialValueDestinationLocationNumber = currentLocation
		? optionToLabelValue(currentLocation, 'destinationLocationNumber')
		: '';
	const initialValueContractLocationName = currentLocation
		? optionToLabelValue(currentLocation, 'contractLocationName')
		: '';
	const initialValueContractLocationNumber = currentLocation
		? optionToLabelValue(currentLocation, 'contractLocationNumber')
		: '';

	const mapNumberProperty = (list) =>
		mapPropertyToLabelValue(list, 'number') || [];
	const mapNameProperty = (list) => mapPropertyToLabelValue(list, 'name') || [];

	let fields: LocationField[] = [
		{
			...locationGeneralFields.contractLocationName,
			fieldsNeedToBeChanged: 'contractLocationNumber',
			options: mapNameProperty(contractLocationSearchResult),
			loading: contractLocationSearchResultIsLoading,
			initialValue: initialValueContractLocationName,
			onSearch: (name) =>
				dispatch(actions.loadSearchLocationsListForContractLocation({ name })),
			filterSelectedItem: (selectedItem) =>
				filterSelectedOption(
					contractLocationSearchResult,
					selectedItem,
					'name',
					mapNumberProperty,
				),
			isEditMode,
		},
		{
			...locationGeneralFields.contractLocationNumber,
			fieldsNeedToBeChanged: 'contractLocationName',
			options: mapNumberProperty(contractLocationSearchResult),
			loading: contractLocationSearchResultIsLoading,
			initialValue: initialValueContractLocationNumber,
			onSearch: (number) =>
				dispatch(
					actions.loadSearchLocationsListForContractLocation({ number }),
				),
			filterSelectedItem: (selectedItem) =>
				filterSelectedOption<'number', LocationListResponse, GenericOption>(
					contractLocationSearchResult,
					selectedItem,
					'number',
					mapNameProperty,
				),
			isEditMode,
		},
		{
			...locationGeneralFields.destinationLocationName,
			fieldsNeedToBeChanged: 'destinationLocationNumber',
			options: mapNameProperty(destinationLocationSearchResult),
			loading: destinationLocationSearchResultIsLoading,
			initialValue: initialValueDestinationLocationName,
			onSearch: (name) =>
				dispatch(
					actions.loadSearchLocationsListForDestinationLocation({ name }),
				),
			filterSelectedItem: (selectedItem) =>
				filterSelectedOption(
					destinationLocationSearchResult,
					selectedItem,
					'name',
					mapNumberProperty,
				),
			isEditMode,
		},
		{
			...locationGeneralFields.destinationLocationNumber,
			fieldsNeedToBeChanged: 'destinationLocationName',
			options: mapNumberProperty(destinationLocationSearchResult),
			loading: destinationLocationSearchResultIsLoading,
			initialValue: initialValueDestinationLocationNumber,
			onSearch: (number) =>
				dispatch(
					actions.loadSearchLocationsListForDestinationLocation({ number }),
				),
			filterSelectedItem: (selectedItem) =>
				filterSelectedOption(
					destinationLocationSearchResult,
					selectedItem,
					'number',
					mapNameProperty,
				),
			isEditMode,
		},
	];

	fields = [
		...fields,
		locationGeneralFields.regionId,
		{ ...locationGeneralFields.manualEditMode, initialValue: false },
	];

	return fields;
};
