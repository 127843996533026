import { z } from 'zod';

const _groupedLocationSchema = z.object({
	id: z.string().uuid(),
	name: z.string(),
	forBuy: z.boolean(),
	forSell: z.boolean(),
	contractLocationId: z.string().uuid(),
	destinationLocationId: z.string().uuid(),
	regionId: z.string().uuid(),
});
export interface GroupedLocation
	extends z.infer<typeof _groupedLocationSchema> {}
export const groupedLocationSchema = _groupedLocationSchema;
