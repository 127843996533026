import { call, put } from 'typed-redux-saga';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from '../slice';
import {
	ReviewAndReleaseTransactionsStatus,
	reviewAndReleaseTransactionsStatusSchema,
} from '../types';

const { reviewAndReleaseOrderStatus } = apiEndpoints;

export function* getReviewAndReleaseOrderStatus() {
	const requestURL = new URL(
		`${apiRoutes.base}/${reviewAndReleaseOrderStatus}`,
	);

	const request = requestWithSchema<ReviewAndReleaseTransactionsStatus>;
	const { data, error } = yield* call(request, requestURL, httpMethod.Get, {
		schema: reviewAndReleaseTransactionsStatusSchema,
	});

	if (!!data) {
		yield* put(actions.getReviewAndReleaseOrderStatusSuccess(data));
	} else if (!!error?.detail) {
		yield* put(actions.getReviewAndReleaseOrderStatusError(error));
	}
}
