/* --- STATE --- */
import { GenericError } from 'types/GenericError';
export interface ServiceFeeState {
	currentView: View | null;
	currentModal: Modal | null;
	commoditiesList: {
		data: any[];
	};
	activeServiceFees: {
		data: any;
		error: GenericError | null;
		loading: boolean;
	};

	list: {
		data: any[];
		loading: boolean;
		error: GenericError | null;
	};

	serviceFeeMonths: {
		data: any[];
		loading: boolean;
		error: GenericError | null;
		id: string;
		transactionTypeId?: string;
		commodityId?: string;
		cropYear?: number | null;
		contractTypeId?: string;
	};

	loadServiceFeeMonths: {
		data: any[];
		loading: boolean;
		error: GenericError | null;
	};

	serviceFeesStatus: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	createServiceFee: {
		data: any;
		loading: boolean;
		error: GenericError | null;
	};

	selectedFilters: {
		commodities: null;
		type: null;
		contract: null;
		cropYear: null;
	};
}

export enum View {
	List = 'list',
	Create = 'create',
	Edit = 'edit',
	Delete = 'delete',
}

export enum Modal {
	Delete = 'Delete',
}

export interface TogglePayload {
	id: string;
	isActive: boolean;
}
