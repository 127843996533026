import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'typed-redux-saga';
import { z } from 'zod';

import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from '../slice';
import { LoadFuturesPricePayload } from '../types';

const { futuresPrice } = apiEndpoints;

const futuresPriceResponseSchema = z.object({
	price: z.number(),
	priceControl: z.number().nullable(),
	// note: there's a lot more included in the response, but this is all we use here
});

export function* getFuturePriceFromMarket(
	action: PayloadAction<LoadFuturesPricePayload>,
) {
	const source = action.payload.source;
	const params = action.payload.params;

	if (!params.commodityId || !params.futuresMonth) {
		return;
	}

	let requestURL = new URL(`${apiRoutes.base}/${futuresPrice}`);
	requestURL.searchParams.append('CommodityId', params.commodityId);
	requestURL.searchParams.append('FutureMonth', params.futuresMonth);

	const request = requestWithSchema<z.infer<typeof futuresPriceResponseSchema>>;
	const { data, error } = yield* call(request, requestURL, httpMethod.Get, {
		schema: futuresPriceResponseSchema,
	});

	if (!!data) {
		const payload = {
			source,
			data: data.price,
		};
		const cachedFuturesPricePayload = {
			futuresPrice: data.price,
			priceControl: data.priceControl ?? 0,
		};

		yield* put(actions.futuresPriceLoaded(payload));
		yield* put(actions.cachedFuturesPriceLoaded(cachedFuturesPricePayload));
	} else if (error?.detail) {
		const payload = {
			source,
			error: error,
		};

		yield* put(actions.futuresPriceError(payload));
	}
}
