import './style.scss';

import { Select } from 'antd';
import React, { memo, useState } from 'react';

import { GenericOption } from 'types/GenericOption';

import { TagWrapped } from '../TagWrapped';

interface Props {
	currentOption: GenericOption[];
	handleChange: (selectedValues: GenericOption[]) => void;
	handleSearch: (searchValue: string) => void;
	loading?: boolean;
	maxTagTextLength?: number;
	notFoundContent?: React.ReactNode;
	options: GenericOption[];
	placeholder?: string;
}

export const FilterAutocomplete = memo(
	({
		currentOption,
		handleChange: propHandleChange,
		handleSearch: propHandleSearch,
		loading,
		maxTagTextLength = 15,
		notFoundContent,
		options,
		placeholder,
	}: Props) => {
		const [open, setOpen] = useState(false);
		const { Option } = Select;

		const handleBlur = () => {
			setOpen(false);
		};

		const handleChange = async (selectedValues: GenericOption[]) => {
			if (selectedValues.length > 1) selectedValues.shift();
			setOpen(false);
			propHandleChange(selectedValues);
		};

		const handleClick = () => {
			setOpen(!open);
		};

		const handleSearch = (searchValue: string) => {
			propHandleSearch(searchValue);
		};

		return (
			<Select
				className="filter-autocomplete"
				mode="multiple"
				size="small"
				labelInValue
				placeholder={placeholder}
				notFoundContent={notFoundContent}
				filterOption={false}
				open={open}
				onClick={handleClick}
				onBlur={handleBlur}
				allowClear={false}
				showArrow={true}
				menuItemSelectedIcon={null}
				maxTagTextLength={maxTagTextLength}
				loading={loading}
				onSearch={handleSearch}
				onChange={handleChange}
				tagRender={TagWrapped}
				value={currentOption}
			>
				{!!options &&
					options?.map((option) => (
						<Option key={option.value} value={option.value}>
							{option.label}
						</Option>
					))}
			</Select>
		);
	},
);
