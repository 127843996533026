import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectNtcAdmin } from 'app/containers/GlobalSaga/selectors';
import { ContractType } from 'app/containers/GlobalSaga/types';

export const useNtcAdminRowData = (
	index: number,
	ntcAdmin: ContractType | undefined,
) => {
	const { t: translate } = useTranslation();
	const NTCs = useSelector(selectNtcAdmin);
	const isCreatingNTCs = NTCs?.length === 0;
	const isActive = isCreatingNTCs || !!ntcAdmin?.isActive;

	const [disabledItems, setDisabledItems] = useState(true);

	const ntcAdminId = ntcAdmin?.id || 'undefined';

	return {
		disabledItems,
		isActive,
		ntcAdminId,
		setDisabledItems,
		translate,
	};
};
