import './style.scss';

import { PayloadAction } from '@reduxjs/toolkit';
import { Button, Checkbox, Popover } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { translations } from 'locales/i18n';
import { GenericOption } from 'types/GenericOption';
import { UpdatePersistedData } from 'utils/helpers';

type VisibleColumnsProps<T extends Record<string, boolean>> = {
	persistedDataKey: string;
	selectedColumns: T;
	setSelectedColumns: (fixedColumns: T) => PayloadAction<T>;
	columns: string[];
	mapOptions: (values: string[]) => GenericOption[];
};

export const VisibleColumns = memo(
	<T extends Record<string, boolean>>({
		persistedDataKey,
		selectedColumns,
		setSelectedColumns,
		columns,
		mapOptions,
	}: VisibleColumnsProps<T>) => {
		const [allVisibleColumns, setAllVisibleColumns] = useState<GenericOption[]>(
			[],
		);

		useEffect(() => {
			setAllVisibleColumns(mapOptions(columns));
		}, [columns, mapOptions]);

		const dispatch = useDispatch();
		const { t: translate } = useTranslation();

		const handleSetSelectedColumns = (
			option: GenericOption,
			oldColumns: T,
			dispatchAction: (columns: T) => PayloadAction<T>,
			persistedDataKey: string,
		) => {
			const newColumns = {
				...oldColumns,
				[option.value]: !oldColumns[option.value],
			};
			UpdatePersistedData(persistedDataKey, oldColumns, newColumns);
			dispatch(dispatchAction(newColumns));
		};

		return (
			<Popover
				content={
					<div className="grid-menu-dropdown">
						{allVisibleColumns.map((option) => (
							<Checkbox
								key={option.value}
								data-testid={`filter-multiple-${option.label}`}
								className="checkbox--no-border-radius"
								checked={selectedColumns[option.value]}
								onChange={() =>
									handleSetSelectedColumns(
										option,
										selectedColumns,
										setSelectedColumns,
										persistedDataKey,
									)
								}
							>
								{option.label}
							</Checkbox>
						))}
					</div>
				}
				trigger="click"
			>
				<Button type="link" className="column-pref">
					{translate(translations.app.commons.visibleColumnsLabel)}
				</Button>
			</Popover>
		);
	},
);
