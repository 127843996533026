import './style.scss';

import { Tag } from 'antd';
import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { useAbac } from 'react-abac';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { TabsWrapper } from 'app/components/TabsWrapper';
import { Contracts } from 'app/containers/Contracts';
import { contractsSaga } from 'app/containers/Contracts/root-saga';
import { actions as globalActions } from 'app/containers/GlobalSaga/slice';
import { Offers } from 'app/containers/Offers';
import { offersSaga } from 'app/containers/Offers/root-saga';
import { OffersViews } from 'app/containers/Offers/types';
import { reviewAndReleaseSaga } from 'app/containers/ReviewAndRelease/root-saga';
import { ROUTES } from 'app/routes';
import { translations } from 'locales/i18n';
import { Permission } from 'types/Authorization';
import { GlobalSagaSource } from 'types/GlobalSagaSource';
import { useFeatureFlags } from 'utils/hooks/useFeatureFlags';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import {
	actions as contractsActions,
	reducer as contractsReducer,
	sliceKey as contractsKey,
} from '../Contracts/slice';
import { ContractsViews } from '../Contracts/types';
import { selectOrderStatus } from '../GlobalSaga/selectors';
import {
	actions as offersActions,
	reducer as offersReducer,
	sliceKey as offersKey,
} from '../Offers/slice';
import { PreHedge } from '../PreHedge';
import { preHedgeSaga } from '../PreHedge/root-saga';
import {
	actions as preHedgeActions,
	reducer as preHedgeReducer,
	sliceKey as preHedgeKey,
} from '../PreHedge/slice';
import { PreHedgeViews } from '../PreHedge/types';
import { ReviewAndRelease } from '../ReviewAndRelease';
import {
	actions as reviewAndReleaseActions,
	reducer as reviewAndReleaseReducer,
	sliceKey as reviewAndReleaseKey,
} from '../ReviewAndRelease/slice';
import { ReviewAndReleaseViews } from '../ReviewAndRelease/types';
import {
	TransactionsTabs as TransactionsTabsEnum,
	TransactionTab,
} from './types';

export const SourceContext = React.createContext<GlobalSagaSource>(
	GlobalSagaSource.contractModal,
);

export function Transactions() {
	useInjectReducer({ key: contractsKey, reducer: contractsReducer });
	useInjectSaga({ key: contractsKey, saga: contractsSaga });

	useInjectReducer({ key: offersKey, reducer: offersReducer });
	useInjectSaga({ key: offersKey, saga: offersSaga });

	useInjectReducer({ key: preHedgeKey, reducer: preHedgeReducer });
	useInjectSaga({ key: preHedgeKey, saga: preHedgeSaga });

	useInjectReducer({
		key: reviewAndReleaseKey,
		reducer: reviewAndReleaseReducer,
	});
	useInjectSaga({ key: reviewAndReleaseKey, saga: reviewAndReleaseSaga });

	const history = useHistory();
	const { isShowFutureFirstPermissionEnabled } = useFeatureFlags();
	const sourceName = useContext(SourceContext);
	const dispatch = useDispatch();
	const { userHasPermissions } = useAbac();
	const { t: translate } = useTranslation();

	const translationsScope = translations.app.containers.Transactions;
	const orderStatus = useSelector(selectOrderStatus);
	const { tab: tabParam } = useParams<{ tab: string }>();
	const showReviewAndReleaseTab = userHasPermissions(
		Permission.CONTRACTSERVICE_REVIEWANDRELEASE_VIEW,
	);

	useEffect(() => {
		dispatch(
			globalActions.setPageTitle(
				translate(translations.app.components.AppLayout.contractManagement),
			),
		);

		dispatch(globalActions.getReviewAndReleaseOrderStatus());
		dispatch(globalActions.loadLocationsList());
		dispatch(globalActions.loadDestinationsList());
		dispatch(globalActions.loadGroupedLocationsList());
		dispatch(globalActions.clearEmployeesList({ source: sourceName }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sourceName]);

	const getTranslation = (key: string) => translate(translationsScope[key]);

	const handleTabChange = (tabKey: TransactionsTabsEnum) => {
		history.push(`${ROUTES.TRANSACTIONS}/${tabKey}`);

		switch (tabKey) {
			case TransactionsTabsEnum.offers:
				dispatch(offersActions.setCurrentView(OffersViews.Table));
				tabParam === tabKey && dispatch(offersActions.loadOffersList(true));
				break;

			case TransactionsTabsEnum.contracts:
				// Get grid updates only when the user is in the contracts tab
				dispatch(contractsActions.setCurrentView(ContractsViews.Table));
				tabParam === tabKey && dispatch(contractsActions.loadContractList());
				break;

			case TransactionsTabsEnum.preHedge:
				dispatch(preHedgeActions.setCurrentView(PreHedgeViews.table));
				tabParam === tabKey && dispatch(preHedgeActions.loadPreHedgeList(true));
				break;

			case TransactionsTabsEnum.reviewAndRelease:
				dispatch(
					reviewAndReleaseActions.setCurrentView(ReviewAndReleaseViews.table),
				);
				tabParam === tabKey &&
					dispatch(reviewAndReleaseActions.loadReviewAndReleaseList());
				break;
		}
	};

	const transactionsTabs: TransactionTab[] = [
		{
			name: TransactionsTabsEnum.contracts,
			content: <Contracts />,
		},
		{
			name: TransactionsTabsEnum.offers,
			content: <Offers />,
		},
	];

	if (showReviewAndReleaseTab) {
		const totalReadyTransactions = orderStatus?.totalReadyTransactions;

		transactionsTabs.push({
			name: TransactionsTabsEnum.reviewAndRelease,
			content: <ReviewAndRelease />,
			icon: totalReadyTransactions ? (
				<Tag className="total-readies">{`${totalReadyTransactions} ${translate(translations.app.containers.Transactions.readyText)}`}</Tag>
			) : null,
			className: classNames('review-and-release-tab', {
				rejected: orderStatus?.anyTransactionRejected,
			}),
		});
	}

	if (isShowFutureFirstPermissionEnabled) {
		transactionsTabs.push({
			name: TransactionsTabsEnum.preHedge,
			content: <PreHedge />,
			className: 'pre-hedge-tab',
		});
	}

	const contractsLabel = getTranslation('contractsTab');
	const offersLabel = getTranslation('offersTab');
	const pageTitle = getTranslation('pageTitle');
	const reviewAndReleaseLabel = getTranslation('reviewAndReleaseTab');
	const futuresFirst = getTranslation('futuresFirstTab');

	const tabsTranslations = {
		contracts: contractsLabel,
		offers: offersLabel,
		reviewAndRelease: reviewAndReleaseLabel,
		futuresFirst: futuresFirst,
	};

	return (
		<>
			<Helmet>
				<title>{pageTitle}</title>
				<meta name="description" content={pageTitle} />
			</Helmet>
			<TabsWrapper
				classname={'transactions__tabs'}
				tabs={transactionsTabs}
				translations={tabsTranslations}
				type="card"
				handleOnClick={handleTabChange}
			/>
		</>
	);
}
