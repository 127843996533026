import './style.scss';

import { Modal, Select } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { GenereicFormPlane } from 'app/components/GenereicFormPlane';
import { selectLocationPermissionSelection } from 'app/containers/Settings/selectors';
import { settingsActions } from 'app/containers/Settings/slice';
import {
	allEditNoQuantityLabels,
	allEditNoQuantityPermission,
	allEditQuantityLabels,
	allEditQuantityPermission,
	contractTypeList,
	functionsPermission,
	offerTypeList,
	roleLocationList,
	screenPermissionList,
} from 'app/containers/Settings/types';
import { translations } from 'locales/i18n';
import { Permission } from 'types/Authorization';
import { GenericOptionData } from 'types/GenericOption';
import { ModalSize } from 'types/ModalSize';
import { mapToLabelValue } from 'utils/helpers';
import { useEnabledRegions } from 'utils/hooks/useEnabledRegions';

import { useFieldFactory } from '../../../../definitions/fields';
import { UseFieldTranslations } from '../../../../definitions/fields/translations';
import {
	selectActiveEmployee,
	selectActiveEmployeeUserID,
	selectCurrentModal,
	selectEmployeeLimit,
	selectEmployeeLocations,
	selectEmployeePermissionsData,
	selectEmployeePermissionsLoading,
} from '../../selectors';
import { actions } from '../../slice';
import { View } from '../../types';
import { useRolesLocationsByEmployee } from '../../View/EditRoleAndPermission/hooks/useRolesLocationsByEmployee';

const ConfirmModalReason = {
	empty: 0,
	changeRegion: 1,
	closeModal: 2,
};

export const RolesLocations = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const globalTranslationDomain = translations.app.commons;
	const fieldsTranslations = UseFieldTranslations();
	const fieldList = useFieldFactory();

	const activeEmployee = useSelector(selectActiveEmployee);
	const activeEmployeeId = useSelector(selectActiveEmployeeUserID);
	const permissionsList = useSelector(selectEmployeePermissionsData);
	const permissionsLoading = useSelector(selectEmployeePermissionsLoading);
	const employeeList = useSelector(selectEmployeeLocations);
	const currentModal = useSelector(selectCurrentModal);
	const hasUpdatedLocationPermissions = useSelector(
		selectLocationPermissionSelection,
	);
	const employeeLimit = useSelector(selectEmployeeLimit);

	const locationsSchema = useRolesLocationsByEmployee();
	const regionsOptions = useEnabledRegions(
		mapToLabelValue,
	) as GenericOptionData[];

	const domain =
		translations.app.containers.Settings.sections.Employees.Views
			.RolesAndPermission;
	const domainLocation =
		translations.app.containers.Settings.sections.Employees.Modals.Location;

	const [userRoleSchema, setUserRoleSchema] = useState<any>(() => null);
	const [employeeName] = useState<string>(
		() => `${activeEmployee?.firstName} ${activeEmployee?.lastName}`,
	);
	const [selectedRegionValue, setSelectedRegionValue] = useState({
		current: '',
		upcomming: '',
	});
	const [showConfirmModal, setShowConfirmModal] = useState<{
		value: boolean;
		reason: number;
	}>({ value: false, reason: ConfirmModalReason.empty });

	useEffect(() => {
		if (!!permissionsList) {
			setUserRoleSchema([
				...contractTypeList.map((field) => setInitialValues(field)),
				...offerTypeList.map((field) => setInitialValues(field)),
				...screenPermissionList.map((field) => setInitialValues(field)),
				locationsSchema?.rolesComponent,
				{
					...fieldList.bushelsLimit,
					initialValue: employeeLimit,
				},
				setInitialValues(functionsPermission.CANCEL),
				setInitialValues(functionsPermission.DO_NOT_HEDGE),
				allEdit(
					functionsPermission.EDIT_NO_QUANTITY,
					allEditNoQuantityPermission,
				),
				allEdit(functionsPermission.EDIT_QUANITY, allEditQuantityPermission),
				setInitialValues(functionsPermission.RESEND_TO_ERP),
				setInitialValues(functionsPermission.SALES_TRADING),
				setInitialValues(functionsPermission.MOBILEAPP),
			]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [permissionsList, employeeList]);

	useEffect(() => {
		dispatch(actions.getEmployeeLimit({ email: activeEmployee?.email }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setInitialValues = (field) => {
		const foundPermission = permissionsList?.find(
			(permission) => permission.name === Permission[field],
		);

		return {
			...fieldList[field],
			initialValue: !!foundPermission,
			disabled: foundPermission?.fromRole,
		};
	};

	const allEdit = (fieldName, innerPermissions) => {
		const allPermissionsChecked = innerPermissions.every((innerPermission) =>
			permissionsList?.some(
				(permission) => permission.name === innerPermission,
			),
		);

		return {
			...fieldList[fieldName],
			initialValue: allPermissionsChecked,
			disabled: innerPermissions.some((innerPermission) =>
				permissionsList?.some(
					(permission) =>
						permission.name === innerPermission && permission.fromRole,
				),
			),
		};
	};

	const handleOnSubmit = (values) => {
		let checkedPermission: string[] = [];
		Object.entries(values).forEach(([key, value]) => {
			const foundPermission = permissionsList?.find(
				(permission) =>
					permission.name === Permission[key] && permission.fromRole,
			);

			if (typeof value === 'boolean' && value && !foundPermission) {
				if (key === functionsPermission.EDIT_NO_QUANTITY) {
					checkedPermission = [
						...checkedPermission,
						...allEditNoQuantityLabels,
					];
				} else if (key === functionsPermission.EDIT_QUANITY) {
					checkedPermission = [...checkedPermission, ...allEditQuantityLabels];
				} else {
					checkedPermission.push(key);
				}
			}
		});

		const claimGrantList = checkedPermission.map(
			(permission) => Permission[permission],
		);

		const dataTransferObject = {
			userId: activeEmployeeId,
			claimGrants: claimGrantList,
		};

		const locations = Object.values(values.rolesLocations);

		dispatch(
			actions.updateEmployeeLocations({
				data: {
					employeeId: activeEmployee?.id,
					locations,
				},
			}),
		);
		dispatch(
			actions.updateEmployeePermissionsLocations({
				data: dataTransferObject,
				successMessage: translate(domain.toastSuccessEditMessage, {
					name: employeeName,
				}),
			}),
		);

		dispatch(
			actions.updateEmployeeLimit({
				data: {
					userEmail: activeEmployee?.email,
					limit: values.bushelsLimit,
				},
			}),
		);

		setShowConfirmModal({ value: false, reason: ConfirmModalReason.empty });
		dispatch(settingsActions.changeLocationPermissionState(false));
	};

	const handleClose = () => {
		setSelectedRegionValue({ current: '', upcomming: '' });
		dispatch(settingsActions.changeLocationPermissionState(false));
		setShowConfirmModal({ value: false, reason: ConfirmModalReason.empty });
		dispatch(actions.setCurrentModal(null));
	};

	const requestHandleClose = () => {
		if (!hasUpdatedLocationPermissions) {
			handleClose();
			return;
		}
		setShowConfirmModal({ value: true, reason: ConfirmModalReason.closeModal });
	};

	const handleOnClose = () => {
		dispatch(actions.setCurrentView(View.Permissions));
	};

	const handleChangeRegions = (value?: string) => {
		setShowConfirmModal({ value: false, reason: ConfirmModalReason.empty });
		dispatch(settingsActions.changeLocationPermissionState(false));
		dispatch(
			actions.getEmployeeLocations({
				id: activeEmployee?.id ?? activeEmployeeId,
				regionId: value ? value : selectedRegionValue.upcomming,
			}),
		);
		setSelectedRegionValue((prev) => ({
			...prev,
			current: prev.upcomming,
			upcoming: null,
		}));
	};

	const requestHandleChangeRegions = (value: string) => {
		setSelectedRegionValue((prev) => ({ ...prev, upcomming: value }));
		if (!hasUpdatedLocationPermissions) {
			handleChangeRegions(value);
			return;
		}
		setShowConfirmModal({
			value: true,
			reason: ConfirmModalReason.changeRegion,
		});
	};

	const handleConfirmation = () => {
		if (showConfirmModal.reason === ConfirmModalReason.closeModal) {
			handleClose();
		}
		if (showConfirmModal.reason === ConfirmModalReason.changeRegion) {
			handleChangeRegions();
		}
	};

	const handleCancelConfirmation = () => {
		setShowConfirmModal({ value: false, reason: ConfirmModalReason.empty });
	};

	const translateList = (list) =>
		list.map((section: string | number) => ({
			label: fieldsTranslations.createEditRoleView.sections[section],
			value: section,
		}));

	const Headers = () => {
		return (
			<div className="flex">
				<b>{employeeName}</b>

				{regionsOptions?.length && (
					<div className="view-location-headers__wrapper">
						<strong className="view-location-headers__title">
							{translate(globalTranslationDomain.regions)}
						</strong>
						<Select
							value={selectedRegionValue.current || regionsOptions[0].value}
							defaultValue={regionsOptions[0].value}
							placeholder={translate(globalTranslationDomain.regionPlaceholder)}
							className="select"
							options={regionsOptions}
							onChange={requestHandleChangeRegions}
						/>
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			<Modal
				maskClosable={true}
				title={translate(domainLocation.viewLocation)}
				visible={showConfirmModal.value && hasUpdatedLocationPermissions}
				onOk={handleConfirmation}
				onCancel={handleCancelConfirmation}
			>
				{translate(domainLocation.confirmChangeText)}
			</Modal>

			<Modal
				maskClosable={true}
				className={classNames(
					'generic-modal-form',
					'view-location-modal-form',
					{
						'large-modal': ModalSize.large,
						scrollable: true,
					},
				)}
				title={translate(domainLocation.viewLocation)}
				visible={currentModal !== null}
				onCancel={requestHandleClose}
				cancelButtonProps={{ style: { display: 'none' } }}
				okButtonProps={{ style: { display: 'none' } }}
			>
				{userRoleSchema && (
					<>
						<GenereicFormPlane
							header={<Headers />}
							secondTabSectionsList={translateList(roleLocationList)}
							formDefinition={userRoleSchema}
							handleSubmit={handleOnSubmit}
							footerButtons={
								<FormFooterButtons
									isLoading={permissionsLoading}
									submitLabel={translate(domain.saveRoleAndPermission)}
									closeModal={handleOnClose}
								/>
							}
						/>
					</>
				)}
			</Modal>
		</>
	);
};
