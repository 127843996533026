/**
 *
 * SourceTable
 *
 */
import './style.scss';

import { Popover, Typography } from 'antd';
import classNames from 'classnames';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TotalAccQuantityIcon } from 'app/components/CustomIcon';
import { GenericTable } from 'app/components/GenericTable';
import { IsSellRoundPill } from 'app/components/IsSellRoundPill';
import { selectActiveOrderDetails } from 'app/containers/ReviewAndRelease/selectors';
import { OrderSource } from 'app/containers/ReviewAndRelease/types';
import { translations } from 'locales/i18n';
import { CONSTANTS } from 'utils/constants';
import {
	currencyFormat,
	customFormat,
	dateFormat,
	timeFormat,
} from 'utils/helpers';

import {
	getColumnValue,
	getEmployeeInitials,
	getFormattedCurrency,
} from '../utils/helpers';

const { Paragraph } = Typography;

export const SourceTable = memo(() => {
	const translationsScope =
		translations.app.containers.ReviewAndRelease.Details;

	const { t: translate } = useTranslation();

	const activeOrderDetails = useSelector(selectActiveOrderDetails);
	const activeOrderSource = activeOrderDetails.data?.source;
	const loading = activeOrderDetails.loading;
	const sourceOrders = activeOrderDetails.data?.list;

	const AccumulationTitle = () => {
		return (
			<>
				{translate(translationsScope.quantityAccumulation)}
				<Popover
					content={
						<div className="popover--content">
							{`${translate(translationsScope.totalAccumulation)}:`}
							{customFormat(
								activeOrderDetails.data?.totalAccumulationQuantity,
								true,
								CONSTANTS.FIXED_QUANTITY_DECIMALS,
							)}
						</div>
					}
					trigger="hover"
				>
					<TotalAccQuantityIcon />
				</Popover>
			</>
		);
	};

	const columns = [
		{
			title: translate(translationsScope.created),
			className: 'column-table',
			width: 115,
			render: (data) => (
				<GenericTable.Column className="order__column">
					<p className="text text--bold text--large">
						{dateFormat(data.creationDate)}
					</p>
					<p className="text text--label">{timeFormat(data.creationDate)}</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.accountNumber),
			width: 140,
			className: 'column-table',
			render: (data) => (
				<GenericTable.Column className="order__column">
					<div className="text flex-row">
						<div>
							<span className="text text--large">
								{data?.number ? data.number : ''}
							</span>
						</div>
						<div className="order__column__icon">
							<IsSellRoundPill isSell={data.isSell} />
						</div>
					</div>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.hrvystId),
			width: 120,
			className: 'column-table',
			render: (data) => (
				<GenericTable.Column className="order__column">
					<div className="text">
						<span className="text text--large text--bold">
							{getColumnValue(data?.internalNumber)}
						</span>
					</div>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.customer),
			className: 'column-table',
			width: 100,
			render: (data) => (
				<div className="wrapper-column">
					<div className="order__column">
						<p className="text text--centered text--large line-height">
							{data?.customer?.number}
						</p>
						<Paragraph
							className="text text--centered text--large line-height"
							title={data.customer?.firstName}
							ellipsis={{ rows: 1 }}
						>
							{data.customer?.firstName || data.customer?.lastName
								? `${data.customer?.firstName} ${data.customer?.lastName}`
								: translate(translationsScope.adjustmentCustomer)}
						</Paragraph>
					</div>
				</div>
			),
		},
		{
			title: translate(translationsScope.employee),
			className: 'column-table',
			width: 90,
			render: (data) => {
				const { employee } = data;
				const { initialFirstName, initialLastName } =
					getEmployeeInitials(employee);

				return (
					<GenericTable.Column className="order__column">
						<Popover
							content={
								<div className="popover--content">{`${employee?.firstName} ${employee?.lastName}`}</div>
							}
							trigger="hover"
						>
							<div className="round-pill">
								{initialFirstName}
								{initialLastName}
							</div>
						</Popover>
					</GenericTable.Column>
				);
			},
		},
		{
			title: translate(translationsScope.commodity),
			className: 'column-table',
			width: 100,
			render: (data) => (
				<GenericTable.Column className="order__column">
					<Paragraph
						className="text text--large ellipsis text--centered"
						title={data.commodityName}
						ellipsis={{ rows: 2 }}
					>
						{data.commodityName}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.crop),
			className: 'column-table',
			width: 80,
			render: (data) => (
				<GenericTable.Column className="order__column">
					<p className="text text--large text--centered">{data.cropYear}</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.futuresPrice),
			className: 'column-table',
			width: 100,
			render: (data) => (
				<GenericTable.Column className="order__column">
					<p className="text text--large text--centered">{data.futuresMonth}</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.destination),
			className: 'column-table',
			width: 100,
			render: (data) => (
				<GenericTable.Column className="order__column">
					<Paragraph
						className="text text--large ellipsis text--centered"
						title={data.destination}
						ellipsis={{ rows: 2 }}
					>
						{data.destination}
					</Paragraph>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.quantity),
			className: 'column-table',
			width: 100,
			render: (data) => (
				<GenericTable.Column className="order__column">
					<p className="text text--large text--centered">
						{customFormat(
							data.quantity,
							true,
							CONSTANTS.FIXED_QUANTITY_DECIMALS,
						)}
					</p>
				</GenericTable.Column>
			),
		},
		...(activeOrderSource === OrderSource.accumulation
			? [
					{
						title: AccumulationTitle,
						className: 'column-table',
						width: 110,
						render: (data) => (
							<GenericTable.Column className="order__column">
								<p className="text text--large text--centered">
									{customFormat(
										data.accumulationQuantity,
										true,
										CONSTANTS.FIXED_QUANTITY_DECIMALS,
									)}
								</p>
							</GenericTable.Column>
						),
					},
				]
			: []),
		{
			title: translate(translationsScope.futures),
			className: 'column-table',
			width: 80,
			render: (data) => (
				<GenericTable.Column className="order__column">
					<p className="text text--large text--centered">
						{getFormattedCurrency(data.futures)}
					</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.basis),
			className: 'column-table',
			width: 80,
			render: (data) => (
				<GenericTable.Column className="order__column">
					<p className="text text--large text--centered">
						{currencyFormat(
							data.netBasis,
							CONSTANTS.FIXED_DECIMALS,
							CONSTANTS.FIXED_DECIMALS,
						)}
					</p>
				</GenericTable.Column>
			),
		},
		{
			title: translate(translationsScope.cash),
			className: 'column-table',
			width: 100,
			render: (data) => (
				<GenericTable.Column className="order__column">
					<p className="text text--large text--centered">
						{getFormattedCurrency(data.price)}
					</p>
				</GenericTable.Column>
			),
		},
	];

	return (
		<>
			<div className="table-title">
				{activeOrderSource === OrderSource.offer
					? translate(translationsScope.sourceOfferTitle)
					: translate(translationsScope.sourceContractTitle)}
			</div>
			<GenericTable.Table<any>
				otherClassName="source-container"
				columns={columns}
				loading={loading}
				pagination={false}
				onRow={(record) => {
					return {
						className: classNames(
							`border-left--${record?.status?.trim().split(' ').join('-').toLowerCase()}`,
							{ offer: activeOrderSource === OrderSource.offer },
						),
					};
				}}
				data={sourceOrders}
			/>
		</>
	);
});
