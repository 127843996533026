import classNames from 'classnames';
import React, { memo } from 'react';

import { Input } from 'app/components/Input';
import { NumberType } from 'types/NumberType';
import {
	DIGITS_CHARS,
	DIGITS_KEYS,
	EVENT_KEY_CODES,
	PHONE_CHARS,
	PHONE_KEYS,
} from 'utils/constants';

import { GenericForm } from '../GenericForm';

interface Props {
	name: string;
	label?: string;
	placeholder?: string;
	numberType?: NumberType;
	arrowsHidden?: boolean;
	maxLength?: number;
}

export const NumberInput = memo((props: Props) => {
	const handlePressDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const keyCode = event.keyCode;
		const shiftKey = event.shiftKey;
		const ctrlKey = event.ctrlKey;
		const notNumber = isNaN(parseInt(event.key));
		const restrictedDigit = !DIGITS_KEYS.find((element) => element === keyCode);
		const restrictedPhone = !PHONE_KEYS.find((element) => element === keyCode);

		if (shiftKey && keyCode !== EVENT_KEY_CODES.TAB) {
			// Allow Shift + '=' (which results in '+')
			if (
				keyCode === EVENT_KEY_CODES.EQUAL_SIGN ||
				keyCode === EVENT_KEY_CODES.EQUAL_SIGN_FIREFOX
			) {
				return;
			}

			event.preventDefault();
			return;
		} else if (ctrlKey && keyCode === EVENT_KEY_CODES.V) {
			return;
		} else if (props.numberType === NumberType.pure) {
			if (notNumber && restrictedDigit) {
				event.preventDefault();
				return;
			}
		} else {
			if (notNumber && restrictedPhone) {
				event.preventDefault();
			}
		}
	};

	const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
		const pasteText = event.clipboardData.getData('Text').toUpperCase();

		if (!!pasteText.length) {
			if (props.numberType === NumberType.pure) {
				for (let i = 0; i < pasteText.length; i++) {
					if (!DIGITS_CHARS.find((element) => element === pasteText[i])) {
						event.preventDefault();
						break;
					}
				}
			} else if (props.numberType === NumberType.phone) {
				for (let i = 0; i < pasteText.length; i++) {
					if (!PHONE_CHARS.find((element) => element === pasteText[i])) {
						event.preventDefault();
						break;
					}
				}
			}
		}
	};

	return (
		<GenericForm.FormItem
			data-testid={`${props.name}-form-item`}
			label={props.label}
			name={props.name}
		>
			<Input
				className={classNames('number-input', {
					'arrows-hidden': props.arrowsHidden,
				})}
				name={props.name}
				type="string"
				placeholder={props.placeholder}
				onKeyDown={(e) => handlePressDown(e)}
				onPaste={(e) => handlePaste(e)}
				maxLength={props.maxLength}
				data-testid="form-number-input"
			/>
		</GenericForm.FormItem>
	);
});
