import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import { selectActiveOffer } from 'app/containers/Offers/selectors';
import { AssignedRegion } from 'app/containers/Transactions/components/AssignedRegion';
import { Basis } from 'app/containers/Transactions/components/Basis';
import { CashSettlement } from 'app/containers/Transactions/components/CashSettlement';
import { Comments } from 'app/containers/Transactions/components/Comments';
import { Commodity } from 'app/containers/Transactions/components/Commodity';
import { ContractType } from 'app/containers/Transactions/components/ContractType';
import { CropYears } from 'app/containers/Transactions/components/CropYears';
import { Customer } from 'app/containers/Transactions/components/Customer';
import { DeliveryDates } from 'app/containers/Transactions/components/DeliveryDates';
import { DeliveryLocation } from 'app/containers/Transactions/components/DeliveryLocation';
import { Employee } from 'app/containers/Transactions/components/Employee';
import { ExpirationDateGTC } from 'app/containers/Transactions/components/ExpirationDateGTC';
import { Fees } from 'app/containers/Transactions/components/Fees';
import { FlatPrice } from 'app/containers/Transactions/components/FlatPrice';
import { Freight } from 'app/containers/Transactions/components/Freight';
import { Futures } from 'app/containers/Transactions/components/Futures';
import { Location } from 'app/containers/Transactions/components/Location';
import { Quantity } from 'app/containers/Transactions/components/Quantity';
import { TheirContract } from 'app/containers/Transactions/components/TheirContract';
import { Transaction } from 'app/containers/Transactions/components/Transaction';
import { UnfilledQuantity } from 'app/containers/Transactions/components/UnfilledQuantity';
import { useOrderEntriesFormSchema } from 'app/containers/Transactions/hooks/useOrderEntriesFormSchema';
import { ContractTypeSource } from 'types/ContractTypeSource';
import { FeatureFlag } from 'types/FeatureFlags';
import { CONSTANTS as GLOBAL_CONSTANTS } from 'utils/constants';
import {
	createRangeCalendarOption,
	customFormat,
	getActionType,
	validatePriceFormat,
	validateQuarterCents,
} from 'utils/helpers';
import Yup from 'yupCustom';

import { getDeliveryDatesMode, getExpiration } from '../../../shared/helpers';
import { useTranslations } from '../../../shared/useTranslations';

export const useFlatPriceSchema = (
	translations: ReturnType<typeof useTranslations>,
) => {
	const offerData = useSelector(selectActiveOffer);
	const featureFlags = useSelector(selectFeatureFlags);

	const { elements, initialValues, validationSchema } =
		useOrderEntriesFormSchema(offerData);

	const requiredMsg = translations.validations.required;
	const maxLength10Msg = translations.validations.maxLength10;
	const numberMsg = translations.validations.number;
	const futuresPriceQuarterMsg =
		translations.validations.futuresPriceQuarterMsg;

	const validation = useMemo(
		() =>
			Yup.object().shape({
				theirContract: Yup.string().nullable().max(10, maxLength10Msg),
				contract: Yup.object().requiredOption(requiredMsg),
				action: Yup.string().required(requiredMsg),
				flatPrice: Yup.number().typeError(numberMsg).required(requiredMsg),
				employee: Yup.object().requiredOption(requiredMsg),
				futuresPrice: Yup.number()
					.typeError(requiredMsg)
					.required(requiredMsg)
					.test('quarterCentsValidation', futuresPriceQuarterMsg, (val) =>
						validateQuarterCents(val),
					),
				quantity: Yup.string().typeError(numberMsg).required(requiredMsg),
				...validationSchema,
			}),
		[
			validationSchema,
			requiredMsg,
			maxLength10Msg,
			numberMsg,
			futuresPriceQuarterMsg,
		],
	);

	const selectedWindow = useMemo(() => {
		if (!offerData?.isDeliveryDatesCustom) {
			const { value } = createRangeCalendarOption(
				offerData?.deliveryStartDate,
				offerData?.deliveryEndDate,
			);
			return {
				value,
			};
		} else {
			return undefined;
		}
	}, [
		offerData?.isDeliveryDatesCustom,
		offerData?.deliveryStartDate,
		offerData?.deliveryEndDate,
	]);

	return useMemo(
		() => ({
			initialValues: {
				theirContract: offerData?.theirContract,
				transaction: {
					label: offerData?.transactionTypeName,
					value: offerData?.transactionTypeId,
				},
				contract: {
					label: offerData?.contractTypeName,
					value: offerData?.contractTypeId,
				},
				action: getActionType(offerData?.isSell),
				commodity: {
					label: offerData?.commodityName,
					value: offerData?.commodityId,
				},
				location: {
					label: offerData?.locationName,
					value: offerData?.locationId,
				},
				deliveryLocation: {
					label: offerData?.deliveryLocationName,
					value: offerData?.deliveryLocationId,
				},
				assignedRegion: {
					label: offerData?.regionName,
					value: offerData?.regionId,
				},
				cropYear: offerData?.cropYear,
				deliveryDate: [
					moment(offerData?.deliveryStartDate),
					moment(offerData?.deliveryEndDate),
				],
				deliveryDatesMode: getDeliveryDatesMode(offerData),
				deliveryDateWindow: selectedWindow || '',
				futuresMonth: {
					label: offerData?.futuresMonth,
					value: offerData?.futuresMonth,
				},
				futuresPrice: validatePriceFormat(offerData?.futuresPrice),
				postedBasis: validatePriceFormat(offerData?.postedBasis),
				pushBasis: validatePriceFormat(offerData?.pushBasis),
				netBasis: validatePriceFormat(offerData?.netBasis),
				freight: validatePriceFormat(offerData?.freightPrice),
				fees1: validatePriceFormat(offerData?.fees1),
				fees2: validatePriceFormat(offerData?.fees2),
				flatPrice: validatePriceFormat(offerData?.price),
				netFutures: validatePriceFormat(offerData?.price),
				netBasisPrice: validatePriceFormat(offerData?.price),
				quantity: customFormat(
					offerData?.quantity,
					true,
					GLOBAL_CONSTANTS.FIXED_QUANTITY_DECIMALS,
				),
				remainingBalance: featureFlags[FeatureFlag.enableOfferQuantityEdit]
					? customFormat(
							offerData?.remainingBalance,
							true,
							GLOBAL_CONSTANTS.FIXED_QUANTITY_DECIMALS,
						)
					: 0,
				gtcMode: offerData?.gtc,
				expirationDateGTC: getExpiration(offerData),
				customer: {
					label: offerData?.customerName,
					value: offerData?.customerId,
				},
				employee: {
					label: offerData?.employeeName,
					value: offerData?.employeeId,
				},
				comments: offerData?.comments,
				cashSettlement: offerData?.cashSettlement,
				...initialValues,
			},
			validationSchema: validation,
			elements: (
				<>
					<TheirContract />
					<Transaction disabled />
					<ContractType disabled />
					<Commodity reloadFuturesMonth disabled />
					<Location />
					<DeliveryLocation disabled />
					<AssignedRegion disabled />
					<CropYears disabled />
					<DeliveryDates
						resetDependencies={false}
						modeSelectorDisabled
						getFromBidsheet
						disabled
						rangePickerDisabled={false}
					/>
					<Futures
						futuresMonth={{ disabled: true }}
						futuresPrice={{ disabled: true }}
					/>
					<Basis
						calcFuturesOnPushBasisChange
						contractTypeSource={ContractTypeSource.OFFER}
						disabled={{
							postedBasis: true,
							netBasis: true,
							pushBasis: false,
						}}
					/>
					<Freight />
					<Fees disabled={{ fee1: false, fee2: false }} />
					<FlatPrice />
					<Quantity disabled={true} />
					<UnfilledQuantity />
					<ExpirationDateGTC disabled disableWeekends />
					<Customer disabled />
					<Employee />
					{elements}
					<Comments />
					<CashSettlement />
				</>
			),
		}),
		[
			offerData,
			featureFlags,
			elements,
			initialValues,
			validation,
			selectedWindow,
		],
	);
};
