import { Spin } from 'antd';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GenericTable } from 'app/components/GenericTable';
import { IsSellRoundPill } from 'app/components/IsSellRoundPill';
import { TabsWrapper } from 'app/components/TabsWrapper';
import { translations } from 'locales/i18n';

import {
	selectHedgeMappingList,
	selectHedgeMappingListError,
	selectHedgeMappingListLoading,
} from '../../../selectors';

export const Table = memo(() => {
	const { t: translate } = useTranslation();
	const translationsRoot =
		translations.app.containers.Settings.sections.HedgeMapping.Table;
	const hedgeMapping = useSelector(selectHedgeMappingList);
	const tableLoading = useSelector(selectHedgeMappingListLoading);
	const hedgeMappingError = useSelector(selectHedgeMappingListError);

	const columns = [
		{
			title: translate(translationsRoot.deliveryStartDate),
			dataIndex: 'label',
			key: 'label',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large">{data}</p>
				</GenericTable.Column>
			),
		},
		{
			title: (
				<>
					{translate(translationsRoot.flatPrice)}{' '}
					<IsSellRoundPill isSell={false} />
				</>
			),
			dataIndex: 'flatPriceP',
			key: 'flatPriceP',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large">{data}</p>
				</GenericTable.Column>
			),
		},
		{
			title: (
				<>
					{translate(translationsRoot.hta)} <IsSellRoundPill isSell={false} />
				</>
			),
			dataIndex: 'htaP',
			key: 'htaP',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large">{data}</p>
				</GenericTable.Column>
			),
		},
		{
			title: (
				<>
					{translate(translationsRoot.basis)} <IsSellRoundPill isSell={false} />
				</>
			),
			dataIndex: 'basisP',
			key: 'basisP',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large">{data}</p>
				</GenericTable.Column>
			),
		},
		{
			title: (
				<>
					{translate(translationsRoot.flatPrice)}{' '}
					<IsSellRoundPill isSell={true} />
				</>
			),
			dataIndex: 'flatPriceS',
			key: 'flatPriceS',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large">{data}</p>
				</GenericTable.Column>
			),
		},
		{
			title: (
				<>
					{translate(translationsRoot.hta)} <IsSellRoundPill isSell={true} />
				</>
			),
			dataIndex: 'htaS',
			key: 'htaS',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large">{data}</p>
				</GenericTable.Column>
			),
		},
		{
			title: (
				<>
					{translate(translationsRoot.basis)} <IsSellRoundPill isSell={true} />
				</>
			),
			dataIndex: 'basisS',
			key: 'basisS',
			render: (data) => (
				<GenericTable.Column>
					<p className="text text--large">{data}</p>
				</GenericTable.Column>
			),
		},
	];

	const getTable = (data) => {
		return (
			<GenericTable.Table
				otherClassName="table-container"
				columns={columns}
				data={data}
				loading={false}
				pagination={false}
			/>
		);
	};

	const tabLabels = hedgeMapping
		.map((element) => Object.values(element)[0])
		.reduce<Record<string, string>>((result: any, item: any) => {
			result[item] = item;
			return result;
		}, {});

	const cropYearTabs = !!hedgeMappingError
		? []
		: hedgeMapping.map((element) => {
				const cropYear: any = Object.values(element)[0];
				const mapping: any = Object.values(element)[1];
				return { name: cropYear, content: getTable(mapping) };
			});

	return !tableLoading ? (
		<TabsWrapper
			classname="transactions__tabs"
			type="card"
			tabs={cropYearTabs}
			translations={tabLabels}
		/>
	) : (
		<Spin data-testid="hedgemap-spin" size="large" />
	);
});
