import {
	AppstoreOutlined,
	ArrowsAltOutlined,
	BranchesOutlined,
	DollarOutlined,
	FilterOutlined,
	FormOutlined,
	NodeIndexOutlined,
	PartitionOutlined,
	ProfileOutlined,
	ShopFilled,
	UserOutlined,
	VerticalAlignMiddleOutlined,
} from '@ant-design/icons';
import React from 'react';

import {
	BellIcon,
	CommodityIcon,
	EmployeeIcon,
	RegionsIcon,
	UserRolIcon,
} from 'app/components/CustomIcon';
import { MenuItem, Sections } from 'app/containers/Settings/types';
import { Permission } from 'types/Authorization';
import { FeatureFlag } from 'types/FeatureFlags';

import { Bidsheet } from '../sections/Bidsheet';
import { BrokerMapping } from '../sections/BrokerMapping';
import { BypassReview } from '../sections/BypassReview';
import { Commodities } from '../sections/Commodities';
import { Customers } from '../sections/Customers';
import { DefaultPage } from '../sections/DefaultPage';
import { Employees } from '../sections/Employees';
import { ERPIntegration } from '../sections/ERPIntegration';
import { HedgeMapping } from '../sections/HedgeMapping';
import { Locations } from '../sections/Locations';
import { NotificationSettings } from '../sections/NotificationSettings';
import { NtcAdmin } from '../sections/NtcAdmin';
import { OrderEntry } from '../sections/OrderEntry';
import { Regions } from '../sections/Regions';
import { RoundingRules } from '../sections/RoundingRules';
import { ServiceFees } from '../sections/ServiceFees';
import { UserRole } from '../sections/UserRoles';

export const menuItems: MenuItem[] = [
	{
		name: Sections.Customers,
		icon: <UserOutlined />,
		component: <Customers />,
	},
	{
		name: Sections.Employees,
		icon: <EmployeeIcon />,
		component: <Employees />,
	},
	{
		name: Sections.Commodities,
		icon: <CommodityIcon />,
		component: <Commodities />,
	},
	{
		name: Sections.Regions,
		icon: <RegionsIcon />,
		component: <Regions />,
	},
	{
		name: Sections.Locations,
		icon: <ShopFilled />,
		component: <Locations />,
	},
	{
		name: Sections.OrderEntry,
		icon: <ProfileOutlined />,
		component: <OrderEntry />,
	},
	{
		name: Sections.NtcAdmin,
		icon: <BranchesOutlined />,
		component: <NtcAdmin />,
		permission: Permission.CONTRACTSERVICE_ALLOW_CONTRACTNTC,
	},
	{
		name: Sections.Users,
		icon: <UserRolIcon />,
		component: <UserRole />,
	},
	{
		name: Sections.BrokerMapping,
		icon: <NodeIndexOutlined />,
		component: <BrokerMapping />,
	},
	{
		name: Sections.HedgeMapping,
		icon: <VerticalAlignMiddleOutlined />,
		component: <HedgeMapping />,
	},
	{
		name: Sections.RoundingRules,
		icon: <ArrowsAltOutlined />,
		component: <RoundingRules />,
	},
	{
		name: Sections.Bidsheet,
		icon: <AppstoreOutlined />,
		component: <Bidsheet />,
	},
	{
		name: Sections.ServiceFees,
		icon: <DollarOutlined />,
		component: <ServiceFees />,
	},
	{
		name: Sections.BypassReview,
		icon: <FilterOutlined />,
		component: <BypassReview />,
	},
	{
		name: Sections.ERPIntegration,
		icon: <PartitionOutlined />,
		component: <ERPIntegration />,
	},
	{
		name: Sections.DefaultPage,
		icon: <FormOutlined />,
		component: <DefaultPage />,
	},
	{
		name: Sections.NotificationSettings,
		icon: <BellIcon />,
		component: <NotificationSettings />,
		featureFlag: FeatureFlag.enableNotifications,
	},
];

export const sectionsPermissions = {
	[Sections.DefaultPage]: [],
	[Sections.NotificationSettings]: [],
};
