import { takeEvery, takeLatest } from 'typed-redux-saga';

import { actions } from '../GlobalSaga/slice';
import {
	getDeliveryDates,
	getFuturesMonth,
	getPostedBasisPrice,
} from './sagas/bidsheets-saga';
import { getCatalogFuturesMonthsList } from './sagas/catalogFuturesMonth-saga';
import {
	getCommoditiesCropYearsExpired,
	getCommoditiesList,
} from './sagas/commodities-saga';
import { getContractTypeCatalog } from './sagas/contract-type-catalog-saga';
import { getContractTypeList } from './sagas/contract-type-saga';
import { getCropsList } from './sagas/crops-saga';
import { getCustomersList } from './sagas/customers-saga';
import { getDefaultPageData } from './sagas/default-page-saga';
import { getDestinationsList } from './sagas/destinations-saga';
import { getEmployeesList } from './sagas/employees-saga';
import { getEventList } from './sagas/event-saga';
import { getFuturesMonthOptions } from './sagas/futures-month-saga';
import { getFuturePriceFromMarket } from './sagas/futures-saga';
import { getHedgeAccountList } from './sagas/hedge-account-saga';
import { loadInitData } from './sagas/init-saga';
import {
	getGroupedLocationsList,
	getLocationsList,
} from './sagas/locations-saga';
import {
	loadCustomerNotificationGroupList,
	loadEmployeeNotificationGroupList,
	sendCustomNotification,
} from './sagas/notifications-saga';
import { getNtcAdminSaga } from './sagas/ntc-admin-saga';
import { getOrderEntrySaga } from './sagas/order-entry-saga';
import { getPreHedgeStates } from './sagas/prehedge-status-saga';
import { getEnabledRegionsSaga } from './sagas/regions-saga';
import { getReviewAndReleaseStates } from './sagas/review-and-release-status-saga';
import { getRoundingRulesList } from './sagas/rounding-rule-saga';
import {
	getServiceFeesList,
	getServiceFeeTypes,
} from './sagas/service-fee-saga';
import { manageSession } from './sagas/session-saga';
import { getReviewAndReleaseOrderStatus } from './sagas/transactions-rejected-saga';
import { getTransactionsList } from './sagas/transactions-saga';

export function* globalSaga() {
	// Eachone of this yields watchs/waits for the dispatch of an action and then calls a saga.
	yield* takeLatest(
		actions.getCommoditiesCropYearsExpired.type,
		getCommoditiesCropYearsExpired,
	);

	yield* takeLatest(
		actions.getReviewAndReleaseOrderStatus.type,
		getReviewAndReleaseOrderStatus,
	);

	yield* takeLatest(actions.loadInitData.type, loadInitData);

	yield* takeLatest(actions.loadCommoditiesList.type, getCommoditiesList);

	yield* takeLatest(actions.loadCropsList.type, getCropsList);

	yield* takeLatest(actions.loadTransactionsList.type, getTransactionsList);

	yield* takeEvery(actions.loadContractTypeList.type, getContractTypeList);

	yield* takeLatest(
		actions.loadContractTypeCatalog.type,
		getContractTypeCatalog,
	);

	yield* takeLatest(actions.loadEventList.type, getEventList);

	yield* takeLatest(actions.loadCustomersList.type, getCustomersList);

	yield* takeLatest(actions.loadLocationsList.type, getLocationsList);

	yield* takeLatest(
		actions.loadGroupedLocationsList.type,
		getGroupedLocationsList,
	);

	yield* takeLatest(actions.loadRoundingRulesList.type, getRoundingRulesList);

	yield* takeLatest(actions.loadServiceFeeList.type, getServiceFeesList);

	yield* takeLatest(actions.loadServiceFeeTypes.type, getServiceFeeTypes);

	yield* takeLatest(actions.loadDestinationsList.type, getDestinationsList);

	yield* takeLatest(actions.loadEmployeesList.type, getEmployeesList);

	yield* takeLatest(actions.loadFuturesPrice.type, getFuturePriceFromMarket);

	yield* takeLatest(
		actions.loadFuturesMonthOptions.type,
		getFuturesMonthOptions,
	);

	// Bidsheet
	yield* takeLatest(actions.loadDeliveryDates.type, getDeliveryDates);
	yield* takeLatest(actions.loadFuturesMonth.type, getFuturesMonth);
	yield* takeLatest(actions.loadPostedBasisPrice.type, getPostedBasisPrice);

	//Catalog
	yield* takeLatest(
		actions.loadCatalogFuturesMonthsList.type,
		getCatalogFuturesMonthsList,
	);

	//Catalog
	yield* takeLatest(
		actions.loadCatalogFuturesMonthsList.type,
		getCatalogFuturesMonthsList,
	);

	yield* takeLatest(
		actions.loadReviewAndReleaseStates.type,
		getReviewAndReleaseStates,
	);

	yield* takeLatest(actions.loadPreHedgeStates.type, getPreHedgeStates);

	//LocalStorage
	yield* takeLatest(actions.startManageSession.type, manageSession);

	yield* takeLatest(actions.loadHedgeAccounts.type, getHedgeAccountList);

	yield* takeLatest(actions.loadDefaultPage.type, getDefaultPageData);

	yield* takeLatest(actions.getOrderEntryRequest.type, getOrderEntrySaga);

	yield* takeLatest(actions.getNtcAdminRequest.type, getNtcAdminSaga);

	yield* takeLatest(
		actions.getEnabledRegionsRequest.type,
		getEnabledRegionsSaga,
	);

	// Notifications
	yield* takeLatest(
		actions.loadEmployeeNotificationGroupList.type,
		loadEmployeeNotificationGroupList,
	);
	yield* takeLatest(
		actions.loadCustomerNotificationGroupList.type,
		loadCustomerNotificationGroupList,
	);
	yield* takeLatest(
		actions.sendCustomNotification.type,
		sendCustomNotification,
	);
}
