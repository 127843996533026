import { call, put } from 'typed-redux-saga';
import { z } from 'zod';

import { locationSchema } from 'types/Location';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from '../slice';

const { locationsRoot } = apiEndpoints;

const locationsResponseSchema = z.object({
	total: z.number().int(),
	locations: z.array(locationSchema),
});

export function* getDestinationsList() {
	let requestURL = new URL(
		`${apiRoutes.base}/${locationsRoot}?IsDestination=true`,
	);

	const request = requestWithSchema<z.infer<typeof locationsResponseSchema>>;
	const { data, error } = yield* call(request, requestURL, httpMethod.Get, {
		schema: locationsResponseSchema,
	});

	if (!!data) {
		yield* put(actions.destinationsListLoaded(data.locations));
	} else if (!!error?.detail) {
		yield* put(actions.destinationsListError(error));
	}
}
