import { z } from 'zod';

// the id is not in the API response; that's copied from name in the data loading sagas
// the API response also has year (number) and month (number)
export interface FutureMonth {
	id: string;
	name: string;
	isExpired: boolean;
}

const _futureMonthDTOSchema = z.object({
	name: z.string(),
	month: z.number().int(),
	year: z.number().int(),
	isExpired: z.boolean(),
});
export interface FutureMonthDTO extends z.infer<typeof _futureMonthDTOSchema> {}
export const futureMonthDTOSchema: z.Schema<FutureMonthDTO> =
	_futureMonthDTOSchema;
