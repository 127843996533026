import React, { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Dropdown } from 'app/components/Dropdown';
import { GenericForm } from 'app/components/GenericForm';
import { selectFeatureFlags } from 'app/containers/GlobalSaga/selectors';
import { translations } from 'locales/i18n';
import { mapToLabelValue } from 'utils/helpers';
import { useEnabledRegions } from 'utils/hooks/useEnabledRegions';

interface Props {
	onChange?: () => {};
	disabled?: boolean;
	hidden?: boolean;
}

interface RegionOptions {
	value: string;
	label: string;
}

export const AssignedRegion = memo((props: Props) => {
	const { disabled = false, hidden = false } = props;
	const { t: translate } = useTranslation();
	const msg =
		translations.app.containers.Transactions.components.AssignedRegion;
	const featureFlags = useSelector(selectFeatureFlags);
	const regionsOptions: RegionOptions[] =
		useEnabledRegions(mapToLabelValue) ?? [];
	const [avalableRegions, setAailableRegions] = useState(regionsOptions);

	const { watch, setValue } = useFormContext();

	const areArraysIdentical = (arrayOne, arrayTwo) => {
		if (arrayOne.length !== arrayTwo.length) {
			return false;
		}

		for (let i = 0; i < arrayOne.length; i++) {
			if (!isObjectEqual(arrayOne[i], arrayTwo[i])) {
				return false;
			}
		}

		return true;
	};

	function isObjectEqual(obj1, obj2) {
		const keys1 = Object.keys(obj1);
		const keys2 = Object.keys(obj2);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (let key of keys1) {
			if (obj1[key] !== obj2[key]) {
				return false;
			}
		}

		return true;
	}

	useEffect(() => {
		const regionIds = watch('deliveryLocation')?.regionIds;
		const currentRegionValue = watch('assignedRegion')?.value;
		const assignedRegions = regionsOptions?.filter((region) =>
			regionIds?.includes(region?.value),
		);
		if (currentRegionValue !== assignedRegions[0]?.value) {
			if (assignedRegions.length === 1) {
				setValue('assignedRegion', {
					value: assignedRegions[0]?.value || null,
					label: assignedRegions[0]?.label,
				});
			}
		}
		if (!areArraysIdentical(avalableRegions, assignedRegions)) {
			setAailableRegions(assignedRegions);
		}
	}, [watch('action'), watch('deliveryLocation'), regionsOptions]);

	return (
		<GenericForm.FormItem
			label={translate(msg.label)}
			name="assignedRegion"
			data-testid="assignedRegion-form-item"
			customClassName={hidden ? ['hidden'] : []}
		>
			<Dropdown
				disabled={disabled}
				key="assignedRegion"
				placeholder={translate(msg.placeholder)}
				options={avalableRegions}
			/>
		</GenericForm.FormItem>
	);
});
