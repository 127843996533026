import { call, put } from 'typed-redux-saga';
import { z } from 'zod';

import { translations } from 'locales/i18n';
import { commoditySchema } from 'types/Commodity';
import { CropYearExpiration } from 'types/CropYearExpiration';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from '../slice';
import { CustomNotificationTypes } from '../types';

const { commoditiesRoot } = apiEndpoints;

const commoditiesResponseSchema = z.object({
	total: z.number(),
	commodities: z.array(commoditySchema).nullable(),
});
interface CommoditiesResponse
	extends z.infer<typeof commoditiesResponseSchema> {}

export function* getCommoditiesList() {
	const requestURL = new URL(`${apiRoutes.base}/${commoditiesRoot}`);

	const request = requestWithSchema<CommoditiesResponse>;
	const { data, error } = yield* call(request, requestURL, httpMethod.Get, {
		schema: commoditiesResponseSchema,
	});

	if (!!data) {
		yield* put(actions.commoditiesLoaded(data.commodities ?? []));
	} else if (!!error?.detail) {
		yield* put(actions.commoditiesError(error));
	}
}

export function* getCommoditiesCropYearsExpired() {
	const domain = translations.app.commons;
	const requestURL = new URL(`${apiRoutes.base}/${commoditiesRoot}`);

	const request = requestWithSchema<CommoditiesResponse>;
	const { data, error } = yield* call(request, requestURL, httpMethod.Get, {
		schema: commoditiesResponseSchema,
	});

	if (!!data) {
		const commodities = data.commodities ?? [];
		const aboutToExpireCommodities = commodities.filter(({ cropYears }) =>
			cropYears?.some(
				({ expiration }) => expiration === CropYearExpiration.ABOUT_TO_EXPIRE,
			),
		);
		const expiredCommodities = commodities.filter(({ cropYears }) =>
			cropYears?.some(
				({ expiration }) => expiration === CropYearExpiration.EXPIRED,
			),
		);

		for (let commodity of aboutToExpireCommodities) {
			const name = commodity.name;

			yield* put(
				actions.addNotification({
					confirmText: { key: domain.confirmButton },
					duration: 0,
					message: { key: domain.aboutToExpireCropYear, data: { name } },
					showConfirmButton: true,
					title: { key: domain.attention.title },
					type: CustomNotificationTypes.WARNING,
				}),
			);
		}

		for (let commodity of expiredCommodities) {
			const name = commodity.name;

			yield* put(
				actions.addNotification({
					confirmText: { key: domain.confirmButton },
					duration: 0,
					message: { key: domain.expiredCropYear, data: { name } },
					showConfirmButton: true,
					title: { key: domain.attention.title },
					type: CustomNotificationTypes.WARNING,
				}),
			);
		}
	} else if (!!error?.detail) {
		yield* put(actions.commoditiesError(error));
	}
}
