/**
 *
 * ConfirmDialog
 *
 */
import './style.scss';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Divider, Popconfirm } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import React, { useState } from 'react';

interface Props {
	placement?: TooltipPlacement;
	icon?: JSX.Element;
	message: string;
	handleConfirm: () => void;
	confirmText?: string;
	cancelText?: string;
	trigger: JSX.Element;
	withOverlay?: boolean;
	stateControlled?: boolean;
	disabled?: boolean;
	okButtonProps?: { htmlType: 'submit' | 'button' };
}

export const ConfirmDialog = (props: Props) => {
	const {
		placement,
		icon,
		message,
		handleConfirm,
		confirmText,
		cancelText,
		trigger,
		withOverlay = true,
		stateControlled = true,
		disabled = false,
		okButtonProps,
	} = props;

	const [isVisible, setIsVisible] = useState(() => false);

	const changeVisibility = () => {
		setIsVisible(!isVisible);
	};

	const hidePopConfirm = () => {
		setIsVisible(false);
	};

	let controlledVisible = {};
	if (stateControlled) {
		controlledVisible = {
			visible: isVisible,
		};
	}

	return (
		<>
			{isVisible && withOverlay && (
				<div
					className="popconfirm-overlay"
					data-testid="popconfirm-overlay"
				></div>
			)}
			{!disabled ? (
				<Popconfirm
					className="popconfirm"
					overlayClassName="popconfirm-modal"
					placement={placement}
					{...controlledVisible}
					icon={
						icon ? (
							icon
						) : (
							<ExclamationCircleOutlined data-testid="default-icon" />
						)
					}
					title={
						<div className="popconfirm-message">
							<label>{message}</label>
							<Divider />
						</div>
					}
					onConfirm={handleConfirm}
					okText={
						<label
							data-testid="popconfirm-apply"
							className="popconfirm-apply"
							style={{ cursor: 'pointer' }}
							onClick={hidePopConfirm}
						>
							{confirmText}
						</label>
					}
					cancelText={
						<label
							data-testid="popconfirm-cancel"
							className="popconfirm-cancel"
							style={{ cursor: 'pointer' }}
							onClick={hidePopConfirm}
						>
							{cancelText}
						</label>
					}
					disabled={disabled}
					okButtonProps={okButtonProps}
				>
					<label
						onClick={changeVisibility}
						data-testid="popconfirm-trigger"
						className="popconfirm-dialog trigger"
					>
						{trigger}
					</label>
				</Popconfirm>
			) : (
				<span>{trigger}</span>
			)}
		</>
	);
};
