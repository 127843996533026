import { Switch } from 'antd';
import React, { memo } from 'react';

interface Props {
	checked?: boolean;
	checkedElement?: React.ReactNode;
	uncheckedElement?: React.ReactNode;
	disabled?: boolean;
	handleChange: (checked: boolean) => void;
}

export const FilterSwitch = memo((props: Props) => {
	const { checked, checkedElement, uncheckedElement, disabled } = props;

	const handleChange = (checked: boolean) => {
		props.handleChange(checked);
	};
	return (
		<Switch
			checked={checked}
			checkedChildren={checkedElement}
			unCheckedChildren={uncheckedElement}
			disabled={disabled}
			onChange={handleChange}
		/>
	);
});
