import React, { memo } from 'react';

import { Calendar } from 'app/components/Calendar';
import { GenericForm } from 'app/components/GenericForm';
import { OrderEntry } from 'types/OrderEntry';
import { DATE_FORMAT } from 'utils/constants';

import { getOrderEntryId } from '../utils/helpers';

interface Props {
	orderEntry: OrderEntry;
}

const customDisabledDate = () => false;

export const Dat = memo(({ orderEntry }: Props) => (
	<GenericForm.FormItem
		data-testid={`${getOrderEntryId(orderEntry)}-form-item`}
		name={getOrderEntryId(orderEntry)}
		label={orderEntry?.label}
	>
		<Calendar customDisabledDate={customDisabledDate} format={DATE_FORMAT} />
	</GenericForm.FormItem>
));
