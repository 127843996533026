import './style.scss';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Input as InputAntD } from 'antd';
import { InputProps } from 'antd/lib/input';
import React, { memo } from 'react';

interface Props extends InputProps {
	name: string;
}

export const InputPassword = memo((props: Props) => {
	const { name } = props;

	return (
		<InputAntD.Password
			{...props}
			className="basic-input-form__input"
			data-testid="form-input-test"
			iconRender={(visible: boolean) =>
				visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
			}
			name={name}
			type="text"
		/>
	);
});
