import { call, put } from 'typed-redux-saga';

import { actions } from 'app/containers/GlobalSaga/slice';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { UserDefaultValues, userDefaultValuesSchema } from '../types';

const { defaultPageSettings } = apiEndpoints;

export function* getDefaultPageData() {
	const requestURL = new URL(`${apiRoutes.base}/${defaultPageSettings}`);

	const request = requestWithSchema<UserDefaultValues>;
	const { data, error } = yield* call(request, requestURL, httpMethod.Get, {
		schema: userDefaultValuesSchema,
	});

	// Success actions
	if (!!data) {
		yield* put(actions.defaultPageLoaded(data));
	} else if (!!error?.detail) {
		yield* put(actions.defaultPageError(error));
	}
}
