/**
 *
 * OrdersCancel or UndoModal
 *
 */
import './style.scss';

import { Button, Col, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { InputNumber } from 'app/components/InputNumber';
import { actions } from 'app/containers/Contracts/slice';
import { translations } from 'locales/i18n';
import { CONSTANTS } from 'utils/constants';
import { addComma, customFormat, removeComma } from 'utils/helpers';

import { selectActiveContract } from '../../../../../selectors';

const GUTTER_SPACE = 0;
const SPAN_COLUMN = {
	TEXT: 4,
	INPUT: 8,
};

const errorKeys = {
	default: false,
	warningMessage: false,
	correctValue: false,
	numberMessage: false,
};

interface Props {
	defaultMessageWithNumber?: string;
	defaultMessage?: string;
	warningMessage?: string;
	titleMessage?: string;
	maxAmountMessage?: string;
	transferCancelQuantityToParent?: boolean;
	comments?: string;
	showCommentsField?: boolean;
}

export const ReduceQuantityModal = (props: Props) => {
	let errorValue: string;
	const {
		transferCancelQuantityToParent = false,
		defaultMessageWithNumber = '',
		defaultMessage = '',
		warningMessage = '',
		titleMessage = '',
		maxAmountMessage = '',
		comments = '',
		showCommentsField = false,
	} = props;

	const cancelOrderText =
		translations.app.containers.OrderContainer.components.CancelOrder;
	const [error, setError] = useState(false);
	const [errorMessages, setErrorMessages] = useState(errorKeys);
	const [amount, setAmount] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const { t: translate } = useTranslation();
	const dispatch = useDispatch();

	const activeContract: any = useSelector(selectActiveContract);
	const [cancelComments, setCancelComments] = useState(
		activeContract?.comments,
	);

	const setContractText = () => {
		const number = activeContract?.contract?.number ?? activeContract?.number;

		if (number) {
			return (
				<p className="generic-orders-reduce-quantity-modal__text--bold">
					{translate(defaultMessageWithNumber, { number })}
				</p>
			);
		} else {
			return <p>{translate(defaultMessage)}</p>;
		}
	};

	useEffect(() => {
		if (transferCancelQuantityToParent) {
			setAmount(
				customFormat(
					activeContract?.restrictions.cancellableQuantity,
					true,
					CONSTANTS.FIXED_QUANTITY_DECIMALS,
				),
			);
		}
	}, [transferCancelQuantityToParent]);

	const handleConfirmation = () => {
		if (amount) {
			if (!submitting) {
				setSubmitting(true);
				// this is hack for our really bad performance, to give the button time to get disabled
				setTimeout(() => {
					activeContract &&
						dispatch(
							actions.cancelContract({
								id: activeContract.id,
								quantity: parseFloat(
									customFormat(
										amount,
										false,
										CONSTANTS.FIXED_QUANTITY_DECIMALS,
									),
								),
								comments: cancelComments,
								transferCancelQuantityToParent,
								isAppliedLoad: true,
							}),
						);
					dispatch(actions.setCurrentModal(null));
				}, 100);
			}
		} else {
			setError(true);
		}
	};

	const handleCancel = () => {
		dispatch(actions.setCurrentModal(null));
	};

	const handleChangeAmount = (event) => {
		const result = removeComma(event.target.value);
		const parsedResult = parseFloat(result);

		if (isNaN(parsedResult)) {
			setErrorMessages({ ...errorMessages, numberMessage: true });
			setError(true);
		} else {
			if (parsedResult > activeContract.restrictions.cancellableQuantity) {
				setErrorMessages({ ...errorMessages, warningMessage: true });
				setError(true);
			} else if (parsedResult && parsedResult < 0.01) {
				setErrorMessages({ ...errorMessages, correctValue: true });
				setError(true);
			} else {
				setErrorMessages(errorKeys);
				setError(false);
			}
		}
		setAmount(
			addComma(result)
				.replace(/(\.\d{2})\d+/g, '$1')
				.replace(/[^\d.]/g, ''),
		);
	};

	const handleChangeComments = (
		event: React.ChangeEvent<HTMLTextAreaElement>,
	) => {
		setCancelComments(event.target.value);
	};

	if (errorMessages.warningMessage) {
		errorValue = translate(warningMessage);
	} else if (errorMessages.numberMessage) {
		errorValue = translate(cancelOrderText.numberMessage);
	} else {
		errorValue = translate(cancelOrderText.correctValue, {
			amount: activeContract?.quantity.grossRemainingBalance,
		});
	}

	return (
		<Modal
			maskClosable={false}
			className="generic-orders-reduce-quantity-modal"
			title={translate(titleMessage)}
			visible={true}
			onOk={handleConfirmation}
			onCancel={handleCancel}
			footer={[
				<Button
					key="back"
					onClick={handleCancel}
					className="return-button"
					data-testid="return-button"
					disabled={submitting}
				>
					{translate(cancelOrderText.return)}
				</Button>,
				<Button
					data-testid="confirm-button"
					key="submit"
					type="primary"
					onClick={handleConfirmation}
					className="cancel-button"
					disabled={error || submitting}
				>
					{translate(cancelOrderText.confirm)}
				</Button>,
			]}
		>
			{activeContract && setContractText()}
			{
				<div>
					<Row gutter={GUTTER_SPACE}>
						{!transferCancelQuantityToParent ? (
							<>
								<Col span={SPAN_COLUMN.TEXT}>
									<p className="generic-orders-reduce-quantity-modal__contract-text">
										{translate(maxAmountMessage)}
									</p>
								</Col>
								<Col span={SPAN_COLUMN.INPUT}>
									<InputNumber
										data-testid="cancel-amount-input"
										placeholder={`${customFormat(activeContract?.restrictions.cancellableQuantity, true, CONSTANTS.FIXED_QUANTITY_DECIMALS)}`}
										handleChange={handleChangeAmount}
										value={amount}
										decimals
										disabled={transferCancelQuantityToParent || submitting}
									/>

									{error && (
										<div>
											<p className="generic-orders-reduce-quantity-modal__error input-number">
												{errorValue}
											</p>
										</div>
									)}
								</Col>
							</>
						) : (
							<div
								style={{
									display: 'flex',
									padding: '1rem',
									margin: 'auto 2rem',
								}}
							>
								<p className="generic-orders-reduce-quantity-modal__contract-text">
									{translate(maxAmountMessage)}
								</p>
								<b className="text-gap generic-orders-reduce-quantity-modal__contract-text">
									{' '}
									{amount}
								</b>
							</div>
						)}
					</Row>
					{showCommentsField ? (
						<Row gutter={GUTTER_SPACE}>
							<Col span={SPAN_COLUMN.TEXT}>
								<p className="generic-orders-reduce-quantity-modal__contract-text">
									{translate(comments)}
								</p>
							</Col>
							<Col span={SPAN_COLUMN.INPUT}>
								<TextArea
									data-testid="cancel-comments-input"
									placeholder={activeContract?.comments}
									value={cancelComments}
									onChange={handleChangeComments}
								/>
							</Col>
						</Row>
					) : (
						''
					)}
				</div>
			}
		</Modal>
	);
};
