import { z } from 'zod';

const _serviceFeeSchema = z.object({
	id: z.string().uuid(),
	serviceFeeId: z.string().uuid(),
	deliveryMonth: z.string(),
	rollingFee: z.number(),
	writingFee: z.number(),
});
export interface ServiceFee extends z.infer<typeof _serviceFeeSchema> {}
const serviceFeeSchema: z.Schema<ServiceFee> = _serviceFeeSchema;

const _serviceFeeDataSchema = z.object({
	commodityId: z.string().uuid(),
	contractTypeId: z.string().uuid(),
	transactionTypeId: z.string().uuid(),
	cropYear: z.number().int(),
	serviceFeeMonthList: z.array(serviceFeeSchema),
	// note: there's more in the DTO, but this is all the UI uses
});
export interface ServiceFeeData extends z.infer<typeof _serviceFeeDataSchema> {}
export const serviceFeeDataSchema: z.Schema<ServiceFeeData> =
	_serviceFeeDataSchema;

export type DeliveryDateValue = { format: (format: string) => string };
