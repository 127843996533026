import { call, put } from 'typed-redux-saga';

import { actions } from 'app/containers/GlobalSaga/slice';
import { OrderEntryData, orderEntryDataSchema } from 'types/OrderEntry';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

const { orderEntry } = apiEndpoints;

export function* getOrderEntrySaga(
	action: ReturnType<typeof actions.getOrderEntryRequest>,
) {
	const isActive = action.payload.isActive;

	const requestURL = new URL(`${apiRoutes.base}/${orderEntry}`);
	requestURL.searchParams.append('onlyActive', isActive.toString());

	const request = requestWithSchema<OrderEntryData>;
	const { data, error } = yield* call(request, requestURL, httpMethod.Get, {
		schema: orderEntryDataSchema,
	});

	if (!!data) {
		yield* put(actions.getOrderEntrySuccess(data));
	} else if (!!error?.detail) {
		yield* put(actions.getOrderEntryError(error));
	}
}
