import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FilterInput } from 'app/components/FilterInput';
import { FilterMultiple } from 'app/components/FilterMultiple';
import { translations } from 'locales/i18n';
import { GenericOption } from 'types/GenericOption';
import { mapToLabelValue } from 'utils/helpers';
import { useEnabledRegions } from 'utils/hooks/useEnabledRegions';

import { actions } from '../../../slice';
import { Modal } from '../../../types';

export const Filters = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const regionsOptions = useEnabledRegions(mapToLabelValue);
	const translationsRoot =
		translations.app.containers.Settings.sections.Locations.View.List.Filters;
	const translationsShared = translations.app.containers.Settings.Shared;

	const defaultRegionOption: GenericOption = {
		label: translate(translationsRoot.regionDefaultOption),
		value: 'all',
	};

	const [searchTerm, setSearchTerm] = useState('');
	const [selectedRegions, setSelectedRegions] = useState<GenericOption[]>([]);

	const handleCreateLocation = () => {
		dispatch(actions.setCurrentModal(Modal.Create));
	};

	const handleOnSearch = (value) => {
		dispatch(
			actions.setPagination({
				start: 1,
			}),
		);
		setSearchTerm(value);
	};

	const handleChangeRegionId = (regionData) => {
		dispatch(
			actions.setPagination({
				start: 1,
			}),
		);
		setSelectedRegions(regionData);
	};

	const loadLocationsList = () => {
		dispatch(
			actions.setSelectedFilters({
				name: searchTerm,
				regionIds: selectedRegions
					?.filter((region) => region.value !== 'all')
					.map((region) => region.value),
			}),
		);
		dispatch(actions.loadLocationsList());
	};

	useEffect(() => {
		loadLocationsList();
	}, [searchTerm, selectedRegions]);

	return (
		<div className="filters-container">
			<Row className="grid-menu" gutter={16}>
				<Col span={9}>
					<Row gutter={16}>
						<Col xs={3} md={2} xxl={1} className="grid-menu-label">
							{translate(translationsShared.Filters)}
						</Col>
						<Col xs={4} md={6} lg={5} xl={4} xxl={3}>
							<FilterInput
								onSearch={handleOnSearch}
								placeholder={translate(translationsRoot.erpNumberFilter)}
							/>
						</Col>
						<Col xs={4} md={6} lg={5} xl={4} xxl={3}>
							<FilterMultiple
								currentOptions={
									selectedRegions.length
										? selectedRegions
										: [defaultRegionOption]
								}
								handleChange={handleChangeRegionId}
								options={[defaultRegionOption, ...(regionsOptions ?? [])]}
								placeholder={translate(
									translationsRoot.regionFilterPlaceholder,
								)}
							/>
						</Col>
					</Row>
				</Col>
				<Col span={3}>
					<Row justify="end">
						<Button
							type="primary"
							size="middle"
							onClick={handleCreateLocation}
							className="orders-button"
							data-testid="button-create-location"
						>
							{translate(translationsRoot.createButton)}
						</Button>
					</Row>
				</Col>
			</Row>
		</div>
	);
};
