import './style.scss';

import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { useFormikContext } from 'formik';
import { Form, FormItemProps, Switch, SwitchProps } from 'formik-antd';
import React, { memo } from 'react';

interface IndexedPropertyValue {
	keyValue: string;
	property: string;
	index: number | string;
}
interface Props
	extends SwitchProps,
		Omit<FormItemProps, 'children' | 'validate'> {
	name: string;
	label?: string;
	size?: 'default' | 'small';
	disabled?: boolean;
	isValueArray?: IndexedPropertyValue;
	handleChange?: SwitchChangeEventHandler;
}

export const FormSwitch = memo((props: Props) => {
	const {
		name,
		label,
		size = 'default',
		disabled = false,
		handleChange,
		isValueArray,
		...rest
	} = props;

	const { values } = useFormikContext();

	let isChecked =
		typeof values === 'object' &&
		values != null &&
		values.hasOwnProperty(name) &&
		!!(values as Record<string, unknown>)[name];
	if (isValueArray) {
		const { keyValue, index, property } = isValueArray;
		isChecked =
			Boolean(
				(values as Record<string, Record<string, Record<string, unknown>>>)[
					keyValue
				]?.[index]?.[property],
			) || isChecked;
	}
	return (
		<Form.Item
			className="switch-form__label"
			label={label}
			name={name}
			key={name}
			{...rest}
		>
			<Switch
				name={name}
				checked={isChecked}
				disabled={disabled}
				size={size}
				onChange={handleChange}
				{...rest}
			></Switch>
		</Form.Item>
	);
});
