import './style.scss';

import { Col, Form, Row } from 'antd';
import classNames from 'classnames';
import React, {
	isValidElement,
	memo,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { addComma, removeComma } from 'utils/helpers';

import { ValidationSchemaContext } from '../Form';
import { GenericFormItemProps } from '../types';

const CONSTANTS = {
	COLUMNS: 12,
	GUTTER: 10,
};

export const FormItem = memo(
	({
		name,
		onChange,
		children,
		label,
		customClassName,
		isInputSlow = false,
		isNumericFieldSlow = false,
		excludeControllerOnBlur = false,
		...formItemProps
	}: GenericFormItemProps) => {
		const validationSchema = useContext(ValidationSchemaContext);

		const [inputValue, setInputValue] = useState('');

		// A wrapper function to separate the digits by comma
		const formatQuantity = (value: string) => {
			// EXPLAINATION for condition value !== '.' --- /if user have entered something starting with a decimal point, this is for quantities less than one bushel
			if (value !== '.' && isNaN(parseFloat(value))) {
				setInputValue(value.replace(/[^0-9]/g, ''));
			} else {
				let result = addComma(value)
					.replace(/(\.\d{2})\d+/g, '$1')
					.replace('NaN', '');
				setInputValue(result);
			}
		};

		// TODO we need to come up with a method taht does not involve reaching into yup's internals
		// see: https://github.com/orgs/react-hook-form/discussions/5258#discussioncomment-3424540
		let isRequired: boolean = true;
		if (Array.isArray(children)) {
			const elementChildren = children.filter(React.isValidElement);
			children = elementChildren;
			// If all children are required, the complete item is marked as required
			// TODO this isn't doing what it says. It checks if the last child is required. It should use .every not .forEach
			elementChildren.forEach((chilData) => {
				isRequired =
					// eslint-disable-next-line @typescript-eslint/no-explicit-any -- see above TODO
					(validationSchema as any)?.fields?.[chilData.props.name as string]
						?._exclusive?.required ||
					// eslint-disable-next-line @typescript-eslint/no-explicit-any -- see above TODO
					(validationSchema as any)?.fields?.[chilData.props.name]?._exclusive
						?.requiredOption;
			});
		} else {
			isRequired =
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- see above TODO
				(validationSchema as any)?.fields?.[name]?._exclusive?.required ||
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- see above TODO
				(validationSchema as any)?.fields?.[name]?._exclusive?.requiredOption;
		}

		const {
			control,
			formState: { errors, isDirty },
			getValues,
			watch,
		} = useFormContext();

		const { quantity, qtyPriceAmount, quantityToSplit } = watch();

		useEffect(() => {
			const shouldResetInput =
				isDirty && !quantity && !qtyPriceAmount && !quantityToSplit;
			if (shouldResetInput) {
				setInputValue('');
			}
		}, [isDirty, quantity, qtyPriceAmount, quantityToSplit]);

		const { GUTTER, COLUMNS } = CONSTANTS;
		let content: JSX.Element = <></>;
		const hasError: boolean = errors[name]?.message?.length > 0;

		if (Array.isArray(children)) {
			const defaultColSpan = Math.floor(COLUMNS / children?.length);

			content = (
				<Row gutter={GUTTER}>
					{children?.map((childData, index) => {
						return (
							<Col
								key={childData.props.name}
								offset={childData.props.wrapperCol?.offset || undefined}
								span={childData.props.wrapperCol?.span || defaultColSpan}
							>
								<FormItem
									{...childData.props}
									wrapperCol={undefined}
									key={childData.props.name}
								/>
							</Col>
						);
					})}
				</Row>
			);
		} else {
			content = (
				<Controller
					name={name}
					control={control}
					render={(controllerProps) => {
						// eslint-disable-next-line react-hooks/rules-of-hooks -- TODO honestly not sure why this even works
						useEffect(() => {
							if (isNumericFieldSlow || isInputSlow) {
								setInputValue(controllerProps.value);
							}
							// eslint-disable-next-line react-hooks/exhaustive-deps
						}, []);

						const Input = isValidElement(children) ? children.type : null;
						const childProps = isValidElement(children) ? children.props : null;

						return Input ? (
							<Input
								{...childProps}
								name={controllerProps.name}
								onBlur={() => {
									if (isNumericFieldSlow || isInputSlow)
										controllerProps?.onChange?.(inputValue);
									if (!excludeControllerOnBlur) controllerProps?.onBlur?.();
									childProps?.onBlur?.();
								}}
								ref={controllerProps.ref}
								value={
									isNumericFieldSlow || isInputSlow
										? inputValue
										: controllerProps.value
								}
								checked={controllerProps.value}
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								onChange={(params: any) => {
									if (isNumericFieldSlow) {
										const value = removeComma(params?.target?.value);
										formatQuantity(value);
									} else if (isInputSlow) {
										setInputValue(params?.target?.value);
									} else {
										controllerProps?.onChange?.(
											params?.target?.value || params,
										);
									}
									childProps?.onChange?.(params?.target?.value || params);
									onChange?.(params?.target?.value || params, getValues());
								}}
							></Input>
						) : (
							<></>
						);
					}}
				/>
			);
		}

		return (
			<>
				<Form.Item
					{...formItemProps}
					label={label && isRequired ? `*${label}` : label}
					name={name}
					key={name}
					className={classNames(
						'generic-form-item',
						{
							'generic-form-item-has-error': hasError,
							'generic-form-item-multiple': Array.isArray(children),
						},
						...(customClassName ?? []),
					)}
					help={
						errors?.[name]?.message && (
							<label
								className={classNames(
									{
										'error-message': errors?.[name]?.type,
									},
									{
										'warning-message': errors?.[name]?.type === 'warning',
									},
								)}
							>
								{errors[name]?.message}
							</label>
						)
					}
				>
					{content}
				</Form.Item>
			</>
		);
	},
);
