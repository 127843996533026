import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FormFooterButtons } from 'app/components/FormFooterButtons';
import { GenericModalForm } from 'app/components/GenericModalForm';
import {
	selectCommodityInfo,
	selectHedgeMapTemplateHedgeUpdate,
	selectRegionInfo,
} from 'app/containers/Settings/sections/HedgeMapping/selectors';
import { translations } from 'locales/i18n';

import { hedgeMappingActions } from '../../slice';
import { useHedgeMapping } from '../schemas/useHedgeMappingSchema';

export const UploadHedgeMapping = () => {
	const dispatch = useDispatch();
	const { t: translate } = useTranslation();
	const uploadedFile = useSelector(selectHedgeMapTemplateHedgeUpdate);
	const commodityId = useSelector(selectCommodityInfo);
	const regionId = useSelector(selectRegionInfo);

	const translationsRoot =
		translations.app.containers.Settings.sections.HedgeMapping.Filters;
	const domain =
		translations.app.containers.Settings.sections.HedgeMapping.UploadHedgeMap;

	const hedgeAccountSchema = useHedgeMapping();

	const handleSubmit = (values) => {
		const fileId = uploadedFile.uploadResponse.data.data.fileId;

		const fmData = new FormData();

		fmData.append('FileId', fileId);
		fmData.append('CommodityId', commodityId);
		if (regionId) fmData.append('regionId', regionId);

		dispatch(
			hedgeMappingActions.submitHedgeMapToServer({
				data: fmData,
				successMessage: translate(domain.hedgeMappingSaved),
				commodityId: commodityId,
				errorTitle: translate(translationsRoot.errorTitle),
				errorDescription: translate(translationsRoot.errorDescription),
			}),
		);
	};

	const handleClose = () => {
		dispatch(hedgeMappingActions.clearHedgeMapUpdateTemplate());
		dispatch(hedgeMappingActions.setCurrentModal(null));
	};

	return (
		<GenericModalForm
			title={translate(domain.modalTitle)}
			formDefinition={hedgeAccountSchema}
			handleSubmit={handleSubmit}
			handleClose={handleClose}
			footerButtons={
				<FormFooterButtons
					isLoading={uploadedFile.loading}
					cancelLabel={translate(domain.cancelUpload)}
					submitLabel={translate(domain.continueUpload)}
					closeModal={handleClose}
				/>
			}
		/>
	);
};
