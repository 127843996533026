import { z } from 'zod';

const _regionSchema = z.object({
	id: z.string().uuid(),
	name: z.string(),
	erpNumber: z.string().nullable(),
	isEnabled: z.boolean(),
	isUsed: z.boolean(),
});
export interface Region extends z.infer<typeof _regionSchema> {}
export const regionSchema: z.Schema<Region> = _regionSchema;
