/**
 *
 * InputNumber
 *
 */
import './style.scss';

import { Input } from 'antd';
import React, {
	ChangeEvent,
	ChangeEventHandler,
	ClipboardEvent,
	KeyboardEvent,
} from 'react';

import { RESTRICTED_KEYS, RESTRICTED_KEYS_DECIMALS } from 'utils/constants';

interface Props {
	value?: string;
	decimals?: boolean;
	placeholder?: string;
	handleChange: ChangeEventHandler<HTMLInputElement>;
	suffix?: JSX.Element;
	disabled?: boolean;
}

export const InputNumber = (props: Props) => {
	const {
		handleChange: propHandleChange,
		placeholder,
		decimals,
		value,
		suffix,
		disabled,
	} = props;

	const handleChange = (event: ChangeEvent<HTMLInputElement>) =>
		propHandleChange(event);

	// prevents "e", "+", "-", "." from being prompted
	const handlePressDown = (event: KeyboardEvent<HTMLInputElement>) => {
		const keyCode = event.keyCode;
		const blockedKeys = decimals ? RESTRICTED_KEYS_DECIMALS : RESTRICTED_KEYS;

		blockedKeys.forEach((key) => {
			if (keyCode === key) {
				event.preventDefault();
			}
		});
	};

	// prevents "e", "+", "-", "." from being pasted
	const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
		const pasteText = event.clipboardData.getData('Text').toUpperCase();

		for (let i = 0; i < pasteText.length; i++) {
			if (
				(pasteText[i] === 'E' ||
					pasteText[i] === '-' ||
					pasteText[i] === '+' ||
					pasteText[i] === '.') &&
				!decimals
			) {
				event.preventDefault();
				break;
			} else if (
				(pasteText[i] === 'E' ||
					pasteText[i] === '-' ||
					pasteText[i] === '+') &&
				decimals
			) {
				event.preventDefault();
			}
		}
	};

	return (
		<Input
			className="input-number"
			data-testid="input__number"
			type="text"
			placeholder={placeholder}
			onKeyDown={(e) => handlePressDown(e)}
			onChange={(e) => handleChange(e)}
			onPaste={(e) => handlePaste(e)}
			value={value}
			suffix={suffix}
			disabled={disabled}
		/>
	);
};
