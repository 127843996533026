import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'typed-redux-saga';
import { z } from 'zod';

import { ContractTypeSource } from 'types/ContractTypeSource';
import { apiEndpoints } from 'utils/api-endpoints';
import { apiRoutes } from 'utils/api-routes';
import { httpMethod, requestWithSchema } from 'utils/request';

import { actions } from '../slice';
import { ContractType, contractTypeSchema } from '../types';

const { contractTypesRoot } = apiEndpoints;

export function* getContractTypeList(
	action: PayloadAction<ContractTypeSource>,
) {
	const requestURL = new URL(
		`${apiRoutes.base}/${contractTypesRoot}/${action.payload}`,
	);

	const request = requestWithSchema<ContractType[]>;
	const { data, error } = yield* call(request, requestURL, httpMethod.Get, {
		schema: z.array(contractTypeSchema),
	});

	if (!!data) {
		yield* put(
			actions.contractTypeListLoaded({
				data,
				source: action.payload,
			}),
		);
	} else if (!!error?.detail) {
		yield* put(
			actions.contractTypeError({
				error,
				source: action.payload,
			}),
		);
	}
}
